/*
* File:             _folder-tree.scss
* Description:      This files holds styles for Folder Tree
* Author:           PreVeil
*/

.tree {
  line-height: 1.25rem;

  .folder-item {
    cursor: pointer;
    padding-left: 20px;

    .folder-label {
      align-items: center;
      display: flex;
      position: relative;

      span {
        margin-left: 0;
      }
      .folder-name {
        margin-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 75%;
      }

      .folder-actions {
        position: absolute;
        right: 0.5rem;
        top: 8px;

        button {
          background-color: $body-bg-base !important;
          border-color: transparent !important;
          color: $active-color;
          padding: 0;

          > i {
            margin-right: 0;
          }
          &:after {
            display: none;
          }
        }
        .dropdown-menu {
          @include small-dropdown-menu();
        }
      }
      &:hover {
        .folder-actions {
          background-color: $left-nav-hover-link-bg;
          opacity: 1;
        }
      }
    }
    .folder-icon {
      margin-left: 0;

      &.no-children {
        opacity: 0;
      }
    }
  }

  .item-row,
  .file-item {
    align-items: center;
    display: flex;
  }

  .file-item {
    padding-left: 1.25rem;
    span {
      margin-left: 0.5rem;
    }
  }
}

// Move tree for Mail and Drive
.move-tree {
  max-width: 100%;
  > .folder-item {
    padding-left: 0;
  }
  .nav-link,
  .folder-item {
    [class^="#{$im-css-prefix}-"] {
      color: $text-muted;
      font-size: 1.25rem;
    }
    [class^="ficon-"] {
      top: -1px !important;
    }
    &.disabled {
      > .folder-label {
        background-color: transparent !important;
        > .folder-name {
          cursor: not-allowed;
          opacity: 0.5;
        }
        &:hover {
          color: none;
        }
      }
    }
  }
  .folder-label {
    @extend .nav-link;
    margin-bottom: 0.1rem !important;
    > span > i {
      min-height: 24px;
    }
  }
}
