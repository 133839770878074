$feather-font-family: "feather" !default;
$feather-font-path: "fonts" !default;

$ficon-activity: "\e900";
$ficon-airplay: "\e901";
$ficon-alert-circle: "\e902";
$ficon-alert-octagon: "\e903";
$ficon-alert-triangle: "\e904";
$ficon-align-center: "\e905";
$ficon-align-justify: "\e906";
$ficon-align-left: "\e907";
$ficon-align-right: "\e908";
$ficon-anchor: "\e909";
$ficon-aperture: "\e90a";
$ficon-archive: "\e90b";
$ficon-arrow-down: "\e90c";
$ficon-arrow-down-circle: "\e90d";
$ficon-arrow-down-left: "\e90e";
$ficon-arrow-down-right: "\e90f";
$ficon-arrow-left: "\e910";
$ficon-arrow-left-circle: "\e911";
$ficon-arrow-right: "\e912";
$ficon-arrow-right-circle: "\e913";
$ficon-arrow-up: "\e914";
$ficon-arrow-up-circle: "\e915";
$ficon-arrow-up-left: "\e916";
$ficon-arrow-up-right: "\e917";
$ficon-at-sign: "\e918";
$ficon-award: "\e919";
$ficon-bar-chart: "\e91a";
$ficon-bar-chart-2: "\e91b";
$ficon-battery: "\e91c";
$ficon-battery-charging: "\e91d";
$ficon-bell: "\e91e";
$ficon-bell-off: "\e91f";
$ficon-bluetooth: "\e920";
$ficon-bold: "\e921";
$ficon-book: "\e922";
$ficon-book-open: "\e923";
$ficon-bookmark: "\e924";
$ficon-box: "\e925";
$ficon-briefcase: "\e926";
$ficon-calendar: "\e927";
$ficon-camera: "\e928";
$ficon-camera-off: "\e929";
$ficon-cast: "\e92a";
$ficon-check: "\e92b";
$ficon-check-circle: "\e92c";
$ficon-check-square: "\e92d";
$ficon-chevron-down: "\e92e";
$ficon-chevron-left: "\e92f";
$ficon-chevron-right: "\e930";
$ficon-chevron-up: "\e931";
$ficon-chevrons-down: "\e932";
$ficon-chevrons-left: "\e933";
$ficon-chevrons-right: "\e934";
$ficon-chevrons-up: "\e935";
$ficon-chrome: "\e936";
$ficon-circle: "\e937";
$ficon-clipboard: "\e938";
$ficon-clock: "\e939";
$ficon-cloud: "\e93a";
$ficon-cloud-drizzle: "\e93b";
$ficon-cloud-lightning: "\e93c";
$ficon-cloud-off: "\e93d";
$ficon-cloud-rain: "\e93e";
$ficon-cloud-snow: "\e93f";
$ficon-code: "\e940";
$ficon-codepen: "\e941";
$ficon-codesandbox: "\e942";
$ficon-coffee: "\e943";
$ficon-columns: "\e944";
$ficon-command: "\e945";
$ficon-compass: "\e946";
$ficon-copy: "\e947";
$ficon-corner-down-left: "\e948";
$ficon-corner-down-right: "\e949";
$ficon-corner-left-down: "\e94a";
$ficon-corner-left-up: "\e94b";
$ficon-corner-right-down: "\e94c";
$ficon-corner-right-up: "\e94d";
$ficon-corner-up-left: "\e94e";
$ficon-corner-up-right: "\e94f";
$ficon-cpu: "\e950";
$ficon-credit-card: "\e951";
$ficon-crop: "\e952";
$ficon-crosshair: "\e953";
$ficon-database: "\e954";
$ficon-delete: "\e955";
$ficon-disc: "\e956";
$ficon-dollar-sign: "\e957";
$ficon-download: "\e958";
$ficon-download-cloud: "\e959";
$ficon-droplet: "\e95a";
$ficon-edit: "\e95b";
$ficon-edit-2: "\e95c";
$ficon-edit-3: "\e95d";
$ficon-external-link: "\e95e";
$ficon-eye: "\e95f";
$ficon-eye-off: "\e960";
$ficon-facebook: "\e961";
$ficon-fast-forward: "\e962";
$ficon-feather: "\e963";
$ficon-figma: "\e964";
$ficon-file: "\e965";
$ficon-file-minus: "\e966";
$ficon-file-plus: "\e967";
$ficon-file-text: "\e968";
$ficon-film: "\e969";
$ficon-filter: "\e96a";
$ficon-flag: "\e96b";
$ficon-folder: "\e96c";
$ficon-folder-minus: "\e96d";
$ficon-folder-plus: "\e96e";
$ficon-framer: "\e96f";
$ficon-frown: "\e970";
$ficon-gift: "\e971";
$ficon-git-branch: "\e972";
$ficon-git-commit: "\e973";
$ficon-git-merge: "\e974";
$ficon-git-pull-request: "\e975";
$ficon-github: "\e976";
$ficon-gitlab: "\e977";
$ficon-globe: "\e978";
$ficon-grid: "\e979";
$ficon-hard-drive: "\e97a";
$ficon-hash: "\e97b";
$ficon-headphones: "\e97c";
$ficon-heart: "\e97d";
$ficon-help-circle: "\e97e";
$ficon-hexagon: "\e97f";
$ficon-home: "\e980";
$ficon-image: "\e981";
$ficon-inbox: "\e982";
$ficon-info: "\e983";
$ficon-instagram: "\e984";
$ficon-italic: "\e985";
$ficon-key: "\e986";
$ficon-layers: "\e987";
$ficon-layout: "\e988";
$ficon-life-buoy: "\e989";
$ficon-link: "\e98a";
$ficon-link-2: "\e98b";
$ficon-linkedin: "\e98c";
$ficon-list: "\e98d";
$ficon-loader: "\e98e";
$ficon-lock: "\e98f";
$ficon-log-in: "\e990";
$ficon-log-out: "\e991";
$ficon-mail: "\e992";
$ficon-map: "\e993";
$ficon-map-pin: "\e994";
$ficon-maximize: "\e995";
$ficon-maximize-2: "\e996";
$ficon-meh: "\e997";
$ficon-menu: "\e998";
$ficon-message-circle: "\e999";
$ficon-message-square: "\e99a";
$ficon-mic: "\e99b";
$ficon-mic-off: "\e99c";
$ficon-minimize: "\e99d";
$ficon-minimize-2: "\e99e";
$ficon-minus: "\e99f";
$ficon-minus-circle: "\e9a0";
$ficon-minus-square: "\e9a1";
$ficon-monitor: "\e9a2";
$ficon-moon: "\e9a3";
$ficon-more-horizontal: "\e9a4";
$ficon-more-vertical: "\e9a5";
$ficon-mouse-pointer: "\e9a6";
$ficon-move: "\e9a7";
$ficon-music: "\e9a8";
$ficon-navigation: "\e9a9";
$ficon-navigation-2: "\e9aa";
$ficon-octagon: "\e9ab";
$ficon-package: "\e9ac";
$ficon-paperclip: "\e9ad";
$ficon-pause: "\e9ae";
$ficon-pause-circle: "\e9af";
$ficon-pen-tool: "\e9b0";
$ficon-percent: "\e9b1";
$ficon-phone: "\e9b2";
$ficon-phone-call: "\e9b3";
$ficon-phone-forwarded: "\e9b4";
$ficon-phone-incoming: "\e9b5";
$ficon-phone-missed: "\e9b6";
$ficon-phone-off: "\e9b7";
$ficon-phone-outgoing: "\e9b8";
$ficon-pie-chart: "\e9b9";
$ficon-play: "\e9ba";
$ficon-play-circle: "\e9bb";
$ficon-plus: "\e9bc";
$ficon-plus-circle: "\e9bd";
$ficon-plus-square: "\e9be";
$ficon-pocket: "\e9bf";
$ficon-power: "\e9c0";
$ficon-printer: "\e9c1";
$ficon-radio: "\e9c2";
$ficon-refresh-ccw: "\e9c3";
$ficon-refresh-cw: "\e9c4";
$ficon-repeat: "\e9c5";
$ficon-rewind: "\e9c6";
$ficon-rotate-ccw: "\e9c7";
$ficon-rotate-cw: "\e9c8";
$ficon-rss: "\e9c9";
$ficon-save: "\e9ca";
$ficon-scissors: "\e9cb";
$ficon-search: "\e9cc";
$ficon-send: "\e9cd";
$ficon-server: "\e9ce";
$ficon-settings: "\e9cf";
$ficon-share: "\e9d0";
$ficon-share-2: "\e9d1";
$ficon-shield: "\e9d2";
$ficon-shield-off: "\e9d3";
$ficon-shopping-bag: "\e9d4";
$ficon-shopping-cart: "\e9d5";
$ficon-shuffle: "\e9d6";
$ficon-sidebar: "\e9d7";
$ficon-skip-back: "\e9d8";
$ficon-skip-forward: "\e9d9";
$ficon-slack: "\e9da";
$ficon-slash: "\e9db";
$ficon-sliders: "\e9dc";
$ficon-smartphone: "\e9dd";
$ficon-smile: "\e9de";
$ficon-speaker: "\e9df";
$ficon-square: "\e9e0";
$ficon-star: "\e9e1";
$ficon-stop-circle: "\e9e2";
$ficon-sun: "\e9e3";
$ficon-sunrise: "\e9e4";
$ficon-sunset: "\e9e5";
$ficon-tablet: "\e9e6";
$ficon-tag: "\e9e7";
$ficon-target: "\e9e8";
$ficon-terminal: "\e9e9";
$ficon-thermometer: "\e9ea";
$ficon-thumbs-down: "\e9eb";
$ficon-thumbs-up: "\e9ec";
$ficon-toggle-left: "\e9ed";
$ficon-toggle-right: "\e9ee";
$ficon-tool: "\e9ef";
$ficon-trash: "\e9f0";
$ficon-trash-2: "\e9f1";
$ficon-trello: "\e9f2";
$ficon-trending-down: "\e9f3";
$ficon-trending-up: "\e9f4";
$ficon-triangle: "\e9f5";
$ficon-truck: "\e9f6";
$ficon-tv: "\e9f7";
$ficon-twitch: "\e9f8";
$ficon-twitter: "\e9f9";
$ficon-type: "\e9fa";
$ficon-umbrella: "\e9fb";
$ficon-underline: "\e9fc";
$ficon-unlock: "\e9fd";
$ficon-upload: "\e9fe";
$ficon-upload-cloud: "\e9ff";
$ficon-user: "\ea00";
$ficon-user-check: "\ea01";
$ficon-user-minus: "\ea02";
$ficon-user-plus: "\ea03";
$ficon-user-x: "\ea04";
$ficon-users: "\ea05";
$ficon-video: "\ea06";
$ficon-video-off: "\ea07";
$ficon-voicemail: "\ea08";
$ficon-volume: "\ea09";
$ficon-volume-1: "\ea0a";
$ficon-volume-2: "\ea0b";
$ficon-volume-x: "\ea0c";
$ficon-watch: "\ea0d";
$ficon-wifi: "\ea0e";
$ficon-wifi-off: "\ea0f";
$ficon-wind: "\ea10";
$ficon-x: "\ea11";
$ficon-x-circle: "\ea12";
$ficon-x-octagon: "\ea13";
$ficon-x-square: "\ea14";
$ficon-youtube: "\ea15";
$ficon-zap: "\ea16";
$ficon-zap-off: "\ea17";
$ficon-zoom-in: "\ea18";
$ficon-zoom-out: "\ea19";

