/*
*  File:            _colors.scss
*  Description:     This files holds variables for site specific and bootstrap base colors 
*  Author:          PreVeil
*/

// Bootstrap base colors:
$white: #fff;
$gray-100: #f5f7f8;
$gray-200: #e9ecef;
$gray-300: #ddd;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #75777c;
$gray-700: #495057;
$gray-800: #333333;
$gray-900: #212529;
$black: #000;

// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

/// PreVeil specific - legacy variable
$pv-blue: #385f7e; // #385f7e; // #6999f3; // #385f7e;
$pv-light-blue: #6999f3; // FOLDER BLUE
$pv-blue-dark: #313841;
$pv-blue-bright-light: #0adaff; // #1cebf5; // notification bubble
$pv-blue-bright: #21b2ff; // #008dcb;
$pv-icon: #7394cc;
$pv-icon-opaque: rgba(116, 134, 153, 0.4);
$pv-orange: #f16122;
$pv-green-light: #cdebd7;
$pv-green: #41a664; // #4db972;
$pv-green-dark: color.adjust($pv-green, $lightness: -6%); // darken($pv-green, 6%); - deprecated
$pv-red: #b70b0b;
$pv-warning: #f00;
$pv-yellow: #f3b72a;
$pv-yellow-light: #f7cf72;
$pv-btn-link: #337ab7;
$pv-lightgray: #f5f5f5;
$pv-red-bright: red;
$pv-muted-label: #748699;

// States:
$hover-color: #ecf2ff;
$hover-color-dark: color.adjust($hover-color, $lightness: -5%); // darken($hover-color, 5%); - deprecated
$hover-color-light: color.adjust($hover-color, $lightness: 1%); // lighten($hover-color, 1%); - deprecated
$active-color-light: #9fc0ff;
$active-color: #415e7b;
$active-bg: #d0e0fd;
$error-bg: color.adjust($pv-red, $lightness: 58%);
$pv-navbar-blue: #172543;
$pv-navbar-blue-dark: #08152f;
$pv-navbar-blue-light: $pv-btn-link;
// Navbar link color options:
$header-inactive-color: #aaa9a9; // gray
// scss-docs-start color-variables
$blue: $pv-blue;
$indigo: hsl(263, 90%, 51%);
$purple: #6f42c1;
$pink: #d63384;
$red: $pv-red;
$orange: $pv-orange;
$yellow: $pv-yellow;
$green: $pv-green;
$teal: #20c997;
$cyan: $pv-blue-bright;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $orange; // $blue;
$secondary: $blue; // $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);
// scss-docs-end theme-colors-map
