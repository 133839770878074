/*
* File:             _country-icons.scss
* Description:      This files holds styles for custom drive icons - image based
* Author:           PreVeil
*/

[class^="#{$ci-icon-css-prefix}-"],
[class*=" #{$ci-icon-css-prefix}-"] {
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: inline-block;
  font-size: $fs-icon-font-size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  height: $ci-icon-height;
  line-height: 1;
  position: relative;
  speak-as: none;
  text-transform: none;
  text-align: center;
  top: -1px;
  vertical-align: middle;
  width: $ci-icon-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$countries: (
  "af",
  "al",
  "dz",
  "as",
  "ad",
  "ao",
  "ai",
  "aq",
  "ag",
  "ar",
  "am",
  "aw",
  "au",
  "at",
  "az",
  "bs",
  "bh",
  "bd",
  "bb",
  "by",
  "be",
  "bz",
  "bj",
  "bm",
  "bt",
  "bo",
  "ba",
  "bw",
  "br",
  "io",
  "vg",
  "bn",
  "bg",
  "bf",
  "bi",
  "kh",
  "cm",
  "ca",
  "cv",
  "ky",
  "cf",
  "td",
  "cl",
  "cn",
  "cx",
  "cc",
  "co",
  "km",
  "ck",
  "cr",
  "hr",
  "cu",
  "cw",
  "cy",
  "cz",
  "cd",
  "dk",
  "dj",
  "dm",
  "do",
  "tl",
  "ec",
  "eg",
  "sv",
  "gq",
  "er",
  "ee",
  "et",
  "fk",
  "fo",
  "fj",
  "fi",
  "fr",
  "pf",
  "ga",
  "gm",
  "ge",
  "de",
  "gh",
  "gi",
  "gr",
  "gl",
  "gd",
  "gu",
  "gt",
  "gg",
  "gn",
  "gw",
  "gy",
  "ht",
  "hn",
  "hk",
  "hu",
  "is",
  "in",
  "id",
  "ir",
  "iq",
  "ie",
  "im",
  "il",
  "it",
  "ci",
  "jm",
  "jp",
  "je",
  "jo",
  "kz",
  "ke",
  "ki",
  "xk",
  "kw",
  "kg",
  "la",
  "lv",
  "lb",
  "ls",
  "lr",
  "ly",
  "li",
  "lt",
  "lu",
  "mo",
  "mk",
  "mg",
  "mw",
  "my",
  "mv",
  "ml",
  "mt",
  "mh",
  "mr",
  "mu",
  "yt",
  "mx",
  "fm",
  "md",
  "mc",
  "mn",
  "me",
  "ms",
  "ma",
  "mz",
  "mm",
  "na",
  "nr",
  "np",
  "nl",
  "an",
  "nc",
  "nz",
  "ni",
  "ne",
  "ng",
  "nu",
  "kp",
  "mp",
  "no",
  "om",
  "pk",
  "pw",
  "ps",
  "pa",
  "pg",
  "py",
  "pe",
  "ph",
  "pn",
  "pl",
  "pt",
  "pr",
  "qa",
  "cg",
  "re",
  "ro",
  "ru",
  "rw",
  "bl",
  "sh",
  "kn",
  "lc",
  "mf",
  "pm",
  "vc",
  "ws",
  "sm",
  "st",
  "sa",
  "sn",
  "rs",
  "sc",
  "sl",
  "sg",
  "sx",
  "sk",
  "si",
  "sb",
  "so",
  "za",
  "kr",
  "ss",
  "es",
  "lk",
  "sd",
  "sr",
  "sj",
  "sz",
  "se",
  "ch",
  "sy",
  "tw",
  "tj",
  "tz",
  "th",
  "tg",
  "tk",
  "to",
  "tt",
  "tn",
  "tr",
  "tm",
  "tc",
  "tv",
  "vi",
  "ug",
  "ua",
  "ae",
  "gb",
  "us",
  "uy",
  "uz",
  "vu",
  "va",
  "ve",
  "vn",
  "wf",
  "eh",
  "ye",
  "zm",
  "zw"
);

// Step 2: Write a Sass mixin to generate CSS rules
@mixin generate-countries($countries) {
  @each $country in $countries {
    .cicon-#{$country} {
      background-image: url("#{$cicon-img-path}/#{$country}.svg");
    }
  }
}

// Step 3: Include the mixin to output the generated CSS
@include generate-countries($countries);
