@import "variables";
@import 'mixins';

@font-face {
  font-family: "#{$feather-font-family}";
  src: url("#{$feather-font-path}/#{$feather-font-family}.eot?1672ms");
  src: url("#{$feather-font-path}/#{$feather-font-family}.eot?1672ms#iefix") format("embedded-opentype"),
    url("#{$feather-font-path}/#{$feather-font-family}.ttf?1672ms") format("truetype"),
    url("#{$feather-font-path}/#{$feather-font-family}.woff?1672ms") format("woff"),
    url("#{$feather-font-path}/#{$feather-font-family}.svg?1672ms##{$feather-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="#{$fi-css-prefix}-"],
[class*=" #{$fi-css-prefix}-"] {
  font-family: "#{$feather-font-family}" !important;
  display: inline-block;
  font-size: $ficon-font-size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1.1667em;
  min-width: 1em;
  position: relative;
  speak-as: never;
  text-transform: none;
  text-align: center;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Icon Sizes. 4 additional sizes: .feather-2x -> .feather-5x
@for $i from 1 through 5 {
  .#{$fi-css-prefix}-#{$i}x {
      font-size: $i * $icon-font-size;
  }
}

//
// Rotated & Flipped Icons
//

// Rotation
.#{$fi-css-prefix}-rotate-90  { @include feather-icon-rotate(90deg, 1);  }
.#{$fi-css-prefix}-rotate-180 { @include feather-icon-rotate(180deg, 2); }
.#{$fi-css-prefix}-rotate-270 { @include feather-icon-rotate(270deg, 3); }

// Flip
.#{$fi-css-prefix}-flip-horizontal { @include feather-icon-flip(-1, 1, 0); }
.#{$fi-css-prefix}-flip-vertical   { @include feather-icon-flip(1, -1, 2); }
.#{$fi-css-prefix}-flip-horizontal.#{$fi-css-prefix}-flip-vertical { @include feather-icon-flip(-1, -1, 2); }


.ficon-activity {
  &:before {
    content: $ficon-activity;
  }
}
.ficon-airplay {
  &:before {
    content: $ficon-airplay;
  }
}
.ficon-alert-circle {
  &:before {
    content: $ficon-alert-circle;
  }
}
.ficon-alert-octagon {
  &:before {
    content: $ficon-alert-octagon;
  }
}
.ficon-alert-triangle {
  &:before {
    content: $ficon-alert-triangle;
  }
}
.ficon-align-center {
  &:before {
    content: $ficon-align-center;
  }
}
.ficon-align-justify {
  &:before {
    content: $ficon-align-justify;
  }
}
.ficon-align-left {
  &:before {
    content: $ficon-align-left;
  }
}
.ficon-align-right {
  &:before {
    content: $ficon-align-right;
  }
}
.ficon-anchor {
  &:before {
    content: $ficon-anchor;
  }
}
.ficon-aperture {
  &:before {
    content: $ficon-aperture;
  }
}
.ficon-archive {
  &:before {
    content: $ficon-archive;
  }
}
.ficon-arrow-down {
  &:before {
    content: $ficon-arrow-down;
  }
}
.ficon-arrow-down-circle {
  &:before {
    content: $ficon-arrow-down-circle;
  }
}
.ficon-arrow-down-left {
  &:before {
    content: $ficon-arrow-down-left;
  }
}
.ficon-arrow-down-right {
  &:before {
    content: $ficon-arrow-down-right;
  }
}
.ficon-arrow-left {
  &:before {
    content: $ficon-arrow-left;
  }
}
.ficon-arrow-left-circle {
  &:before {
    content: $ficon-arrow-left-circle;
  }
}
.ficon-arrow-right {
  &:before {
    content: $ficon-arrow-right;
  }
}
.ficon-arrow-right-circle {
  &:before {
    content: $ficon-arrow-right-circle;
  }
}
.ficon-arrow-up {
  &:before {
    content: $ficon-arrow-up;
  }
}
.ficon-arrow-up-circle {
  &:before {
    content: $ficon-arrow-up-circle;
  }
}
.ficon-arrow-up-left {
  &:before {
    content: $ficon-arrow-up-left;
  }
}
.ficon-arrow-up-right {
  &:before {
    content: $ficon-arrow-up-right;
  }
}
.ficon-at-sign {
  &:before {
    content: $ficon-at-sign;
  }
}
.ficon-award {
  &:before {
    content: $ficon-award;
  }
}
.ficon-bar-chart {
  &:before {
    content: $ficon-bar-chart;
  }
}
.ficon-bar-chart-2 {
  &:before {
    content: $ficon-bar-chart-2;
  }
}
.ficon-battery {
  &:before {
    content: $ficon-battery;
  }
}
.ficon-battery-charging {
  &:before {
    content: $ficon-battery-charging;
  }
}
.ficon-bell {
  &:before {
    content: $ficon-bell;
  }
}
.ficon-bell-off {
  &:before {
    content: $ficon-bell-off;
  }
}
.ficon-bluetooth {
  &:before {
    content: $ficon-bluetooth;
  }
}
.ficon-bold {
  &:before {
    content: $ficon-bold;
  }
}
.ficon-book {
  &:before {
    content: $ficon-book;
  }
}
.ficon-book-open {
  &:before {
    content: $ficon-book-open;
  }
}
.ficon-bookmark {
  &:before {
    content: $ficon-bookmark;
  }
}
.ficon-box {
  &:before {
    content: $ficon-box;
  }
}
.ficon-briefcase {
  &:before {
    content: $ficon-briefcase;
  }
}
.ficon-calendar {
  &:before {
    content: $ficon-calendar;
  }
}
.ficon-camera {
  &:before {
    content: $ficon-camera;
  }
}
.ficon-camera-off {
  &:before {
    content: $ficon-camera-off;
  }
}
.ficon-cast {
  &:before {
    content: $ficon-cast;
  }
}
.ficon-check {
  &:before {
    content: $ficon-check;
  }
}
.ficon-check-circle {
  &:before {
    content: $ficon-check-circle;
  }
}
.ficon-check-square {
  &:before {
    content: $ficon-check-square;
  }
}
.ficon-chevron-down {
  &:before {
    content: $ficon-chevron-down;
  }
}
.ficon-chevron-left {
  &:before {
    content: $ficon-chevron-left;
  }
}
.ficon-chevron-right {
  &:before {
    content: $ficon-chevron-right;
  }
}
.ficon-chevron-up {
  &:before {
    content: $ficon-chevron-up;
  }
}
.ficon-chevrons-down {
  &:before {
    content: $ficon-chevrons-down;
  }
}
.ficon-chevrons-left {
  &:before {
    content: $ficon-chevrons-left;
  }
}
.ficon-chevrons-right {
  &:before {
    content: $ficon-chevrons-right;
  }
}
.ficon-chevrons-up {
  &:before {
    content: $ficon-chevrons-up;
  }
}
.ficon-chrome {
  &:before {
    content: $ficon-chrome;
  }
}
.ficon-circle {
  &:before {
    content: $ficon-circle;
  }
}
.ficon-clipboard {
  &:before {
    content: $ficon-clipboard;
  }
}
.ficon-clock {
  &:before {
    content: $ficon-clock;
  }
}
.ficon-cloud {
  &:before {
    content: $ficon-cloud;
  }
}
.ficon-cloud-drizzle {
  &:before {
    content: $ficon-cloud-drizzle;
  }
}
.ficon-cloud-lightning {
  &:before {
    content: $ficon-cloud-lightning;
  }
}
.ficon-cloud-off {
  &:before {
    content: $ficon-cloud-off;
  }
}
.ficon-cloud-rain {
  &:before {
    content: $ficon-cloud-rain;
  }
}
.ficon-cloud-snow {
  &:before {
    content: $ficon-cloud-snow;
  }
}
.ficon-code {
  &:before {
    content: $ficon-code;
  }
}
.ficon-codepen {
  &:before {
    content: $ficon-codepen;
  }
}
.ficon-codesandbox {
  &:before {
    content: $ficon-codesandbox;
  }
}
.ficon-coffee {
  &:before {
    content: $ficon-coffee;
  }
}
.ficon-columns {
  &:before {
    content: $ficon-columns;
  }
}
.ficon-command {
  &:before {
    content: $ficon-command;
  }
}
.ficon-compass {
  &:before {
    content: $ficon-compass;
  }
}
.ficon-copy {
  &:before {
    content: $ficon-copy;
  }
}
.ficon-corner-down-left {
  &:before {
    content: $ficon-corner-down-left;
  }
}
.ficon-corner-down-right {
  &:before {
    content: $ficon-corner-down-right;
  }
}
.ficon-corner-left-down {
  &:before {
    content: $ficon-corner-left-down;
  }
}
.ficon-corner-left-up {
  &:before {
    content: $ficon-corner-left-up;
  }
}
.ficon-corner-right-down {
  &:before {
    content: $ficon-corner-right-down;
  }
}
.ficon-corner-right-up {
  &:before {
    content: $ficon-corner-right-up;
  }
}
.ficon-corner-up-left {
  &:before {
    content: $ficon-corner-up-left;
  }
}
.ficon-corner-up-right {
  &:before {
    content: $ficon-corner-up-right;
  }
}
.ficon-cpu {
  &:before {
    content: $ficon-cpu;
  }
}
.ficon-credit-card {
  &:before {
    content: $ficon-credit-card;
  }
}
.ficon-crop {
  &:before {
    content: $ficon-crop;
  }
}
.ficon-crosshair {
  &:before {
    content: $ficon-crosshair;
  }
}
.ficon-database {
  &:before {
    content: $ficon-database;
  }
}
.ficon-delete {
  &:before {
    content: $ficon-delete;
  }
}
.ficon-disc {
  &:before {
    content: $ficon-disc;
  }
}
.ficon-dollar-sign {
  &:before {
    content: $ficon-dollar-sign;
  }
}
.ficon-download {
  &:before {
    content: $ficon-download;
  }
}
.ficon-download-cloud {
  &:before {
    content: $ficon-download-cloud;
  }
}
.ficon-droplet {
  &:before {
    content: $ficon-droplet;
  }
}
.ficon-edit {
  &:before {
    content: $ficon-edit;
  }
}
.ficon-edit-2 {
  &:before {
    content: $ficon-edit-2;
  }
}
.ficon-edit-3 {
  &:before {
    content: $ficon-edit-3;
  }
}
.ficon-external-link {
  &:before {
    content: $ficon-external-link;
  }
}
.ficon-eye {
  &:before {
    content: $ficon-eye;
  }
}
.ficon-eye-off {
  &:before {
    content: $ficon-eye-off;
  }
}
.ficon-facebook {
  &:before {
    content: $ficon-facebook;
  }
}
.ficon-fast-forward {
  &:before {
    content: $ficon-fast-forward;
  }
}
.ficon-feather {
  &:before {
    content: $ficon-feather;
  }
}
.ficon-figma {
  &:before {
    content: $ficon-figma;
  }
}
.ficon-file {
  &:before {
    content: $ficon-file;
  }
}
.ficon-file-minus {
  &:before {
    content: $ficon-file-minus;
  }
}
.ficon-file-plus {
  &:before {
    content: $ficon-file-plus;
  }
}
.ficon-file-text {
  &:before {
    content: $ficon-file-text;
  }
}
.ficon-film {
  &:before {
    content: $ficon-film;
  }
}
.ficon-filter {
  &:before {
    content: $ficon-filter;
  }
}
.ficon-flag {
  &:before {
    content: $ficon-flag;
  }
}
.ficon-folder {
  &:before {
    content: $ficon-folder;
  }
}
.ficon-folder-minus {
  &:before {
    content: $ficon-folder-minus;
  }
}
.ficon-folder-plus {
  &:before {
    content: $ficon-folder-plus;
  }
}
.ficon-framer {
  &:before {
    content: $ficon-framer;
  }
}
.ficon-frown {
  &:before {
    content: $ficon-frown;
  }
}
.ficon-gift {
  &:before {
    content: $ficon-gift;
  }
}
.ficon-git-branch {
  &:before {
    content: $ficon-git-branch;
  }
}
.ficon-git-commit {
  &:before {
    content: $ficon-git-commit;
  }
}
.ficon-git-merge {
  &:before {
    content: $ficon-git-merge;
  }
}
.ficon-git-pull-request {
  &:before {
    content: $ficon-git-pull-request;
  }
}
.ficon-github {
  &:before {
    content: $ficon-github;
  }
}
.ficon-gitlab {
  &:before {
    content: $ficon-gitlab;
  }
}
.ficon-globe {
  &:before {
    content: $ficon-globe;
  }
}
.ficon-grid {
  &:before {
    content: $ficon-grid;
  }
}
.ficon-hard-drive {
  &:before {
    content: $ficon-hard-drive;
  }
}
.ficon-hash {
  &:before {
    content: $ficon-hash;
  }
}
.ficon-headphones {
  &:before {
    content: $ficon-headphones;
  }
}
.ficon-heart {
  &:before {
    content: $ficon-heart;
  }
}
.ficon-help-circle {
  &:before {
    content: $ficon-help-circle;
  }
}
.ficon-hexagon {
  &:before {
    content: $ficon-hexagon;
  }
}
.ficon-home {
  &:before {
    content: $ficon-home;
  }
}
.ficon-image {
  &:before {
    content: $ficon-image;
  }
}
.ficon-inbox {
  &:before {
    content: $ficon-inbox;
  }
}
.ficon-info {
  &:before {
    content: $ficon-info;
  }
}
.ficon-instagram {
  &:before {
    content: $ficon-instagram;
  }
}
.ficon-italic {
  &:before {
    content: $ficon-italic;
  }
}
.ficon-key {
  &:before {
    content: $ficon-key;
  }
}
.ficon-layers {
  &:before {
    content: $ficon-layers;
  }
}
.ficon-layout {
  &:before {
    content: $ficon-layout;
  }
}
.ficon-life-buoy {
  &:before {
    content: $ficon-life-buoy;
  }
}
.ficon-link {
  &:before {
    content: $ficon-link;
  }
}
.ficon-link-2 {
  &:before {
    content: $ficon-link-2;
  }
}
.ficon-linkedin {
  &:before {
    content: $ficon-linkedin;
  }
}
.ficon-list {
  &:before {
    content: $ficon-list;
  }
}
.ficon-loader {
  &:before {
    content: $ficon-loader;
  }
}
.ficon-lock {
  &:before {
    content: $ficon-lock;
  }
}
.ficon-log-in {
  &:before {
    content: $ficon-log-in;
  }
}
.ficon-log-out {
  &:before {
    content: $ficon-log-out;
  }
}
.ficon-mail {
  &:before {
    content: $ficon-mail;
  }
}
.ficon-map {
  &:before {
    content: $ficon-map;
  }
}
.ficon-map-pin {
  &:before {
    content: $ficon-map-pin;
  }
}
.ficon-maximize {
  &:before {
    content: $ficon-maximize;
  }
}
.ficon-maximize-2 {
  &:before {
    content: $ficon-maximize-2;
  }
}
.ficon-meh {
  &:before {
    content: $ficon-meh;
  }
}
.ficon-menu {
  &:before {
    content: $ficon-menu;
  }
}
.ficon-message-circle {
  &:before {
    content: $ficon-message-circle;
  }
}
.ficon-message-square {
  &:before {
    content: $ficon-message-square;
  }
}
.ficon-mic {
  &:before {
    content: $ficon-mic;
  }
}
.ficon-mic-off {
  &:before {
    content: $ficon-mic-off;
  }
}
.ficon-minimize {
  &:before {
    content: $ficon-minimize;
  }
}
.ficon-minimize-2 {
  &:before {
    content: $ficon-minimize-2;
  }
}
.ficon-minus {
  &:before {
    content: $ficon-minus;
  }
}
.ficon-minus-circle {
  &:before {
    content: $ficon-minus-circle;
  }
}
.ficon-minus-square {
  &:before {
    content: $ficon-minus-square;
  }
}
.ficon-monitor {
  &:before {
    content: $ficon-monitor;
  }
}
.ficon-moon {
  &:before {
    content: $ficon-moon;
  }
}
.ficon-more-horizontal {
  &:before {
    content: $ficon-more-horizontal;
  }
}
.ficon-more-vertical {
  &:before {
    content: $ficon-more-vertical;
  }
}
.ficon-mouse-pointer {
  &:before {
    content: $ficon-mouse-pointer;
  }
}
.ficon-move {
  &:before {
    content: $ficon-move;
  }
}
.ficon-music {
  &:before {
    content: $ficon-music;
  }
}
.ficon-navigation {
  &:before {
    content: $ficon-navigation;
  }
}
.ficon-navigation-2 {
  &:before {
    content: $ficon-navigation-2;
  }
}
.ficon-octagon {
  &:before {
    content: $ficon-octagon;
  }
}
.ficon-package {
  &:before {
    content: $ficon-package;
  }
}
.ficon-paperclip {
  &:before {
    content: $ficon-paperclip;
  }
}
.ficon-pause {
  &:before {
    content: $ficon-pause;
  }
}
.ficon-pause-circle {
  &:before {
    content: $ficon-pause-circle;
  }
}
.ficon-pen-tool {
  &:before {
    content: $ficon-pen-tool;
  }
}
.ficon-percent {
  &:before {
    content: $ficon-percent;
  }
}
.ficon-phone {
  &:before {
    content: $ficon-phone;
  }
}
.ficon-phone-call {
  &:before {
    content: $ficon-phone-call;
  }
}
.ficon-phone-forwarded {
  &:before {
    content: $ficon-phone-forwarded;
  }
}
.ficon-phone-incoming {
  &:before {
    content: $ficon-phone-incoming;
  }
}
.ficon-phone-missed {
  &:before {
    content: $ficon-phone-missed;
  }
}
.ficon-phone-off {
  &:before {
    content: $ficon-phone-off;
  }
}
.ficon-phone-outgoing {
  &:before {
    content: $ficon-phone-outgoing;
  }
}
.ficon-pie-chart {
  &:before {
    content: $ficon-pie-chart;
  }
}
.ficon-play {
  &:before {
    content: $ficon-play;
  }
}
.ficon-play-circle {
  &:before {
    content: $ficon-play-circle;
  }
}
.ficon-plus {
  &:before {
    content: $ficon-plus;
  }
}
.ficon-plus-circle {
  &:before {
    content: $ficon-plus-circle;
  }
}
.ficon-plus-square {
  &:before {
    content: $ficon-plus-square;
  }
}
.ficon-pocket {
  &:before {
    content: $ficon-pocket;
  }
}
.ficon-power {
  &:before {
    content: $ficon-power;
  }
}
.ficon-printer {
  &:before {
    content: $ficon-printer;
  }
}
.ficon-radio {
  &:before {
    content: $ficon-radio;
  }
}
.ficon-refresh-ccw {
  &:before {
    content: $ficon-refresh-ccw;
  }
}
.ficon-refresh-cw {
  &:before {
    content: $ficon-refresh-cw;
  }
}
.ficon-repeat {
  &:before {
    content: $ficon-repeat;
  }
}
.ficon-rewind {
  &:before {
    content: $ficon-rewind;
  }
}
.ficon-rotate-ccw {
  &:before {
    content: $ficon-rotate-ccw;
  }
}
.ficon-rotate-cw {
  &:before {
    content: $ficon-rotate-cw;
  }
}
.ficon-rss {
  &:before {
    content: $ficon-rss;
  }
}
.ficon-save {
  &:before {
    content: $ficon-save;
  }
}
.ficon-scissors {
  &:before {
    content: $ficon-scissors;
  }
}
.ficon-search {
  &:before {
    content: $ficon-search;
  }
}
.ficon-send {
  &:before {
    content: $ficon-send;
  }
}
.ficon-server {
  &:before {
    content: $ficon-server;
  }
}
.ficon-settings {
  &:before {
    content: $ficon-settings;
  }
}
.ficon-share {
  &:before {
    content: $ficon-share;
  }
}
.ficon-share-2 {
  &:before {
    content: $ficon-share-2;
  }
}
.ficon-shield {
  &:before {
    content: $ficon-shield;
  }
}
.ficon-shield-off {
  &:before {
    content: $ficon-shield-off;
  }
}
.ficon-shopping-bag {
  &:before {
    content: $ficon-shopping-bag;
  }
}
.ficon-shopping-cart {
  &:before {
    content: $ficon-shopping-cart;
  }
}
.ficon-shuffle {
  &:before {
    content: $ficon-shuffle;
  }
}
.ficon-sidebar {
  &:before {
    content: $ficon-sidebar;
  }
}
.ficon-skip-back {
  &:before {
    content: $ficon-skip-back;
  }
}
.ficon-skip-forward {
  &:before {
    content: $ficon-skip-forward;
  }
}
.ficon-slack {
  &:before {
    content: $ficon-slack;
  }
}
.ficon-slash {
  &:before {
    content: $ficon-slash;
  }
}
.ficon-sliders {
  &:before {
    content: $ficon-sliders;
  }
}
.ficon-smartphone {
  &:before {
    content: $ficon-smartphone;
  }
}
.ficon-smile {
  &:before {
    content: $ficon-smile;
  }
}
.ficon-speaker {
  &:before {
    content: $ficon-speaker;
  }
}
.ficon-square {
  &:before {
    content: $ficon-square;
  }
}
.ficon-star {
  &:before {
    content: $ficon-star;
  }
}
.ficon-stop-circle {
  &:before {
    content: $ficon-stop-circle;
  }
}
.ficon-sun {
  &:before {
    content: $ficon-sun;
  }
}
.ficon-sunrise {
  &:before {
    content: $ficon-sunrise;
  }
}
.ficon-sunset {
  &:before {
    content: $ficon-sunset;
  }
}
.ficon-tablet {
  &:before {
    content: $ficon-tablet;
  }
}
.ficon-tag {
  &:before {
    content: $ficon-tag;
  }
}
.ficon-target {
  &:before {
    content: $ficon-target;
  }
}
.ficon-terminal {
  &:before {
    content: $ficon-terminal;
  }
}
.ficon-thermometer {
  &:before {
    content: $ficon-thermometer;
  }
}
.ficon-thumbs-down {
  &:before {
    content: $ficon-thumbs-down;
  }
}
.ficon-thumbs-up {
  &:before {
    content: $ficon-thumbs-up;
  }
}
.ficon-toggle-left {
  &:before {
    content: $ficon-toggle-left;
  }
}
.ficon-toggle-right {
  &:before {
    content: $ficon-toggle-right;
  }
}
.ficon-tool {
  &:before {
    content: $ficon-tool;
  }
}
.ficon-trash {
  &:before {
    content: $ficon-trash;
  }
}
.ficon-trash-2 {
  &:before {
    content: $ficon-trash-2;
  }
}
.ficon-trello {
  &:before {
    content: $ficon-trello;
  }
}
.ficon-trending-down {
  &:before {
    content: $ficon-trending-down;
  }
}
.ficon-trending-up {
  &:before {
    content: $ficon-trending-up;
  }
}
.ficon-triangle {
  &:before {
    content: $ficon-triangle;
  }
}
.ficon-truck {
  &:before {
    content: $ficon-truck;
  }
}
.ficon-tv {
  &:before {
    content: $ficon-tv;
  }
}
.ficon-twitch {
  &:before {
    content: $ficon-twitch;
  }
}
.ficon-twitter {
  &:before {
    content: $ficon-twitter;
  }
}
.ficon-type {
  &:before {
    content: $ficon-type;
  }
}
.ficon-umbrella {
  &:before {
    content: $ficon-umbrella;
  }
}
.ficon-underline {
  &:before {
    content: $ficon-underline;
  }
}
.ficon-unlock {
  &:before {
    content: $ficon-unlock;
  }
}
.ficon-upload {
  &:before {
    content: $ficon-upload;
  }
}
.ficon-upload-cloud {
  &:before {
    content: $ficon-upload-cloud;
  }
}
.ficon-user {
  &:before {
    content: $ficon-user;
  }
}
.ficon-user-check {
  &:before {
    content: $ficon-user-check;
  }
}
.ficon-user-minus {
  &:before {
    content: $ficon-user-minus;
  }
}
.ficon-user-plus {
  &:before {
    content: $ficon-user-plus;
  }
}
.ficon-user-x {
  &:before {
    content: $ficon-user-x;
  }
}
.ficon-users {
  &:before {
    content: $ficon-users;
  }
}
.ficon-video {
  &:before {
    content: $ficon-video;
  }
}
.ficon-video-off {
  &:before {
    content: $ficon-video-off;
  }
}
.ficon-voicemail {
  &:before {
    content: $ficon-voicemail;
  }
}
.ficon-volume {
  &:before {
    content: $ficon-volume;
  }
}
.ficon-volume-1 {
  &:before {
    content: $ficon-volume-1;
  }
}
.ficon-volume-2 {
  &:before {
    content: $ficon-volume-2;
  }
}
.ficon-volume-x {
  &:before {
    content: $ficon-volume-x;
  }
}
.ficon-watch {
  &:before {
    content: $ficon-watch;
  }
}
.ficon-wifi {
  &:before {
    content: $ficon-wifi;
  }
}
.ficon-wifi-off {
  &:before {
    content: $ficon-wifi-off;
  }
}
.ficon-wind {
  &:before {
    content: $ficon-wind;
  }
}
.ficon-x {
  &:before {
    content: $ficon-x;
  }
}
.ficon-x-circle {
  &:before {
    content: $ficon-x-circle;
  }
}
.ficon-x-octagon {
  &:before {
    content: $ficon-x-octagon;
  }
}
.ficon-x-square {
  &:before {
    content: $ficon-x-square;
  }
}
.ficon-youtube {
  &:before {
    content: $ficon-youtube;
  }
}
.ficon-zap {
  &:before {
    content: $ficon-zap;
  }
}
.ficon-zap-off {
  &:before {
    content: $ficon-zap-off;
  }
}
.ficon-zoom-in {
  &:before {
    content: $ficon-zoom-in;
  }
}
.ficon-zoom-out {
  &:before {
    content: $ficon-zoom-out;
  }
}
