/* ------------------------------------------------------------------------------
 *
 *  # Icomoon icon classes
 *
 *  Icomoon uses the Unicode Private Use Area (PUA) to ensure screen
 *  readers do not read off random characters that represent icons
 *
 * ---------------------------------------------------------------------------- */

.#{$im-css-prefix}-home:before{ content: im-content($im-var-home); }
.#{$im-css-prefix}-home2:before{ content: im-content($im-var-home2); }
.#{$im-css-prefix}-home5:before{ content: im-content($im-var-home5); }
.#{$im-css-prefix}-home7:before{ content: im-content($im-var-home7); }
.#{$im-css-prefix}-home8:before{ content: im-content($im-var-home8); }
.#{$im-css-prefix}-home9:before{ content: im-content($im-var-home9); }
.#{$im-css-prefix}-office:before{ content: im-content($im-var-office); }
.#{$im-css-prefix}-city:before{ content: im-content($im-var-city); }
.#{$im-css-prefix}-newspaper:before{ content: im-content($im-var-newspaper); }
.#{$im-css-prefix}-magazine:before{ content: im-content($im-var-magazine); }
.#{$im-css-prefix}-design:before{ content: im-content($im-var-design); }
.#{$im-css-prefix}-pencil:before{ content: im-content($im-var-pencil); }
.#{$im-css-prefix}-pencil3:before{ content: im-content($im-var-pencil3); }
.#{$im-css-prefix}-pencil4:before{ content: im-content($im-var-pencil4); }
.#{$im-css-prefix}-pencil5:before{ content: im-content($im-var-pencil5); }
.#{$im-css-prefix}-pencil6:before{ content: im-content($im-var-pencil6); }
.#{$im-css-prefix}-pencil7:before{ content: im-content($im-var-pencil7); }
.#{$im-css-prefix}-eraser:before{ content: im-content($im-var-eraser); }
.#{$im-css-prefix}-eraser2:before{ content: im-content($im-var-eraser2); }
.#{$im-css-prefix}-eraser3:before{ content: im-content($im-var-eraser3); }
.#{$im-css-prefix}-quill2:before{ content: im-content($im-var-quill2); }
.#{$im-css-prefix}-quill4:before{ content: im-content($im-var-quill4); }
.#{$im-css-prefix}-pen:before{ content: im-content($im-var-pen); }
.#{$im-css-prefix}-pen-plus:before{ content: im-content($im-var-pen-plus); }
.#{$im-css-prefix}-pen-minus:before{ content: im-content($im-var-pen-minus); }
.#{$im-css-prefix}-pen2:before{ content: im-content($im-var-pen2); }
.#{$im-css-prefix}-blog:before{ content: im-content($im-var-blog); }
.#{$im-css-prefix}-pen6:before{ content: im-content($im-var-pen6); }
.#{$im-css-prefix}-brush:before{ content: im-content($im-var-brush); }
.#{$im-css-prefix}-spray:before{ content: im-content($im-var-spray); }
.#{$im-css-prefix}-color-sampler:before{ content: im-content($im-var-color-sampler); }
.#{$im-css-prefix}-toggle:before{ content: im-content($im-var-toggle); }
.#{$im-css-prefix}-bucket:before{ content: im-content($im-var-bucket); }
.#{$im-css-prefix}-gradient:before{ content: im-content($im-var-gradient); }
.#{$im-css-prefix}-eyedropper:before{ content: im-content($im-var-eyedropper); }
.#{$im-css-prefix}-eyedropper2:before{ content: im-content($im-var-eyedropper2); }
.#{$im-css-prefix}-eyedropper3:before{ content: im-content($im-var-eyedropper3); }
.#{$im-css-prefix}-droplet:before{ content: im-content($im-var-droplet); }
.#{$im-css-prefix}-droplet2:before{ content: im-content($im-var-droplet2); }
.#{$im-css-prefix}-color-clear:before{ content: im-content($im-var-color-clear); }
.#{$im-css-prefix}-paint-format:before{ content: im-content($im-var-paint-format); }
.#{$im-css-prefix}-stamp:before{ content: im-content($im-var-stamp); }
.#{$im-css-prefix}-image2:before{ content: im-content($im-var-image2); }
.#{$im-css-prefix}-image-compare:before{ content: im-content($im-var-image-compare); }
.#{$im-css-prefix}-images2:before{ content: im-content($im-var-images2); }
.#{$im-css-prefix}-image3:before{ content: im-content($im-var-image3); }
.#{$im-css-prefix}-images3:before{ content: im-content($im-var-images3); }
.#{$im-css-prefix}-image4:before{ content: im-content($im-var-image4); }
.#{$im-css-prefix}-image5:before{ content: im-content($im-var-image5); }
.#{$im-css-prefix}-camera:before{ content: im-content($im-var-camera); }
.#{$im-css-prefix}-shutter:before{ content: im-content($im-var-shutter); }
.#{$im-css-prefix}-headphones:before{ content: im-content($im-var-headphones); }
.#{$im-css-prefix}-headset:before{ content: im-content($im-var-headset); }
.#{$im-css-prefix}-music:before{ content: im-content($im-var-music); }
.#{$im-css-prefix}-album:before{ content: im-content($im-var-album); }
.#{$im-css-prefix}-tape:before{ content: im-content($im-var-tape); }
.#{$im-css-prefix}-piano:before{ content: im-content($im-var-piano); }
.#{$im-css-prefix}-speakers:before{ content: im-content($im-var-speakers); }
.#{$im-css-prefix}-play:before{ content: im-content($im-var-play); }
.#{$im-css-prefix}-clapboard-play:before{ content: im-content($im-var-clapboard-play); }
.#{$im-css-prefix}-clapboard:before{ content: im-content($im-var-clapboard); }
.#{$im-css-prefix}-media:before{ content: im-content($im-var-media); }
.#{$im-css-prefix}-presentation:before{ content: im-content($im-var-presentation); }
.#{$im-css-prefix}-movie:before{ content: im-content($im-var-movie); }
.#{$im-css-prefix}-film:before{ content: im-content($im-var-film); }
.#{$im-css-prefix}-film2:before{ content: im-content($im-var-film2); }
.#{$im-css-prefix}-film3:before{ content: im-content($im-var-film3); }
.#{$im-css-prefix}-film4:before{ content: im-content($im-var-film4); }
.#{$im-css-prefix}-video-camera:before{ content: im-content($im-var-video-camera); }
.#{$im-css-prefix}-video-camera2:before{ content: im-content($im-var-video-camera2); }
.#{$im-css-prefix}-video-camera-slash:before{ content: im-content($im-var-video-camera-slash); }
.#{$im-css-prefix}-video-camera3:before{ content: im-content($im-var-video-camera3); }
.#{$im-css-prefix}-dice:before{ content: im-content($im-var-dice); }
.#{$im-css-prefix}-chess-king:before{ content: im-content($im-var-chess-king); }
.#{$im-css-prefix}-chess-queen:before{ content: im-content($im-var-chess-queen); }
.#{$im-css-prefix}-chess:before{ content: im-content($im-var-chess); }
.#{$im-css-prefix}-megaphone:before{ content: im-content($im-var-megaphone); }
.#{$im-css-prefix}-new:before{ content: im-content($im-var-new); }
.#{$im-css-prefix}-connection:before{ content: im-content($im-var-connection); }
.#{$im-css-prefix}-station:before{ content: im-content($im-var-station); }
.#{$im-css-prefix}-satellite-dish2:before{ content: im-content($im-var-satellite-dish2); }
.#{$im-css-prefix}-feed:before{ content: im-content($im-var-feed); }
.#{$im-css-prefix}-mic2:before{ content: im-content($im-var-mic2); }
.#{$im-css-prefix}-mic-off2:before{ content: im-content($im-var-mic-off2); }
.#{$im-css-prefix}-book:before{ content: im-content($im-var-book); }
.#{$im-css-prefix}-book2:before{ content: im-content($im-var-book2); }
.#{$im-css-prefix}-book-play:before{ content: im-content($im-var-book-play); }
.#{$im-css-prefix}-book3:before{ content: im-content($im-var-book3); }
.#{$im-css-prefix}-bookmark:before{ content: im-content($im-var-bookmark); }
.#{$im-css-prefix}-books:before{ content: im-content($im-var-books); }
.#{$im-css-prefix}-archive:before{ content: im-content($im-var-archive); }
.#{$im-css-prefix}-reading:before{ content: im-content($im-var-reading); }
.#{$im-css-prefix}-library2:before{ content: im-content($im-var-library2); }
.#{$im-css-prefix}-graduation2:before{ content: im-content($im-var-graduation2); }
.#{$im-css-prefix}-file-text:before{ content: im-content($im-var-file-text); }
.#{$im-css-prefix}-profile:before{ content: im-content($im-var-profile); }
.#{$im-css-prefix}-file-empty:before{ content: im-content($im-var-file-empty); }
.#{$im-css-prefix}-file-empty2:before{ content: im-content($im-var-file-empty2); }
.#{$im-css-prefix}-files-empty:before{ content: im-content($im-var-files-empty); }
.#{$im-css-prefix}-files-empty2:before{ content: im-content($im-var-files-empty2); }
.#{$im-css-prefix}-file-plus:before{ content: im-content($im-var-file-plus); }
.#{$im-css-prefix}-file-plus2:before{ content: im-content($im-var-file-plus2); }
.#{$im-css-prefix}-file-minus:before{ content: im-content($im-var-file-minus); }
.#{$im-css-prefix}-file-minus2:before{ content: im-content($im-var-file-minus2); }
.#{$im-css-prefix}-file-download:before{ content: im-content($im-var-file-download); }
.#{$im-css-prefix}-file-download2:before{ content: im-content($im-var-file-download2); }
.#{$im-css-prefix}-file-upload:before{ content: im-content($im-var-file-upload); }
.#{$im-css-prefix}-file-upload2:before{ content: im-content($im-var-file-upload2); }
.#{$im-css-prefix}-file-check:before{ content: im-content($im-var-file-check); }
.#{$im-css-prefix}-file-check2:before{ content: im-content($im-var-file-check2); }
.#{$im-css-prefix}-file-eye:before{ content: im-content($im-var-file-eye); }
.#{$im-css-prefix}-file-eye2:before{ content: im-content($im-var-file-eye2); }
.#{$im-css-prefix}-file-text2:before{ content: im-content($im-var-file-text2); }
.#{$im-css-prefix}-file-text3:before{ content: im-content($im-var-file-text3); }
.#{$im-css-prefix}-file-picture:before{ content: im-content($im-var-file-picture); }
.#{$im-css-prefix}-file-picture2:before{ content: im-content($im-var-file-picture2); }
.#{$im-css-prefix}-file-music:before{ content: im-content($im-var-file-music); }
.#{$im-css-prefix}-file-music2:before{ content: im-content($im-var-file-music2); }
.#{$im-css-prefix}-file-play:before{ content: im-content($im-var-file-play); }
.#{$im-css-prefix}-file-play2:before{ content: im-content($im-var-file-play2); }
.#{$im-css-prefix}-file-video:before{ content: im-content($im-var-file-video); }
.#{$im-css-prefix}-file-video2:before{ content: im-content($im-var-file-video2); }
.#{$im-css-prefix}-copy:before{ content: im-content($im-var-copy); }
.#{$im-css-prefix}-copy2:before{ content: im-content($im-var-copy2); }
.#{$im-css-prefix}-file-zip:before{ content: im-content($im-var-file-zip); }
.#{$im-css-prefix}-file-zip2:before{ content: im-content($im-var-file-zip2); }
.#{$im-css-prefix}-file-xml:before{ content: im-content($im-var-file-xml); }
.#{$im-css-prefix}-file-xml2:before{ content: im-content($im-var-file-xml2); }
.#{$im-css-prefix}-file-css:before{ content: im-content($im-var-file-css); }
.#{$im-css-prefix}-file-css2:before{ content: im-content($im-var-file-css2); }
.#{$im-css-prefix}-file-presentation:before{ content: im-content($im-var-file-presentation); }
.#{$im-css-prefix}-file-presentation2:before{ content: im-content($im-var-file-presentation2); }
.#{$im-css-prefix}-file-stats:before{ content: im-content($im-var-file-stats); }
.#{$im-css-prefix}-file-stats2:before{ content: im-content($im-var-file-stats2); }
.#{$im-css-prefix}-file-locked:before{ content: im-content($im-var-file-locked); }
.#{$im-css-prefix}-file-locked2:before{ content: im-content($im-var-file-locked2); }
.#{$im-css-prefix}-file-spreadsheet:before{ content: im-content($im-var-file-spreadsheet); }
.#{$im-css-prefix}-file-spreadsheet2:before{ content: im-content($im-var-file-spreadsheet2); }
.#{$im-css-prefix}-copy3:before{ content: im-content($im-var-copy3); }
.#{$im-css-prefix}-copy4:before{ content: im-content($im-var-copy4); }
.#{$im-css-prefix}-paste:before{ content: im-content($im-var-paste); }
.#{$im-css-prefix}-paste2:before{ content: im-content($im-var-paste2); }
.#{$im-css-prefix}-paste3:before{ content: im-content($im-var-paste3); }
.#{$im-css-prefix}-paste4:before{ content: im-content($im-var-paste4); }
.#{$im-css-prefix}-stack:before{ content: im-content($im-var-stack); }
.#{$im-css-prefix}-stack2:before{ content: im-content($im-var-stack2); }
.#{$im-css-prefix}-stack3:before{ content: im-content($im-var-stack3); }
.#{$im-css-prefix}-folder:before{ content: im-content($im-var-folder); }
.#{$im-css-prefix}-folder-search:before{ content: im-content($im-var-folder-search); }
.#{$im-css-prefix}-folder-download:before{ content: im-content($im-var-folder-download); }
.#{$im-css-prefix}-folder-upload:before{ content: im-content($im-var-folder-upload); }
.#{$im-css-prefix}-folder-plus:before{ content: im-content($im-var-folder-plus); }
.#{$im-css-prefix}-folder-plus2:before{ content: im-content($im-var-folder-plus2); }
.#{$im-css-prefix}-folder-minus:before{ content: im-content($im-var-folder-minus); }
.#{$im-css-prefix}-folder-minus2:before{ content: im-content($im-var-folder-minus2); }
.#{$im-css-prefix}-folder-check:before{ content: im-content($im-var-folder-check); }
.#{$im-css-prefix}-folder-heart:before{ content: im-content($im-var-folder-heart); }
.#{$im-css-prefix}-folder-remove:before{ content: im-content($im-var-folder-remove); }
.#{$im-css-prefix}-folder2:before{ content: im-content($im-var-folder2); }
.#{$im-css-prefix}-folder-open:before{ content: im-content($im-var-folder-open); }
.#{$im-css-prefix}-folder3:before{ content: im-content($im-var-folder3); }
.#{$im-css-prefix}-folder4:before{ content: im-content($im-var-folder4); }
.#{$im-css-prefix}-folder-plus3:before{ content: im-content($im-var-folder-plus3); }
.#{$im-css-prefix}-folder-minus3:before{ content: im-content($im-var-folder-minus3); }
.#{$im-css-prefix}-folder-plus4:before{ content: im-content($im-var-folder-plus4); }
.#{$im-css-prefix}-folder-minus4:before{ content: im-content($im-var-folder-minus4); }
.#{$im-css-prefix}-folder-download2:before{ content: im-content($im-var-folder-download2); }
.#{$im-css-prefix}-folder-upload2:before{ content: im-content($im-var-folder-upload2); }
.#{$im-css-prefix}-folder-download3:before{ content: im-content($im-var-folder-download3); }
.#{$im-css-prefix}-folder-upload3:before{ content: im-content($im-var-folder-upload3); }
.#{$im-css-prefix}-folder5:before{ content: im-content($im-var-folder5); }
.#{$im-css-prefix}-folder-open2:before{ content: im-content($im-var-folder-open2); }
.#{$im-css-prefix}-folder6:before{ content: im-content($im-var-folder6); }
.#{$im-css-prefix}-folder-open3:before{ content: im-content($im-var-folder-open3); }
.#{$im-css-prefix}-certificate:before{ content: im-content($im-var-certificate); }
.#{$im-css-prefix}-cc:before{ content: im-content($im-var-cc); }
.#{$im-css-prefix}-price-tag:before{ content: im-content($im-var-price-tag); }
.#{$im-css-prefix}-price-tag2:before{ content: im-content($im-var-price-tag2); }
.#{$im-css-prefix}-price-tags:before{ content: im-content($im-var-price-tags); }
.#{$im-css-prefix}-price-tag3:before{ content: im-content($im-var-price-tag3); }
.#{$im-css-prefix}-price-tags2:before{ content: im-content($im-var-price-tags2); }
.#{$im-css-prefix}-barcode2:before{ content: im-content($im-var-barcode2); }
.#{$im-css-prefix}-qrcode:before{ content: im-content($im-var-qrcode); }
.#{$im-css-prefix}-ticket:before{ content: im-content($im-var-ticket); }
.#{$im-css-prefix}-theater:before{ content: im-content($im-var-theater); }
.#{$im-css-prefix}-store:before{ content: im-content($im-var-store); }
.#{$im-css-prefix}-store2:before{ content: im-content($im-var-store2); }
.#{$im-css-prefix}-cart:before{ content: im-content($im-var-cart); }
.#{$im-css-prefix}-cart2:before{ content: im-content($im-var-cart2); }
.#{$im-css-prefix}-cart4:before{ content: im-content($im-var-cart4); }
.#{$im-css-prefix}-cart5:before{ content: im-content($im-var-cart5); }
.#{$im-css-prefix}-cart-add:before{ content: im-content($im-var-cart-add); }
.#{$im-css-prefix}-cart-add2:before{ content: im-content($im-var-cart-add2); }
.#{$im-css-prefix}-cart-remove:before{ content: im-content($im-var-cart-remove); }
.#{$im-css-prefix}-basket:before{ content: im-content($im-var-basket); }
.#{$im-css-prefix}-bag:before{ content: im-content($im-var-bag); }
.#{$im-css-prefix}-percent:before{ content: im-content($im-var-percent); }
.#{$im-css-prefix}-coins:before{ content: im-content($im-var-coins); }
.#{$im-css-prefix}-coin-dollar:before{ content: im-content($im-var-coin-dollar); }
.#{$im-css-prefix}-coin-euro:before{ content: im-content($im-var-coin-euro); }
.#{$im-css-prefix}-coin-pound:before{ content: im-content($im-var-coin-pound); }
.#{$im-css-prefix}-coin-yen:before{ content: im-content($im-var-coin-yen); }
.#{$im-css-prefix}-piggy-bank:before{ content: im-content($im-var-piggy-bank); }
.#{$im-css-prefix}-wallet:before{ content: im-content($im-var-wallet); }
.#{$im-css-prefix}-cash:before{ content: im-content($im-var-cash); }
.#{$im-css-prefix}-cash2:before{ content: im-content($im-var-cash2); }
.#{$im-css-prefix}-cash3:before{ content: im-content($im-var-cash3); }
.#{$im-css-prefix}-cash4:before{ content: im-content($im-var-cash4); }
.#{$im-css-prefix}-credit-card:before{ content: im-content($im-var-credit-card); }
.#{$im-css-prefix}-credit-card2:before{ content: im-content($im-var-credit-card2); }
.#{$im-css-prefix}-calculator4:before{ content: im-content($im-var-calculator4); }
.#{$im-css-prefix}-calculator2:before{ content: im-content($im-var-calculator2); }
.#{$im-css-prefix}-calculator3:before{ content: im-content($im-var-calculator3); }
.#{$im-css-prefix}-chip:before{ content: im-content($im-var-chip); }
.#{$im-css-prefix}-lifebuoy:before{ content: im-content($im-var-lifebuoy); }
.#{$im-css-prefix}-phone:before{ content: im-content($im-var-phone); }
.#{$im-css-prefix}-phone2:before{ content: im-content($im-var-phone2); }
.#{$im-css-prefix}-phone-slash:before{ content: im-content($im-var-phone-slash); }
.#{$im-css-prefix}-phone-wave:before{ content: im-content($im-var-phone-wave); }
.#{$im-css-prefix}-phone-plus:before{ content: im-content($im-var-phone-plus); }
.#{$im-css-prefix}-phone-minus:before{ content: im-content($im-var-phone-minus); }
.#{$im-css-prefix}-phone-plus2:before{ content: im-content($im-var-phone-plus2); }
.#{$im-css-prefix}-phone-minus2:before{ content: im-content($im-var-phone-minus2); }
.#{$im-css-prefix}-phone-incoming:before{ content: im-content($im-var-phone-incoming); }
.#{$im-css-prefix}-phone-outgoing:before{ content: im-content($im-var-phone-outgoing); }
.#{$im-css-prefix}-phone-hang-up:before{ content: im-content($im-var-phone-hang-up); }
.#{$im-css-prefix}-address-book:before{ content: im-content($im-var-address-book); }
.#{$im-css-prefix}-address-book2:before{ content: im-content($im-var-address-book2); }
.#{$im-css-prefix}-address-book3:before{ content: im-content($im-var-address-book3); }
.#{$im-css-prefix}-notebook:before{ content: im-content($im-var-notebook); }
.#{$im-css-prefix}-envelop:before{ content: im-content($im-var-envelop); }
.#{$im-css-prefix}-envelop2:before{ content: im-content($im-var-envelop2); }
.#{$im-css-prefix}-envelop3:before{ content: im-content($im-var-envelop3); }
.#{$im-css-prefix}-envelop4:before{ content: im-content($im-var-envelop4); }
.#{$im-css-prefix}-envelop5:before{ content: im-content($im-var-envelop5); }
.#{$im-css-prefix}-mailbox:before{ content: im-content($im-var-mailbox); }
.#{$im-css-prefix}-pushpin:before{ content: im-content($im-var-pushpin); }
.#{$im-css-prefix}-location3:before{ content: im-content($im-var-location3); }
.#{$im-css-prefix}-location4:before{ content: im-content($im-var-location4); }
.#{$im-css-prefix}-compass4:before{ content: im-content($im-var-compass4); }
.#{$im-css-prefix}-map:before{ content: im-content($im-var-map); }
.#{$im-css-prefix}-map4:before{ content: im-content($im-var-map4); }
.#{$im-css-prefix}-map5:before{ content: im-content($im-var-map5); }
.#{$im-css-prefix}-direction:before{ content: im-content($im-var-direction); }
.#{$im-css-prefix}-reset:before{ content: im-content($im-var-reset); }
.#{$im-css-prefix}-history:before{ content: im-content($im-var-history); }
.#{$im-css-prefix}-watch:before{ content: im-content($im-var-watch); }
.#{$im-css-prefix}-watch2:before{ content: im-content($im-var-watch2); }
.#{$im-css-prefix}-alarm:before{ content: im-content($im-var-alarm); }
.#{$im-css-prefix}-alarm-add:before{ content: im-content($im-var-alarm-add); }
.#{$im-css-prefix}-alarm-check:before{ content: im-content($im-var-alarm-check); }
.#{$im-css-prefix}-alarm-cancel:before{ content: im-content($im-var-alarm-cancel); }
.#{$im-css-prefix}-bell2:before{ content: im-content($im-var-bell2); }
.#{$im-css-prefix}-bell3:before{ content: im-content($im-var-bell3); }
.#{$im-css-prefix}-bell-plus:before{ content: im-content($im-var-bell-plus); }
.#{$im-css-prefix}-bell-minus:before{ content: im-content($im-var-bell-minus); }
.#{$im-css-prefix}-bell-check:before{ content: im-content($im-var-bell-check); }
.#{$im-css-prefix}-bell-cross:before{ content: im-content($im-var-bell-cross); }
.#{$im-css-prefix}-calendar:before{ content: im-content($im-var-calendar); }
.#{$im-css-prefix}-calendar2:before{ content: im-content($im-var-calendar2); }
.#{$im-css-prefix}-calendar3:before{ content: im-content($im-var-calendar3); }
.#{$im-css-prefix}-calendar52:before{ content: im-content($im-var-calendar52); }
.#{$im-css-prefix}-printer:before{ content: im-content($im-var-printer); }
.#{$im-css-prefix}-printer2:before{ content: im-content($im-var-printer2); }
.#{$im-css-prefix}-printer4:before{ content: im-content($im-var-printer4); }
.#{$im-css-prefix}-shredder:before{ content: im-content($im-var-shredder); }
.#{$im-css-prefix}-mouse:before{ content: im-content($im-var-mouse); }
.#{$im-css-prefix}-mouse-left:before{ content: im-content($im-var-mouse-left); }
.#{$im-css-prefix}-mouse-right:before{ content: im-content($im-var-mouse-right); }
.#{$im-css-prefix}-keyboard:before{ content: im-content($im-var-keyboard); }
.#{$im-css-prefix}-typewriter:before{ content: im-content($im-var-typewriter); }
.#{$im-css-prefix}-display:before{ content: im-content($im-var-display); }
.#{$im-css-prefix}-display4:before{ content: im-content($im-var-display4); }
.#{$im-css-prefix}-laptop:before{ content: im-content($im-var-laptop); }
.#{$im-css-prefix}-mobile:before{ content: im-content($im-var-mobile); }
.#{$im-css-prefix}-mobile2:before{ content: im-content($im-var-mobile2); }
.#{$im-css-prefix}-tablet:before{ content: im-content($im-var-tablet); }
.#{$im-css-prefix}-mobile3:before{ content: im-content($im-var-mobile3); }
.#{$im-css-prefix}-tv:before{ content: im-content($im-var-tv); }
.#{$im-css-prefix}-radio:before{ content: im-content($im-var-radio); }
.#{$im-css-prefix}-cabinet:before{ content: im-content($im-var-cabinet); }
.#{$im-css-prefix}-drawer:before{ content: im-content($im-var-drawer); }
.#{$im-css-prefix}-drawer2:before{ content: im-content($im-var-drawer2); }
.#{$im-css-prefix}-drawer-out:before{ content: im-content($im-var-drawer-out); }
.#{$im-css-prefix}-drawer-in:before{ content: im-content($im-var-drawer-in); }
.#{$im-css-prefix}-drawer3:before{ content: im-content($im-var-drawer3); }
.#{$im-css-prefix}-box:before{ content: im-content($im-var-box); }
.#{$im-css-prefix}-box-add:before{ content: im-content($im-var-box-add); }
.#{$im-css-prefix}-box-remove:before{ content: im-content($im-var-box-remove); }
.#{$im-css-prefix}-download:before{ content: im-content($im-var-download); }
.#{$im-css-prefix}-upload:before{ content: im-content($im-var-upload); }
.#{$im-css-prefix}-floppy-disk:before{ content: im-content($im-var-floppy-disk); }
.#{$im-css-prefix}-floppy-disks:before{ content: im-content($im-var-floppy-disks); }
.#{$im-css-prefix}-usb-stick:before{ content: im-content($im-var-usb-stick); }
.#{$im-css-prefix}-drive:before{ content: im-content($im-var-drive); }
.#{$im-css-prefix}-server:before{ content: im-content($im-var-server); }
.#{$im-css-prefix}-database:before{ content: im-content($im-var-database); }
.#{$im-css-prefix}-database2:before{ content: im-content($im-var-database2); }
.#{$im-css-prefix}-database4:before{ content: im-content($im-var-database4); }
.#{$im-css-prefix}-database-menu:before{ content: im-content($im-var-database-menu); }
.#{$im-css-prefix}-database-add:before{ content: im-content($im-var-database-add); }
.#{$im-css-prefix}-database-remove:before{ content: im-content($im-var-database-remove); }
.#{$im-css-prefix}-database-insert:before{ content: im-content($im-var-database-insert); }
.#{$im-css-prefix}-database-export:before{ content: im-content($im-var-database-export); }
.#{$im-css-prefix}-database-upload:before{ content: im-content($im-var-database-upload); }
.#{$im-css-prefix}-database-refresh:before{ content: im-content($im-var-database-refresh); }
.#{$im-css-prefix}-database-diff:before{ content: im-content($im-var-database-diff); }
.#{$im-css-prefix}-database-edit2:before{ content: im-content($im-var-database-edit2); }
.#{$im-css-prefix}-database-check:before{ content: im-content($im-var-database-check); }
.#{$im-css-prefix}-database-arrow:before{ content: im-content($im-var-database-arrow); }
.#{$im-css-prefix}-database-time2:before{ content: im-content($im-var-database-time2); }
.#{$im-css-prefix}-undo:before{ content: im-content($im-var-undo); }
.#{$im-css-prefix}-redo:before{ content: im-content($im-var-redo); }
.#{$im-css-prefix}-rotate-ccw:before{ content: im-content($im-var-rotate-ccw); }
.#{$im-css-prefix}-rotate-cw:before{ content: im-content($im-var-rotate-cw); }
.#{$im-css-prefix}-rotate-ccw2:before{ content: im-content($im-var-rotate-ccw2); }
.#{$im-css-prefix}-rotate-cw2:before{ content: im-content($im-var-rotate-cw2); }
.#{$im-css-prefix}-rotate-ccw3:before{ content: im-content($im-var-rotate-ccw3); }
.#{$im-css-prefix}-rotate-cw3:before{ content: im-content($im-var-rotate-cw3); }
.#{$im-css-prefix}-flip-vertical2:before{ content: im-content($im-var-flip-vertical2); }
.#{$im-css-prefix}-flip-horizontal2:before{ content: im-content($im-var-flip-horizontal2); }
.#{$im-css-prefix}-flip-vertical3:before{ content: im-content($im-var-flip-vertical3); }
.#{$im-css-prefix}-flip-vertical4:before{ content: im-content($im-var-flip-vertical4); }
.#{$im-css-prefix}-angle:before{ content: im-content($im-var-angle); }
.#{$im-css-prefix}-shear:before{ content: im-content($im-var-shear); }
.#{$im-css-prefix}-align-left:before{ content: im-content($im-var-align-left); }
.#{$im-css-prefix}-align-center-horizontal:before{ content: im-content($im-var-align-center-horizontal); }
.#{$im-css-prefix}-align-right:before{ content: im-content($im-var-align-right); }
.#{$im-css-prefix}-align-top:before{ content: im-content($im-var-align-top); }
.#{$im-css-prefix}-align-center-vertical:before{ content: im-content($im-var-align-center-vertical); }
.#{$im-css-prefix}-align-bottom:before{ content: im-content($im-var-align-bottom); }
.#{$im-css-prefix}-undo2:before{ content: im-content($im-var-undo2); }
.#{$im-css-prefix}-redo2:before{ content: im-content($im-var-redo2); }
.#{$im-css-prefix}-forward:before{ content: im-content($im-var-forward); }
.#{$im-css-prefix}-reply:before{ content: im-content($im-var-reply); }
.#{$im-css-prefix}-reply-all:before{ content: im-content($im-var-reply-all); }
.#{$im-css-prefix}-bubble:before{ content: im-content($im-var-bubble); }
.#{$im-css-prefix}-bubbles:before{ content: im-content($im-var-bubbles); }
.#{$im-css-prefix}-bubbles2:before{ content: im-content($im-var-bubbles2); }
.#{$im-css-prefix}-bubble2:before{ content: im-content($im-var-bubble2); }
.#{$im-css-prefix}-bubbles3:before{ content: im-content($im-var-bubbles3); }
.#{$im-css-prefix}-bubbles4:before{ content: im-content($im-var-bubbles4); }
.#{$im-css-prefix}-bubble-notification:before{ content: im-content($im-var-bubble-notification); }
.#{$im-css-prefix}-bubbles5:before{ content: im-content($im-var-bubbles5); }
.#{$im-css-prefix}-bubbles6:before{ content: im-content($im-var-bubbles6); }
.#{$im-css-prefix}-bubble6:before{ content: im-content($im-var-bubble6); }
.#{$im-css-prefix}-bubbles7:before{ content: im-content($im-var-bubbles7); }
.#{$im-css-prefix}-bubble7:before{ content: im-content($im-var-bubble7); }
.#{$im-css-prefix}-bubbles8:before{ content: im-content($im-var-bubbles8); }
.#{$im-css-prefix}-bubble8:before{ content: im-content($im-var-bubble8); }
.#{$im-css-prefix}-bubble-dots3:before{ content: im-content($im-var-bubble-dots3); }
.#{$im-css-prefix}-bubble-lines3:before{ content: im-content($im-var-bubble-lines3); }
.#{$im-css-prefix}-bubble9:before{ content: im-content($im-var-bubble9); }
.#{$im-css-prefix}-bubble-dots4:before{ content: im-content($im-var-bubble-dots4); }
.#{$im-css-prefix}-bubble-lines4:before{ content: im-content($im-var-bubble-lines4); }
.#{$im-css-prefix}-bubbles9:before{ content: im-content($im-var-bubbles9); }
.#{$im-css-prefix}-bubbles10:before{ content: im-content($im-var-bubbles10); }
.#{$im-css-prefix}-user:before{ content: im-content($im-var-user); }
.#{$im-css-prefix}-users:before{ content: im-content($im-var-users); }
.#{$im-css-prefix}-user-plus:before{ content: im-content($im-var-user-plus); }
.#{$im-css-prefix}-user-minus:before{ content: im-content($im-var-user-minus); }
.#{$im-css-prefix}-user-cancel:before{ content: im-content($im-var-user-cancel); }
.#{$im-css-prefix}-user-block:before{ content: im-content($im-var-user-block); }
.#{$im-css-prefix}-user-lock:before{ content: im-content($im-var-user-lock); }
.#{$im-css-prefix}-user-check:before{ content: im-content($im-var-user-check); }
.#{$im-css-prefix}-users2:before{ content: im-content($im-var-users2); }
.#{$im-css-prefix}-users4:before{ content: im-content($im-var-users4); }
.#{$im-css-prefix}-user-tie:before{ content: im-content($im-var-user-tie); }
.#{$im-css-prefix}-collaboration:before{ content: im-content($im-var-collaboration); }
.#{$im-css-prefix}-vcard:before{ content: im-content($im-var-vcard); }
.#{$im-css-prefix}-hat:before{ content: im-content($im-var-hat); }
.#{$im-css-prefix}-bowtie:before{ content: im-content($im-var-bowtie); }
.#{$im-css-prefix}-quotes-left:before{ content: im-content($im-var-quotes-left); }
.#{$im-css-prefix}-quotes-right:before{ content: im-content($im-var-quotes-right); }
.#{$im-css-prefix}-quotes-left2:before{ content: im-content($im-var-quotes-left2); }
.#{$im-css-prefix}-quotes-right2:before{ content: im-content($im-var-quotes-right2); }
.#{$im-css-prefix}-hour-glass:before{ content: im-content($im-var-hour-glass); }
.#{$im-css-prefix}-hour-glass2:before{ content: im-content($im-var-hour-glass2); }
.#{$im-css-prefix}-hour-glass3:before{ content: im-content($im-var-hour-glass3); }
.#{$im-css-prefix}-spinner:before{ content: im-content($im-var-spinner); }
.#{$im-css-prefix}-spinner2:before{ content: im-content($im-var-spinner2); }
.#{$im-css-prefix}-spinner3:before{ content: im-content($im-var-spinner3); }
.#{$im-css-prefix}-spinner4:before{ content: im-content($im-var-spinner4); }
.#{$im-css-prefix}-spinner6:before{ content: im-content($im-var-spinner6); }
.#{$im-css-prefix}-spinner9:before{ content: im-content($im-var-spinner9); }
.#{$im-css-prefix}-spinner10:before{ content: im-content($im-var-spinner10); }
.#{$im-css-prefix}-spinner11:before{ content: im-content($im-var-spinner11); }
.#{$im-css-prefix}-microscope:before{ content: im-content($im-var-microscope); }
.#{$im-css-prefix}-enlarge:before{ content: im-content($im-var-enlarge); }
.#{$im-css-prefix}-shrink:before{ content: im-content($im-var-shrink); }
.#{$im-css-prefix}-enlarge3:before{ content: im-content($im-var-enlarge3); }
.#{$im-css-prefix}-shrink3:before{ content: im-content($im-var-shrink3); }
.#{$im-css-prefix}-enlarge5:before{ content: im-content($im-var-enlarge5); }
.#{$im-css-prefix}-shrink5:before{ content: im-content($im-var-shrink5); }
.#{$im-css-prefix}-enlarge6:before{ content: im-content($im-var-enlarge6); }
.#{$im-css-prefix}-shrink6:before{ content: im-content($im-var-shrink6); }
.#{$im-css-prefix}-enlarge7:before{ content: im-content($im-var-enlarge7); }
.#{$im-css-prefix}-shrink7:before{ content: im-content($im-var-shrink7); }
.#{$im-css-prefix}-key:before{ content: im-content($im-var-key); }
.#{$im-css-prefix}-lock:before{ content: im-content($im-var-lock); }
.#{$im-css-prefix}-lock2:before{ content: im-content($im-var-lock2); }
.#{$im-css-prefix}-lock4:before{ content: im-content($im-var-lock4); }
.#{$im-css-prefix}-unlocked:before{ content: im-content($im-var-unlocked); }
.#{$im-css-prefix}-lock5:before{ content: im-content($im-var-lock5); }
.#{$im-css-prefix}-unlocked2:before{ content: im-content($im-var-unlocked2); }
.#{$im-css-prefix}-safe:before{ content: im-content($im-var-safe); }
.#{$im-css-prefix}-wrench:before{ content: im-content($im-var-wrench); }
.#{$im-css-prefix}-wrench2:before{ content: im-content($im-var-wrench2); }
.#{$im-css-prefix}-wrench3:before{ content: im-content($im-var-wrench3); }
.#{$im-css-prefix}-equalizer:before{ content: im-content($im-var-equalizer); }
.#{$im-css-prefix}-equalizer2:before{ content: im-content($im-var-equalizer2); }
.#{$im-css-prefix}-equalizer3:before{ content: im-content($im-var-equalizer3); }
.#{$im-css-prefix}-equalizer4:before{ content: im-content($im-var-equalizer4); }
.#{$im-css-prefix}-cog:before{ content: im-content($im-var-cog); }
.#{$im-css-prefix}-cogs:before{ content: im-content($im-var-cogs); }
.#{$im-css-prefix}-cog2:before{ content: im-content($im-var-cog2); }
.#{$im-css-prefix}-cog3:before{ content: im-content($im-var-cog3); }
.#{$im-css-prefix}-cog4:before{ content: im-content($im-var-cog4); }
.#{$im-css-prefix}-cog52:before{ content: im-content($im-var-cog52); }
.#{$im-css-prefix}-cog6:before{ content: im-content($im-var-cog6); }
.#{$im-css-prefix}-cog7:before{ content: im-content($im-var-cog7); }
.#{$im-css-prefix}-hammer:before{ content: im-content($im-var-hammer); }
.#{$im-css-prefix}-hammer-wrench:before{ content: im-content($im-var-hammer-wrench); }
.#{$im-css-prefix}-magic-wand:before{ content: im-content($im-var-magic-wand); }
.#{$im-css-prefix}-magic-wand2:before{ content: im-content($im-var-magic-wand2); }
.#{$im-css-prefix}-pulse2:before{ content: im-content($im-var-pulse2); }
.#{$im-css-prefix}-aid-kit:before{ content: im-content($im-var-aid-kit); }
.#{$im-css-prefix}-bug2:before{ content: im-content($im-var-bug2); }
.#{$im-css-prefix}-construction:before{ content: im-content($im-var-construction); }
.#{$im-css-prefix}-traffic-cone:before{ content: im-content($im-var-traffic-cone); }
.#{$im-css-prefix}-traffic-lights:before{ content: im-content($im-var-traffic-lights); }
.#{$im-css-prefix}-pie-chart:before{ content: im-content($im-var-pie-chart); }
.#{$im-css-prefix}-pie-chart2:before{ content: im-content($im-var-pie-chart2); }
.#{$im-css-prefix}-pie-chart3:before{ content: im-content($im-var-pie-chart3); }
.#{$im-css-prefix}-pie-chart4:before{ content: im-content($im-var-pie-chart4); }
.#{$im-css-prefix}-pie-chart5:before{ content: im-content($im-var-pie-chart5); }
.#{$im-css-prefix}-pie-chart6:before{ content: im-content($im-var-pie-chart6); }
.#{$im-css-prefix}-pie-chart7:before{ content: im-content($im-var-pie-chart7); }
.#{$im-css-prefix}-stats-dots:before{ content: im-content($im-var-stats-dots); }
.#{$im-css-prefix}-stats-bars:before{ content: im-content($im-var-stats-bars); }
.#{$im-css-prefix}-pie-chart8:before{ content: im-content($im-var-pie-chart8); }
.#{$im-css-prefix}-stats-bars2:before{ content: im-content($im-var-stats-bars2); }
.#{$im-css-prefix}-stats-bars3:before{ content: im-content($im-var-stats-bars3); }
.#{$im-css-prefix}-stats-bars4:before{ content: im-content($im-var-stats-bars4); }
.#{$im-css-prefix}-chart:before{ content: im-content($im-var-chart); }
.#{$im-css-prefix}-stats-growth:before{ content: im-content($im-var-stats-growth); }
.#{$im-css-prefix}-stats-decline:before{ content: im-content($im-var-stats-decline); }
.#{$im-css-prefix}-stats-growth2:before{ content: im-content($im-var-stats-growth2); }
.#{$im-css-prefix}-stats-decline2:before{ content: im-content($im-var-stats-decline2); }
.#{$im-css-prefix}-stairs-up:before{ content: im-content($im-var-stairs-up); }
.#{$im-css-prefix}-stairs-down:before{ content: im-content($im-var-stairs-down); }
.#{$im-css-prefix}-stairs:before{ content: im-content($im-var-stairs); }
.#{$im-css-prefix}-ladder:before{ content: im-content($im-var-ladder); }
.#{$im-css-prefix}-rating:before{ content: im-content($im-var-rating); }
.#{$im-css-prefix}-rating2:before{ content: im-content($im-var-rating2); }
.#{$im-css-prefix}-rating3:before{ content: im-content($im-var-rating3); }
.#{$im-css-prefix}-podium:before{ content: im-content($im-var-podium); }
.#{$im-css-prefix}-stars:before{ content: im-content($im-var-stars); }
.#{$im-css-prefix}-medal-star:before{ content: im-content($im-var-medal-star); }
.#{$im-css-prefix}-medal:before{ content: im-content($im-var-medal); }
.#{$im-css-prefix}-medal2:before{ content: im-content($im-var-medal2); }
.#{$im-css-prefix}-medal-first:before{ content: im-content($im-var-medal-first); }
.#{$im-css-prefix}-medal-second:before{ content: im-content($im-var-medal-second); }
.#{$im-css-prefix}-medal-third:before{ content: im-content($im-var-medal-third); }
.#{$im-css-prefix}-crown:before{ content: im-content($im-var-crown); }
.#{$im-css-prefix}-trophy2:before{ content: im-content($im-var-trophy2); }
.#{$im-css-prefix}-trophy3:before{ content: im-content($im-var-trophy3); }
.#{$im-css-prefix}-diamond:before{ content: im-content($im-var-diamond); }
.#{$im-css-prefix}-trophy4:before{ content: im-content($im-var-trophy4); }
.#{$im-css-prefix}-gift:before{ content: im-content($im-var-gift); }
.#{$im-css-prefix}-pipe:before{ content: im-content($im-var-pipe); }
.#{$im-css-prefix}-mustache:before{ content: im-content($im-var-mustache); }
.#{$im-css-prefix}-cup2:before{ content: im-content($im-var-cup2); }
.#{$im-css-prefix}-coffee:before{ content: im-content($im-var-coffee); }
.#{$im-css-prefix}-paw:before{ content: im-content($im-var-paw); }
.#{$im-css-prefix}-footprint:before{ content: im-content($im-var-footprint); }
.#{$im-css-prefix}-rocket:before{ content: im-content($im-var-rocket); }
.#{$im-css-prefix}-meter2:before{ content: im-content($im-var-meter2); }
.#{$im-css-prefix}-meter-slow:before{ content: im-content($im-var-meter-slow); }
.#{$im-css-prefix}-meter-fast:before{ content: im-content($im-var-meter-fast); }
.#{$im-css-prefix}-hammer2:before{ content: im-content($im-var-hammer2); }
.#{$im-css-prefix}-balance:before{ content: im-content($im-var-balance); }
.#{$im-css-prefix}-fire:before{ content: im-content($im-var-fire); }
.#{$im-css-prefix}-fire2:before{ content: im-content($im-var-fire2); }
.#{$im-css-prefix}-lab:before{ content: im-content($im-var-lab); }
.#{$im-css-prefix}-atom:before{ content: im-content($im-var-atom); }
.#{$im-css-prefix}-atom2:before{ content: im-content($im-var-atom2); }
.#{$im-css-prefix}-bin:before{ content: im-content($im-var-bin); }
.#{$im-css-prefix}-bin2:before{ content: im-content($im-var-bin2); }
.#{$im-css-prefix}-briefcase:before{ content: im-content($im-var-briefcase); }
.#{$im-css-prefix}-briefcase3:before{ content: im-content($im-var-briefcase3); }
.#{$im-css-prefix}-airplane2:before{ content: im-content($im-var-airplane2); }
.#{$im-css-prefix}-airplane3:before{ content: im-content($im-var-airplane3); }
.#{$im-css-prefix}-airplane4:before{ content: im-content($im-var-airplane4); }
.#{$im-css-prefix}-paperplane:before{ content: im-content($im-var-paperplane); }
.#{$im-css-prefix}-car:before{ content: im-content($im-var-car); }
.#{$im-css-prefix}-steering-wheel:before{ content: im-content($im-var-steering-wheel); }
.#{$im-css-prefix}-car2:before{ content: im-content($im-var-car2); }
.#{$im-css-prefix}-gas:before{ content: im-content($im-var-gas); }
.#{$im-css-prefix}-bus:before{ content: im-content($im-var-bus); }
.#{$im-css-prefix}-truck:before{ content: im-content($im-var-truck); }
.#{$im-css-prefix}-bike:before{ content: im-content($im-var-bike); }
.#{$im-css-prefix}-road:before{ content: im-content($im-var-road); }
.#{$im-css-prefix}-train:before{ content: im-content($im-var-train); }
.#{$im-css-prefix}-train2:before{ content: im-content($im-var-train2); }
.#{$im-css-prefix}-ship:before{ content: im-content($im-var-ship); }
.#{$im-css-prefix}-boat:before{ content: im-content($im-var-boat); }
.#{$im-css-prefix}-chopper:before{ content: im-content($im-var-chopper); }
.#{$im-css-prefix}-cube:before{ content: im-content($im-var-cube); }
.#{$im-css-prefix}-cube2:before{ content: im-content($im-var-cube2); }
.#{$im-css-prefix}-cube3:before{ content: im-content($im-var-cube3); }
.#{$im-css-prefix}-cube4:before{ content: im-content($im-var-cube4); }
.#{$im-css-prefix}-pyramid:before{ content: im-content($im-var-pyramid); }
.#{$im-css-prefix}-pyramid2:before{ content: im-content($im-var-pyramid2); }
.#{$im-css-prefix}-package:before{ content: im-content($im-var-package); }
.#{$im-css-prefix}-puzzle:before{ content: im-content($im-var-puzzle); }
.#{$im-css-prefix}-puzzle2:before{ content: im-content($im-var-puzzle2); }
.#{$im-css-prefix}-puzzle3:before{ content: im-content($im-var-puzzle3); }
.#{$im-css-prefix}-puzzle4:before{ content: im-content($im-var-puzzle4); }
.#{$im-css-prefix}-glasses-3d2:before{ content: im-content($im-var-glasses-3d2); }
.#{$im-css-prefix}-brain:before{ content: im-content($im-var-brain); }
.#{$im-css-prefix}-accessibility:before{ content: im-content($im-var-accessibility); }
.#{$im-css-prefix}-accessibility2:before{ content: im-content($im-var-accessibility2); }
.#{$im-css-prefix}-strategy:before{ content: im-content($im-var-strategy); }
.#{$im-css-prefix}-target:before{ content: im-content($im-var-target); }
.#{$im-css-prefix}-target2:before{ content: im-content($im-var-target2); }
.#{$im-css-prefix}-shield-check:before{ content: im-content($im-var-shield-check); }
.#{$im-css-prefix}-shield-notice:before{ content: im-content($im-var-shield-notice); }
.#{$im-css-prefix}-shield2:before{ content: im-content($im-var-shield2); }
.#{$im-css-prefix}-racing:before{ content: im-content($im-var-racing); }
.#{$im-css-prefix}-finish:before{ content: im-content($im-var-finish); }
.#{$im-css-prefix}-power2:before{ content: im-content($im-var-power2); }
.#{$im-css-prefix}-power3:before{ content: im-content($im-var-power3); }
.#{$im-css-prefix}-switch:before{ content: im-content($im-var-switch); }
.#{$im-css-prefix}-switch22:before{ content: im-content($im-var-switch22); }
.#{$im-css-prefix}-power-cord:before{ content: im-content($im-var-power-cord); }
.#{$im-css-prefix}-clipboard:before{ content: im-content($im-var-clipboard); }
.#{$im-css-prefix}-clipboard2:before{ content: im-content($im-var-clipboard2); }
.#{$im-css-prefix}-clipboard3:before{ content: im-content($im-var-clipboard3); }
.#{$im-css-prefix}-clipboard4:before{ content: im-content($im-var-clipboard4); }
.#{$im-css-prefix}-clipboard5:before{ content: im-content($im-var-clipboard5); }
.#{$im-css-prefix}-clipboard6:before{ content: im-content($im-var-clipboard6); }
.#{$im-css-prefix}-playlist:before{ content: im-content($im-var-playlist); }
.#{$im-css-prefix}-playlist-add:before{ content: im-content($im-var-playlist-add); }
.#{$im-css-prefix}-list-numbered:before{ content: im-content($im-var-list-numbered); }
.#{$im-css-prefix}-list:before{ content: im-content($im-var-list); }
.#{$im-css-prefix}-list2:before{ content: im-content($im-var-list2); }
.#{$im-css-prefix}-more:before{ content: im-content($im-var-more); }
.#{$im-css-prefix}-more2:before{ content: im-content($im-var-more2); }
.#{$im-css-prefix}-grid:before{ content: im-content($im-var-grid); }
.#{$im-css-prefix}-grid2:before{ content: im-content($im-var-grid2); }
.#{$im-css-prefix}-grid3:before{ content: im-content($im-var-grid3); }
.#{$im-css-prefix}-grid4:before{ content: im-content($im-var-grid4); }
.#{$im-css-prefix}-grid52:before{ content: im-content($im-var-grid52); }
.#{$im-css-prefix}-grid6:before{ content: im-content($im-var-grid6); }
.#{$im-css-prefix}-grid7:before{ content: im-content($im-var-grid7); }
.#{$im-css-prefix}-tree5:before{ content: im-content($im-var-tree5); }
.#{$im-css-prefix}-tree6:before{ content: im-content($im-var-tree6); }
.#{$im-css-prefix}-tree7:before{ content: im-content($im-var-tree7); }
.#{$im-css-prefix}-lan:before{ content: im-content($im-var-lan); }
.#{$im-css-prefix}-lan2:before{ content: im-content($im-var-lan2); }
.#{$im-css-prefix}-lan3:before{ content: im-content($im-var-lan3); }
.#{$im-css-prefix}-menu:before{ content: im-content($im-var-menu); }
.#{$im-css-prefix}-circle-small:before{ content: im-content($im-var-circle-small); }
.#{$im-css-prefix}-menu2:before{ content: im-content($im-var-menu2); }
.#{$im-css-prefix}-menu3:before{ content: im-content($im-var-menu3); }
.#{$im-css-prefix}-menu4:before{ content: im-content($im-var-menu4); }
.#{$im-css-prefix}-menu5:before{ content: im-content($im-var-menu5); }
.#{$im-css-prefix}-menu62:before{ content: im-content($im-var-menu62); }
.#{$im-css-prefix}-menu7:before{ content: im-content($im-var-menu7); }
.#{$im-css-prefix}-menu8:before{ content: im-content($im-var-menu8); }
.#{$im-css-prefix}-menu9:before{ content: im-content($im-var-menu9); }
.#{$im-css-prefix}-menu10:before{ content: im-content($im-var-menu10); }
.#{$im-css-prefix}-cloud:before{ content: im-content($im-var-cloud); }
.#{$im-css-prefix}-cloud-download:before{ content: im-content($im-var-cloud-download); }
.#{$im-css-prefix}-cloud-upload:before{ content: im-content($im-var-cloud-upload); }
.#{$im-css-prefix}-cloud-check:before{ content: im-content($im-var-cloud-check); }
.#{$im-css-prefix}-cloud2:before{ content: im-content($im-var-cloud2); }
.#{$im-css-prefix}-cloud-download2:before{ content: im-content($im-var-cloud-download2); }
.#{$im-css-prefix}-cloud-upload2:before{ content: im-content($im-var-cloud-upload2); }
.#{$im-css-prefix}-cloud-check2:before{ content: im-content($im-var-cloud-check2); }
.#{$im-css-prefix}-import:before{ content: im-content($im-var-import); }
.#{$im-css-prefix}-download4:before{ content: im-content($im-var-download4); }
.#{$im-css-prefix}-upload4:before{ content: im-content($im-var-upload4); }
.#{$im-css-prefix}-download7:before{ content: im-content($im-var-download7); }
.#{$im-css-prefix}-upload7:before{ content: im-content($im-var-upload7); }
.#{$im-css-prefix}-download10:before{ content: im-content($im-var-download10); }
.#{$im-css-prefix}-upload10:before{ content: im-content($im-var-upload10); }
.#{$im-css-prefix}-sphere:before{ content: im-content($im-var-sphere); }
.#{$im-css-prefix}-sphere3:before{ content: im-content($im-var-sphere3); }
.#{$im-css-prefix}-earth:before{ content: im-content($im-var-earth); }
.#{$im-css-prefix}-link:before{ content: im-content($im-var-link); }
.#{$im-css-prefix}-unlink:before{ content: im-content($im-var-unlink); }
.#{$im-css-prefix}-link2:before{ content: im-content($im-var-link2); }
.#{$im-css-prefix}-unlink2:before{ content: im-content($im-var-unlink2); }
.#{$im-css-prefix}-anchor:before{ content: im-content($im-var-anchor); }
.#{$im-css-prefix}-flag3:before{ content: im-content($im-var-flag3); }
.#{$im-css-prefix}-flag4:before{ content: im-content($im-var-flag4); }
.#{$im-css-prefix}-flag7:before{ content: im-content($im-var-flag7); }
.#{$im-css-prefix}-flag8:before{ content: im-content($im-var-flag8); }
.#{$im-css-prefix}-attachment:before{ content: im-content($im-var-attachment); }
.#{$im-css-prefix}-attachment2:before{ content: im-content($im-var-attachment2); }
.#{$im-css-prefix}-eye:before{ content: im-content($im-var-eye); }
.#{$im-css-prefix}-eye-plus:before{ content: im-content($im-var-eye-plus); }
.#{$im-css-prefix}-eye-minus:before{ content: im-content($im-var-eye-minus); }
.#{$im-css-prefix}-eye-blocked:before{ content: im-content($im-var-eye-blocked); }
.#{$im-css-prefix}-eye2:before{ content: im-content($im-var-eye2); }
.#{$im-css-prefix}-eye-blocked2:before{ content: im-content($im-var-eye-blocked2); }
.#{$im-css-prefix}-eye4:before{ content: im-content($im-var-eye4); }
.#{$im-css-prefix}-bookmark2:before{ content: im-content($im-var-bookmark2); }
.#{$im-css-prefix}-bookmark3:before{ content: im-content($im-var-bookmark3); }
.#{$im-css-prefix}-bookmarks:before{ content: im-content($im-var-bookmarks); }
.#{$im-css-prefix}-bookmark4:before{ content: im-content($im-var-bookmark4); }
.#{$im-css-prefix}-spotlight2:before{ content: im-content($im-var-spotlight2); }
.#{$im-css-prefix}-starburst:before{ content: im-content($im-var-starburst); }
.#{$im-css-prefix}-snowflake:before{ content: im-content($im-var-snowflake); }
.#{$im-css-prefix}-weather-windy:before{ content: im-content($im-var-weather-windy); }
.#{$im-css-prefix}-fan:before{ content: im-content($im-var-fan); }
.#{$im-css-prefix}-umbrella:before{ content: im-content($im-var-umbrella); }
.#{$im-css-prefix}-sun3:before{ content: im-content($im-var-sun3); }
.#{$im-css-prefix}-contrast:before{ content: im-content($im-var-contrast); }
.#{$im-css-prefix}-bed2:before{ content: im-content($im-var-bed2); }
.#{$im-css-prefix}-furniture:before{ content: im-content($im-var-furniture); }
.#{$im-css-prefix}-chair:before{ content: im-content($im-var-chair); }
.#{$im-css-prefix}-star-empty3:before{ content: im-content($im-var-star-empty3); }
.#{$im-css-prefix}-star-half:before{ content: im-content($im-var-star-half); }
.#{$im-css-prefix}-star-full2:before{ content: im-content($im-var-star-full2); }
.#{$im-css-prefix}-heart5:before{ content: im-content($im-var-heart5); }
.#{$im-css-prefix}-heart6:before{ content: im-content($im-var-heart6); }
.#{$im-css-prefix}-heart-broken2:before{ content: im-content($im-var-heart-broken2); }
.#{$im-css-prefix}-thumbs-up2:before{ content: im-content($im-var-thumbs-up2); }
.#{$im-css-prefix}-thumbs-down2:before{ content: im-content($im-var-thumbs-down2); }
.#{$im-css-prefix}-thumbs-up3:before{ content: im-content($im-var-thumbs-up3); }
.#{$im-css-prefix}-thumbs-down3:before{ content: im-content($im-var-thumbs-down3); }
.#{$im-css-prefix}-height:before{ content: im-content($im-var-height); }
.#{$im-css-prefix}-man:before{ content: im-content($im-var-man); }
.#{$im-css-prefix}-woman:before{ content: im-content($im-var-woman); }
.#{$im-css-prefix}-man-woman:before{ content: im-content($im-var-man-woman); }
.#{$im-css-prefix}-yin-yang:before{ content: im-content($im-var-yin-yang); }
.#{$im-css-prefix}-cursor:before{ content: im-content($im-var-cursor); }
.#{$im-css-prefix}-cursor2:before{ content: im-content($im-var-cursor2); }
.#{$im-css-prefix}-lasso2:before{ content: im-content($im-var-lasso2); }
.#{$im-css-prefix}-select2:before{ content: im-content($im-var-select2); }
.#{$im-css-prefix}-point-up:before{ content: im-content($im-var-point-up); }
.#{$im-css-prefix}-point-right:before{ content: im-content($im-var-point-right); }
.#{$im-css-prefix}-point-down:before{ content: im-content($im-var-point-down); }
.#{$im-css-prefix}-point-left:before{ content: im-content($im-var-point-left); }
.#{$im-css-prefix}-pointer:before{ content: im-content($im-var-pointer); }
.#{$im-css-prefix}-reminder:before{ content: im-content($im-var-reminder); }
.#{$im-css-prefix}-drag-left-right:before{ content: im-content($im-var-drag-left-right); }
.#{$im-css-prefix}-drag-left:before{ content: im-content($im-var-drag-left); }
.#{$im-css-prefix}-drag-right:before{ content: im-content($im-var-drag-right); }
.#{$im-css-prefix}-touch:before{ content: im-content($im-var-touch); }
.#{$im-css-prefix}-multitouch:before{ content: im-content($im-var-multitouch); }
.#{$im-css-prefix}-touch-zoom:before{ content: im-content($im-var-touch-zoom); }
.#{$im-css-prefix}-touch-pinch:before{ content: im-content($im-var-touch-pinch); }
.#{$im-css-prefix}-hand:before{ content: im-content($im-var-hand); }
.#{$im-css-prefix}-grab:before{ content: im-content($im-var-grab); }
.#{$im-css-prefix}-stack-empty:before{ content: im-content($im-var-stack-empty); }
.#{$im-css-prefix}-stack-plus:before{ content: im-content($im-var-stack-plus); }
.#{$im-css-prefix}-stack-minus:before{ content: im-content($im-var-stack-minus); }
.#{$im-css-prefix}-stack-star:before{ content: im-content($im-var-stack-star); }
.#{$im-css-prefix}-stack-picture:before{ content: im-content($im-var-stack-picture); }
.#{$im-css-prefix}-stack-down:before{ content: im-content($im-var-stack-down); }
.#{$im-css-prefix}-stack-up:before{ content: im-content($im-var-stack-up); }
.#{$im-css-prefix}-stack-cancel:before{ content: im-content($im-var-stack-cancel); }
.#{$im-css-prefix}-stack-check:before{ content: im-content($im-var-stack-check); }
.#{$im-css-prefix}-stack-text:before{ content: im-content($im-var-stack-text); }
.#{$im-css-prefix}-stack4:before{ content: im-content($im-var-stack4); }
.#{$im-css-prefix}-stack-music:before{ content: im-content($im-var-stack-music); }
.#{$im-css-prefix}-stack-play:before{ content: im-content($im-var-stack-play); }
.#{$im-css-prefix}-move:before{ content: im-content($im-var-move); }
.#{$im-css-prefix}-dots:before{ content: im-content($im-var-dots); }
.#{$im-css-prefix}-warning:before{ content: im-content($im-var-warning); }
.#{$im-css-prefix}-warning22:before{ content: im-content($im-var-warning22); }
.#{$im-css-prefix}-notification2:before{ content: im-content($im-var-notification2); }
.#{$im-css-prefix}-question3:before{ content: im-content($im-var-question3); }
.#{$im-css-prefix}-question4:before{ content: im-content($im-var-question4); }
.#{$im-css-prefix}-plus3:before{ content: im-content($im-var-plus3); }
.#{$im-css-prefix}-minus3:before{ content: im-content($im-var-minus3); }
.#{$im-css-prefix}-plus-circle2:before{ content: im-content($im-var-plus-circle2); }
.#{$im-css-prefix}-minus-circle2:before{ content: im-content($im-var-minus-circle2); }
.#{$im-css-prefix}-cancel-circle2:before{ content: im-content($im-var-cancel-circle2); }
.#{$im-css-prefix}-blocked:before{ content: im-content($im-var-blocked); }
.#{$im-css-prefix}-cancel-square:before{ content: im-content($im-var-cancel-square); }
.#{$im-css-prefix}-cancel-square2:before{ content: im-content($im-var-cancel-square2); }
.#{$im-css-prefix}-spam:before{ content: im-content($im-var-spam); }
.#{$im-css-prefix}-cross2:before{ content: im-content($im-var-cross2); }
.#{$im-css-prefix}-cross3:before{ content: im-content($im-var-cross3); }
.#{$im-css-prefix}-checkmark:before{ content: im-content($im-var-checkmark); }
.#{$im-css-prefix}-checkmark3:before{ content: im-content($im-var-checkmark3); }
.#{$im-css-prefix}-checkmark2:before{ content: im-content($im-var-checkmark2); }
.#{$im-css-prefix}-checkmark4:before{ content: im-content($im-var-checkmark4); }
.#{$im-css-prefix}-spell-check:before{ content: im-content($im-var-spell-check); }
.#{$im-css-prefix}-spell-check2:before{ content: im-content($im-var-spell-check2); }
.#{$im-css-prefix}-enter:before{ content: im-content($im-var-enter); }
.#{$im-css-prefix}-exit:before{ content: im-content($im-var-exit); }
.#{$im-css-prefix}-enter2:before{ content: im-content($im-var-enter2); }
.#{$im-css-prefix}-exit2:before{ content: im-content($im-var-exit2); }
.#{$im-css-prefix}-enter3:before{ content: im-content($im-var-enter3); }
.#{$im-css-prefix}-exit3:before{ content: im-content($im-var-exit3); }
.#{$im-css-prefix}-wall:before{ content: im-content($im-var-wall); }
.#{$im-css-prefix}-fence:before{ content: im-content($im-var-fence); }
.#{$im-css-prefix}-play3:before{ content: im-content($im-var-play3); }
.#{$im-css-prefix}-pause:before{ content: im-content($im-var-pause); }
.#{$im-css-prefix}-stop:before{ content: im-content($im-var-stop); }
.#{$im-css-prefix}-previous:before{ content: im-content($im-var-previous); }
.#{$im-css-prefix}-next:before{ content: im-content($im-var-next); }
.#{$im-css-prefix}-backward:before{ content: im-content($im-var-backward); }
.#{$im-css-prefix}-forward2:before{ content: im-content($im-var-forward2); }
.#{$im-css-prefix}-play4:before{ content: im-content($im-var-play4); }
.#{$im-css-prefix}-pause2:before{ content: im-content($im-var-pause2); }
.#{$im-css-prefix}-stop2:before{ content: im-content($im-var-stop2); }
.#{$im-css-prefix}-backward2:before{ content: im-content($im-var-backward2); }
.#{$im-css-prefix}-forward3:before{ content: im-content($im-var-forward3); }
.#{$im-css-prefix}-first:before{ content: im-content($im-var-first); }
.#{$im-css-prefix}-last:before{ content: im-content($im-var-last); }
.#{$im-css-prefix}-previous2:before{ content: im-content($im-var-previous2); }
.#{$im-css-prefix}-next2:before{ content: im-content($im-var-next2); }
.#{$im-css-prefix}-eject:before{ content: im-content($im-var-eject); }
.#{$im-css-prefix}-volume-high:before{ content: im-content($im-var-volume-high); }
.#{$im-css-prefix}-volume-medium:before{ content: im-content($im-var-volume-medium); }
.#{$im-css-prefix}-volume-low:before{ content: im-content($im-var-volume-low); }
.#{$im-css-prefix}-volume-mute:before{ content: im-content($im-var-volume-mute); }
.#{$im-css-prefix}-speaker-left:before{ content: im-content($im-var-speaker-left); }
.#{$im-css-prefix}-speaker-right:before{ content: im-content($im-var-speaker-right); }
.#{$im-css-prefix}-volume-mute2:before{ content: im-content($im-var-volume-mute2); }
.#{$im-css-prefix}-volume-increase:before{ content: im-content($im-var-volume-increase); }
.#{$im-css-prefix}-volume-decrease:before{ content: im-content($im-var-volume-decrease); }
.#{$im-css-prefix}-volume-mute5:before{ content: im-content($im-var-volume-mute5); }
.#{$im-css-prefix}-loop:before{ content: im-content($im-var-loop); }
.#{$im-css-prefix}-loop3:before{ content: im-content($im-var-loop3); }
.#{$im-css-prefix}-infinite-square:before{ content: im-content($im-var-infinite-square); }
.#{$im-css-prefix}-infinite:before{ content: im-content($im-var-infinite); }
.#{$im-css-prefix}-loop4:before{ content: im-content($im-var-loop4); }
.#{$im-css-prefix}-shuffle:before{ content: im-content($im-var-shuffle); }
.#{$im-css-prefix}-wave:before{ content: im-content($im-var-wave); }
.#{$im-css-prefix}-wave2:before{ content: im-content($im-var-wave2); }
.#{$im-css-prefix}-split:before{ content: im-content($im-var-split); }
.#{$im-css-prefix}-merge:before{ content: im-content($im-var-merge); }
.#{$im-css-prefix}-arrow-up5:before{ content: im-content($im-var-arrow-up5); }
.#{$im-css-prefix}-arrow-right5:before{ content: im-content($im-var-arrow-right5); }
.#{$im-css-prefix}-arrow-down5:before{ content: im-content($im-var-arrow-down5); }
.#{$im-css-prefix}-arrow-left5:before{ content: im-content($im-var-arrow-left5); }
.#{$im-css-prefix}-arrow-up-left2:before{ content: im-content($im-var-arrow-up-left2); }
.#{$im-css-prefix}-arrow-up7:before{ content: im-content($im-var-arrow-up7); }
.#{$im-css-prefix}-arrow-up-right2:before{ content: im-content($im-var-arrow-up-right2); }
.#{$im-css-prefix}-arrow-right7:before{ content: im-content($im-var-arrow-right7); }
.#{$im-css-prefix}-arrow-down-right2:before{ content: im-content($im-var-arrow-down-right2); }
.#{$im-css-prefix}-arrow-down7:before{ content: im-content($im-var-arrow-down7); }
.#{$im-css-prefix}-arrow-down-left2:before{ content: im-content($im-var-arrow-down-left2); }
.#{$im-css-prefix}-arrow-left7:before{ content: im-content($im-var-arrow-left7); }
.#{$im-css-prefix}-arrow-up-left3:before{ content: im-content($im-var-arrow-up-left3); }
.#{$im-css-prefix}-arrow-up8:before{ content: im-content($im-var-arrow-up8); }
.#{$im-css-prefix}-arrow-up-right3:before{ content: im-content($im-var-arrow-up-right3); }
.#{$im-css-prefix}-arrow-right8:before{ content: im-content($im-var-arrow-right8); }
.#{$im-css-prefix}-arrow-down-right3:before{ content: im-content($im-var-arrow-down-right3); }
.#{$im-css-prefix}-arrow-down8:before{ content: im-content($im-var-arrow-down8); }
.#{$im-css-prefix}-arrow-down-left3:before{ content: im-content($im-var-arrow-down-left3); }
.#{$im-css-prefix}-arrow-left8:before{ content: im-content($im-var-arrow-left8); }
.#{$im-css-prefix}-circle-up2:before{ content: im-content($im-var-circle-up2); }
.#{$im-css-prefix}-circle-right2:before{ content: im-content($im-var-circle-right2); }
.#{$im-css-prefix}-circle-down2:before{ content: im-content($im-var-circle-down2); }
.#{$im-css-prefix}-circle-left2:before{ content: im-content($im-var-circle-left2); }
.#{$im-css-prefix}-arrow-resize7:before{ content: im-content($im-var-arrow-resize7); }
.#{$im-css-prefix}-arrow-resize8:before{ content: im-content($im-var-arrow-resize8); }
.#{$im-css-prefix}-square-up-left:before{ content: im-content($im-var-square-up-left); }
.#{$im-css-prefix}-square-up:before{ content: im-content($im-var-square-up); }
.#{$im-css-prefix}-square-up-right:before{ content: im-content($im-var-square-up-right); }
.#{$im-css-prefix}-square-right:before{ content: im-content($im-var-square-right); }
.#{$im-css-prefix}-square-down-right:before{ content: im-content($im-var-square-down-right); }
.#{$im-css-prefix}-square-down:before{ content: im-content($im-var-square-down); }
.#{$im-css-prefix}-square-down-left:before{ content: im-content($im-var-square-down-left); }
.#{$im-css-prefix}-square-left:before{ content: im-content($im-var-square-left); }
.#{$im-css-prefix}-arrow-up15:before{ content: im-content($im-var-arrow-up15); }
.#{$im-css-prefix}-arrow-right15:before{ content: im-content($im-var-arrow-right15); }
.#{$im-css-prefix}-arrow-down15:before{ content: im-content($im-var-arrow-down15); }
.#{$im-css-prefix}-arrow-left15:before{ content: im-content($im-var-arrow-left15); }
.#{$im-css-prefix}-arrow-up16:before{ content: im-content($im-var-arrow-up16); }
.#{$im-css-prefix}-arrow-right16:before{ content: im-content($im-var-arrow-right16); }
.#{$im-css-prefix}-arrow-down16:before{ content: im-content($im-var-arrow-down16); }
.#{$im-css-prefix}-arrow-left16:before{ content: im-content($im-var-arrow-left16); }
.#{$im-css-prefix}-menu-open:before{ content: im-content($im-var-menu-open); }
.#{$im-css-prefix}-menu-open2:before{ content: im-content($im-var-menu-open2); }
.#{$im-css-prefix}-menu-close:before{ content: im-content($im-var-menu-close); }
.#{$im-css-prefix}-menu-close2:before{ content: im-content($im-var-menu-close2); }
.#{$im-css-prefix}-enter5:before{ content: im-content($im-var-enter5); }
.#{$im-css-prefix}-esc:before{ content: im-content($im-var-esc); }
.#{$im-css-prefix}-enter6:before{ content: im-content($im-var-enter6); }
.#{$im-css-prefix}-backspace:before{ content: im-content($im-var-backspace); }
.#{$im-css-prefix}-backspace2:before{ content: im-content($im-var-backspace2); }
.#{$im-css-prefix}-tab:before{ content: im-content($im-var-tab); }
.#{$im-css-prefix}-transmission:before{ content: im-content($im-var-transmission); }
.#{$im-css-prefix}-sort:before{ content: im-content($im-var-sort); }
.#{$im-css-prefix}-move-up2:before{ content: im-content($im-var-move-up2); }
.#{$im-css-prefix}-move-down2:before{ content: im-content($im-var-move-down2); }
.#{$im-css-prefix}-sort-alpha-asc:before{ content: im-content($im-var-sort-alpha-asc); }
.#{$im-css-prefix}-sort-alpha-desc:before{ content: im-content($im-var-sort-alpha-desc); }
.#{$im-css-prefix}-sort-numeric-asc:before{ content: im-content($im-var-sort-numeric-asc); }
.#{$im-css-prefix}-sort-numberic-desc:before{ content: im-content($im-var-sort-numberic-desc); }
.#{$im-css-prefix}-sort-amount-asc:before{ content: im-content($im-var-sort-amount-asc); }
.#{$im-css-prefix}-sort-amount-desc:before{ content: im-content($im-var-sort-amount-desc); }
.#{$im-css-prefix}-sort-time-asc:before{ content: im-content($im-var-sort-time-asc); }
.#{$im-css-prefix}-sort-time-desc:before{ content: im-content($im-var-sort-time-desc); }
.#{$im-css-prefix}-battery-6:before{ content: im-content($im-var-battery-6); }
.#{$im-css-prefix}-battery-0:before{ content: im-content($im-var-battery-0); }
.#{$im-css-prefix}-battery-charging:before{ content: im-content($im-var-battery-charging); }
.#{$im-css-prefix}-command:before{ content: im-content($im-var-command); }
.#{$im-css-prefix}-shift:before{ content: im-content($im-var-shift); }
.#{$im-css-prefix}-ctrl:before{ content: im-content($im-var-ctrl); }
.#{$im-css-prefix}-opt:before{ content: im-content($im-var-opt); }
.#{$im-css-prefix}-checkbox-checked:before{ content: im-content($im-var-checkbox-checked); }
.#{$im-css-prefix}-checkbox-unchecked:before{ content: im-content($im-var-checkbox-unchecked); }
.#{$im-css-prefix}-checkbox-partial:before{ content: im-content($im-var-checkbox-partial); }
.#{$im-css-prefix}-square:before{ content: im-content($im-var-square); }
.#{$im-css-prefix}-triangle:before{ content: im-content($im-var-triangle); }
.#{$im-css-prefix}-triangle2:before{ content: im-content($im-var-triangle2); }
.#{$im-css-prefix}-diamond3:before{ content: im-content($im-var-diamond3); }
.#{$im-css-prefix}-diamond4:before{ content: im-content($im-var-diamond4); }
.#{$im-css-prefix}-checkbox-checked2:before{ content: im-content($im-var-checkbox-checked2); }
.#{$im-css-prefix}-checkbox-unchecked2:before{ content: im-content($im-var-checkbox-unchecked2); }
.#{$im-css-prefix}-checkbox-partial2:before{ content: im-content($im-var-checkbox-partial2); }
.#{$im-css-prefix}-radio-checked:before{ content: im-content($im-var-radio-checked); }
.#{$im-css-prefix}-radio-checked2:before{ content: im-content($im-var-radio-checked2); }
.#{$im-css-prefix}-radio-unchecked:before{ content: im-content($im-var-radio-unchecked); }
.#{$im-css-prefix}-checkmark-circle:before{ content: im-content($im-var-checkmark-circle); }
.#{$im-css-prefix}-circle:before{ content: im-content($im-var-circle); }
.#{$im-css-prefix}-circle2:before{ content: im-content($im-var-circle2); }
.#{$im-css-prefix}-circles:before{ content: im-content($im-var-circles); }
.#{$im-css-prefix}-circles2:before{ content: im-content($im-var-circles2); }
.#{$im-css-prefix}-crop:before{ content: im-content($im-var-crop); }
.#{$im-css-prefix}-crop2:before{ content: im-content($im-var-crop2); }
.#{$im-css-prefix}-make-group:before{ content: im-content($im-var-make-group); }
.#{$im-css-prefix}-ungroup:before{ content: im-content($im-var-ungroup); }
.#{$im-css-prefix}-vector:before{ content: im-content($im-var-vector); }
.#{$im-css-prefix}-vector2:before{ content: im-content($im-var-vector2); }
.#{$im-css-prefix}-rulers:before{ content: im-content($im-var-rulers); }
.#{$im-css-prefix}-pencil-ruler:before{ content: im-content($im-var-pencil-ruler); }
.#{$im-css-prefix}-scissors:before{ content: im-content($im-var-scissors); }
.#{$im-css-prefix}-filter3:before{ content: im-content($im-var-filter3); }
.#{$im-css-prefix}-filter4:before{ content: im-content($im-var-filter4); }
.#{$im-css-prefix}-font:before{ content: im-content($im-var-font); }
.#{$im-css-prefix}-ampersand2:before{ content: im-content($im-var-ampersand2); }
.#{$im-css-prefix}-ligature:before{ content: im-content($im-var-ligature); }
.#{$im-css-prefix}-font-size:before{ content: im-content($im-var-font-size); }
.#{$im-css-prefix}-typography:before{ content: im-content($im-var-typography); }
.#{$im-css-prefix}-text-height:before{ content: im-content($im-var-text-height); }
.#{$im-css-prefix}-text-width:before{ content: im-content($im-var-text-width); }
.#{$im-css-prefix}-height2:before{ content: im-content($im-var-height2); }
.#{$im-css-prefix}-width:before{ content: im-content($im-var-width); }
.#{$im-css-prefix}-strikethrough2:before{ content: im-content($im-var-strikethrough2); }
.#{$im-css-prefix}-font-size2:before{ content: im-content($im-var-font-size2); }
.#{$im-css-prefix}-bold2:before{ content: im-content($im-var-bold2); }
.#{$im-css-prefix}-underline2:before{ content: im-content($im-var-underline2); }
.#{$im-css-prefix}-italic2:before{ content: im-content($im-var-italic2); }
.#{$im-css-prefix}-strikethrough3:before{ content: im-content($im-var-strikethrough3); }
.#{$im-css-prefix}-omega:before{ content: im-content($im-var-omega); }
.#{$im-css-prefix}-sigma:before{ content: im-content($im-var-sigma); }
.#{$im-css-prefix}-nbsp:before{ content: im-content($im-var-nbsp); }
.#{$im-css-prefix}-page-break:before{ content: im-content($im-var-page-break); }
.#{$im-css-prefix}-page-break2:before{ content: im-content($im-var-page-break2); }
.#{$im-css-prefix}-superscript:before{ content: im-content($im-var-superscript); }
.#{$im-css-prefix}-subscript:before{ content: im-content($im-var-subscript); }
.#{$im-css-prefix}-superscript2:before{ content: im-content($im-var-superscript2); }
.#{$im-css-prefix}-subscript2:before{ content: im-content($im-var-subscript2); }
.#{$im-css-prefix}-text-color:before{ content: im-content($im-var-text-color); }
.#{$im-css-prefix}-highlight:before{ content: im-content($im-var-highlight); }
.#{$im-css-prefix}-pagebreak:before{ content: im-content($im-var-pagebreak); }
.#{$im-css-prefix}-clear-formatting:before{ content: im-content($im-var-clear-formatting); }
.#{$im-css-prefix}-table:before{ content: im-content($im-var-table); }
.#{$im-css-prefix}-table2:before{ content: im-content($im-var-table2); }
.#{$im-css-prefix}-insert-template:before{ content: im-content($im-var-insert-template); }
.#{$im-css-prefix}-pilcrow:before{ content: im-content($im-var-pilcrow); }
.#{$im-css-prefix}-ltr:before{ content: im-content($im-var-ltr); }
.#{$im-css-prefix}-rtl:before{ content: im-content($im-var-rtl); }
.#{$im-css-prefix}-ltr2:before{ content: im-content($im-var-ltr2); }
.#{$im-css-prefix}-rtl2:before{ content: im-content($im-var-rtl2); }
.#{$im-css-prefix}-section:before{ content: im-content($im-var-section); }
.#{$im-css-prefix}-paragraph-left2:before{ content: im-content($im-var-paragraph-left2); }
.#{$im-css-prefix}-paragraph-center2:before{ content: im-content($im-var-paragraph-center2); }
.#{$im-css-prefix}-paragraph-right2:before{ content: im-content($im-var-paragraph-right2); }
.#{$im-css-prefix}-paragraph-justify2:before{ content: im-content($im-var-paragraph-justify2); }
.#{$im-css-prefix}-indent-increase:before{ content: im-content($im-var-indent-increase); }
.#{$im-css-prefix}-indent-decrease:before{ content: im-content($im-var-indent-decrease); }
.#{$im-css-prefix}-paragraph-left3:before{ content: im-content($im-var-paragraph-left3); }
.#{$im-css-prefix}-paragraph-center3:before{ content: im-content($im-var-paragraph-center3); }
.#{$im-css-prefix}-paragraph-right3:before{ content: im-content($im-var-paragraph-right3); }
.#{$im-css-prefix}-paragraph-justify3:before{ content: im-content($im-var-paragraph-justify3); }
.#{$im-css-prefix}-indent-increase2:before{ content: im-content($im-var-indent-increase2); }
.#{$im-css-prefix}-indent-decrease2:before{ content: im-content($im-var-indent-decrease2); }
.#{$im-css-prefix}-share:before{ content: im-content($im-var-share); }
.#{$im-css-prefix}-share2:before{ content: im-content($im-var-share2); }
.#{$im-css-prefix}-new-tab:before{ content: im-content($im-var-new-tab); }
.#{$im-css-prefix}-new-tab2:before{ content: im-content($im-var-new-tab2); }
.#{$im-css-prefix}-popout:before{ content: im-content($im-var-popout); }
.#{$im-css-prefix}-embed:before{ content: im-content($im-var-embed); }
.#{$im-css-prefix}-embed2:before{ content: im-content($im-var-embed2); }
.#{$im-css-prefix}-markup:before{ content: im-content($im-var-markup); }
.#{$im-css-prefix}-regexp:before{ content: im-content($im-var-regexp); }
.#{$im-css-prefix}-regexp2:before{ content: im-content($im-var-regexp2); }
.#{$im-css-prefix}-code:before{ content: im-content($im-var-code); }
.#{$im-css-prefix}-circle-css:before{ content: im-content($im-var-circle-css); }
.#{$im-css-prefix}-circle-code:before{ content: im-content($im-var-circle-code); }
.#{$im-css-prefix}-terminal:before{ content: im-content($im-var-terminal); }
.#{$im-css-prefix}-unicode:before{ content: im-content($im-var-unicode); }
.#{$im-css-prefix}-seven-segment-0:before{ content: im-content($im-var-seven-segment-0); }
.#{$im-css-prefix}-seven-segment-1:before{ content: im-content($im-var-seven-segment-1); }
.#{$im-css-prefix}-seven-segment-2:before{ content: im-content($im-var-seven-segment-2); }
.#{$im-css-prefix}-seven-segment-3:before{ content: im-content($im-var-seven-segment-3); }
.#{$im-css-prefix}-seven-segment-4:before{ content: im-content($im-var-seven-segment-4); }
.#{$im-css-prefix}-seven-segment-5:before{ content: im-content($im-var-seven-segment-5); }
.#{$im-css-prefix}-seven-segment-6:before{ content: im-content($im-var-seven-segment-6); }
.#{$im-css-prefix}-seven-segment-7:before{ content: im-content($im-var-seven-segment-7); }
.#{$im-css-prefix}-seven-segment-8:before{ content: im-content($im-var-seven-segment-8); }
.#{$im-css-prefix}-seven-segment-9:before{ content: im-content($im-var-seven-segment-9); }
.#{$im-css-prefix}-share3:before{ content: im-content($im-var-share3); }
.#{$im-css-prefix}-share4:before{ content: im-content($im-var-share4); }
.#{$im-css-prefix}-google:before{ content: im-content($im-var-google); }
.#{$im-css-prefix}-google-plus:before{ content: im-content($im-var-google-plus); }
.#{$im-css-prefix}-google-plus2:before{ content: im-content($im-var-google-plus2); }
.#{$im-css-prefix}-google-drive:before{ content: im-content($im-var-google-drive); }
.#{$im-css-prefix}-facebook:before{ content: im-content($im-var-facebook); }
.#{$im-css-prefix}-facebook2:before{ content: im-content($im-var-facebook2); }
.#{$im-css-prefix}-instagram:before{ content: im-content($im-var-instagram); }
.#{$im-css-prefix}-twitter:before{ content: im-content($im-var-twitter); }
.#{$im-css-prefix}-twitter2:before{ content: im-content($im-var-twitter2); }
.#{$im-css-prefix}-feed2:before{ content: im-content($im-var-feed2); }
.#{$im-css-prefix}-feed3:before{ content: im-content($im-var-feed3); }
.#{$im-css-prefix}-youtube:before{ content: im-content($im-var-youtube); }
.#{$im-css-prefix}-youtube2:before{ content: im-content($im-var-youtube2); }
.#{$im-css-prefix}-youtube3:before{ content: im-content($im-var-youtube3); }
.#{$im-css-prefix}-vimeo:before{ content: im-content($im-var-vimeo); }
.#{$im-css-prefix}-vimeo2:before{ content: im-content($im-var-vimeo2); }
.#{$im-css-prefix}-lanyrd:before{ content: im-content($im-var-lanyrd); }
.#{$im-css-prefix}-flickr:before{ content: im-content($im-var-flickr); }
.#{$im-css-prefix}-flickr2:before{ content: im-content($im-var-flickr2); }
.#{$im-css-prefix}-flickr3:before{ content: im-content($im-var-flickr3); }
.#{$im-css-prefix}-picassa:before{ content: im-content($im-var-picassa); }
.#{$im-css-prefix}-picassa2:before{ content: im-content($im-var-picassa2); }
.#{$im-css-prefix}-dribbble:before{ content: im-content($im-var-dribbble); }
.#{$im-css-prefix}-dribbble2:before{ content: im-content($im-var-dribbble2); }
.#{$im-css-prefix}-dribbble3:before{ content: im-content($im-var-dribbble3); }
.#{$im-css-prefix}-forrst:before{ content: im-content($im-var-forrst); }
.#{$im-css-prefix}-forrst2:before{ content: im-content($im-var-forrst2); }
.#{$im-css-prefix}-deviantart:before{ content: im-content($im-var-deviantart); }
.#{$im-css-prefix}-deviantart2:before{ content: im-content($im-var-deviantart2); }
.#{$im-css-prefix}-steam:before{ content: im-content($im-var-steam); }
.#{$im-css-prefix}-steam2:before{ content: im-content($im-var-steam2); }
.#{$im-css-prefix}-dropbox:before{ content: im-content($im-var-dropbox); }
.#{$im-css-prefix}-onedrive:before{ content: im-content($im-var-onedrive); }
.#{$im-css-prefix}-github:before{ content: im-content($im-var-github); }
.#{$im-css-prefix}-github4:before{ content: im-content($im-var-github4); }
.#{$im-css-prefix}-github5:before{ content: im-content($im-var-github5); }
.#{$im-css-prefix}-wordpress:before{ content: im-content($im-var-wordpress); }
.#{$im-css-prefix}-wordpress2:before{ content: im-content($im-var-wordpress2); }
.#{$im-css-prefix}-joomla:before{ content: im-content($im-var-joomla); }
.#{$im-css-prefix}-blogger:before{ content: im-content($im-var-blogger); }
.#{$im-css-prefix}-blogger2:before{ content: im-content($im-var-blogger2); }
.#{$im-css-prefix}-tumblr:before{ content: im-content($im-var-tumblr); }
.#{$im-css-prefix}-tumblr2:before{ content: im-content($im-var-tumblr2); }
.#{$im-css-prefix}-yahoo:before{ content: im-content($im-var-yahoo); }
.#{$im-css-prefix}-tux:before{ content: im-content($im-var-tux); }
.#{$im-css-prefix}-apple2:before{ content: im-content($im-var-apple2); }
.#{$im-css-prefix}-finder:before{ content: im-content($im-var-finder); }
.#{$im-css-prefix}-android:before{ content: im-content($im-var-android); }
.#{$im-css-prefix}-windows:before{ content: im-content($im-var-windows); }
.#{$im-css-prefix}-windows8:before{ content: im-content($im-var-windows8); }
.#{$im-css-prefix}-soundcloud:before{ content: im-content($im-var-soundcloud); }
.#{$im-css-prefix}-soundcloud2:before{ content: im-content($im-var-soundcloud2); }
.#{$im-css-prefix}-skype:before{ content: im-content($im-var-skype); }
.#{$im-css-prefix}-reddit:before{ content: im-content($im-var-reddit); }
.#{$im-css-prefix}-linkedin:before{ content: im-content($im-var-linkedin); }
.#{$im-css-prefix}-linkedin2:before{ content: im-content($im-var-linkedin2); }
.#{$im-css-prefix}-lastfm:before{ content: im-content($im-var-lastfm); }
.#{$im-css-prefix}-lastfm2:before{ content: im-content($im-var-lastfm2); }
.#{$im-css-prefix}-delicious:before{ content: im-content($im-var-delicious); }
.#{$im-css-prefix}-stumbleupon:before{ content: im-content($im-var-stumbleupon); }
.#{$im-css-prefix}-stumbleupon2:before{ content: im-content($im-var-stumbleupon2); }
.#{$im-css-prefix}-stackoverflow:before{ content: im-content($im-var-stackoverflow); }
.#{$im-css-prefix}-pinterest2:before{ content: im-content($im-var-pinterest2); }
.#{$im-css-prefix}-xing:before{ content: im-content($im-var-xing); }
.#{$im-css-prefix}-flattr:before{ content: im-content($im-var-flattr); }
.#{$im-css-prefix}-foursquare:before{ content: im-content($im-var-foursquare); }
.#{$im-css-prefix}-paypal:before{ content: im-content($im-var-paypal); }
.#{$im-css-prefix}-paypal2:before{ content: im-content($im-var-paypal2); }
.#{$im-css-prefix}-yelp:before{ content: im-content($im-var-yelp); }
.#{$im-css-prefix}-file-pdf:before{ content: im-content($im-var-file-pdf); }
.#{$im-css-prefix}-file-openoffice:before{ content: im-content($im-var-file-openoffice); }
.#{$im-css-prefix}-file-word:before{ content: im-content($im-var-file-word); }
.#{$im-css-prefix}-file-excel:before{ content: im-content($im-var-file-excel); }
.#{$im-css-prefix}-libreoffice:before{ content: im-content($im-var-libreoffice); }
.#{$im-css-prefix}-html5:before{ content: im-content($im-var-html5); }
.#{$im-css-prefix}-html52:before{ content: im-content($im-var-html52); }
.#{$im-css-prefix}-css3:before{ content: im-content($im-var-css3); }
.#{$im-css-prefix}-git:before{ content: im-content($im-var-git); }
.#{$im-css-prefix}-svg:before{ content: im-content($im-var-svg); }
.#{$im-css-prefix}-codepen:before{ content: im-content($im-var-codepen); }
.#{$im-css-prefix}-chrome:before{ content: im-content($im-var-chrome); }
.#{$im-css-prefix}-firefox:before{ content: im-content($im-var-firefox); }
.#{$im-css-prefix}-IE:before{ content: im-content($im-var-IE); }
.#{$im-css-prefix}-opera:before{ content: im-content($im-var-opera); }
.#{$im-css-prefix}-safari:before{ content: im-content($im-var-safari); }
.#{$im-css-prefix}-check2:before{ content: im-content($im-var-check2); }
.#{$im-css-prefix}-home4:before{ content: im-content($im-var-home4); }
.#{$im-css-prefix}-people:before{ content: im-content($im-var-people); }
.#{$im-css-prefix}-checkmark-circle2:before{ content: im-content($im-var-checkmark-circle2); }
.#{$im-css-prefix}-arrow-up-left32:before{ content: im-content($im-var-arrow-up-left32); }
.#{$im-css-prefix}-arrow-up52:before{ content: im-content($im-var-arrow-up52); }
.#{$im-css-prefix}-arrow-up-right32:before{ content: im-content($im-var-arrow-up-right32); }
.#{$im-css-prefix}-arrow-right6:before{ content: im-content($im-var-arrow-right6); }
.#{$im-css-prefix}-arrow-down-right32:before{ content: im-content($im-var-arrow-down-right32); }
.#{$im-css-prefix}-arrow-down52:before{ content: im-content($im-var-arrow-down52); }
.#{$im-css-prefix}-arrow-down-left32:before{ content: im-content($im-var-arrow-down-left32); }
.#{$im-css-prefix}-arrow-left52:before{ content: im-content($im-var-arrow-left52); }
.#{$im-css-prefix}-calendar5:before{ content: im-content($im-var-calendar5); }
.#{$im-css-prefix}-move-alt1:before{ content: im-content($im-var-move-alt1); }
.#{$im-css-prefix}-reload-alt:before{ content: im-content($im-var-reload-alt); }
.#{$im-css-prefix}-move-vertical:before{ content: im-content($im-var-move-vertical); }
.#{$im-css-prefix}-move-horizontal:before{ content: im-content($im-var-move-horizontal); }
.#{$im-css-prefix}-hash:before{ content: im-content($im-var-hash); }
.#{$im-css-prefix}-bars-alt:before{ content: im-content($im-var-bars-alt); }
.#{$im-css-prefix}-eye8:before{ content: im-content($im-var-eye8); }
.#{$im-css-prefix}-search4:before{ content: im-content($im-var-search4); }
.#{$im-css-prefix}-zoomin3:before{ content: im-content($im-var-zoomin3); }
.#{$im-css-prefix}-zoomout3:before{ content: im-content($im-var-zoomout3); }
.#{$im-css-prefix}-add:before{ content: im-content($im-var-add); }
.#{$im-css-prefix}-subtract:before{ content: im-content($im-var-subtract); }
.#{$im-css-prefix}-exclamation:before{ content: im-content($im-var-exclamation); }
.#{$im-css-prefix}-question6:before{ content: im-content($im-var-question6); }
.#{$im-css-prefix}-close2:before{ content: im-content($im-var-close2); }
.#{$im-css-prefix}-task:before{ content: im-content($im-var-task); }
.#{$im-css-prefix}-inbox:before{ content: im-content($im-var-inbox); }
.#{$im-css-prefix}-inbox-alt:before{ content: im-content($im-var-inbox-alt); }
.#{$im-css-prefix}-envelope:before{ content: im-content($im-var-envelope); }
.#{$im-css-prefix}-compose:before{ content: im-content($im-var-compose); }
.#{$im-css-prefix}-newspaper2:before{ content: im-content($im-var-newspaper2); }
.#{$im-css-prefix}-calendar22:before{ content: im-content($im-var-calendar22); }
.#{$im-css-prefix}-hyperlink:before{ content: im-content($im-var-hyperlink); }
.#{$im-css-prefix}-trash:before{ content: im-content($im-var-trash); }
.#{$im-css-prefix}-trash-alt:before{ content: im-content($im-var-trash-alt); }
.#{$im-css-prefix}-grid5:before{ content: im-content($im-var-grid5); }
.#{$im-css-prefix}-grid-alt:before{ content: im-content($im-var-grid-alt); }
.#{$im-css-prefix}-menu6:before{ content: im-content($im-var-menu6); }
.#{$im-css-prefix}-list3:before{ content: im-content($im-var-list3); }
.#{$im-css-prefix}-gallery:before{ content: im-content($im-var-gallery); }
.#{$im-css-prefix}-calculator:before{ content: im-content($im-var-calculator); }
.#{$im-css-prefix}-windows2:before{ content: im-content($im-var-windows2); }
.#{$im-css-prefix}-browser:before{ content: im-content($im-var-browser); }
.#{$im-css-prefix}-portfolio:before{ content: im-content($im-var-portfolio); }
.#{$im-css-prefix}-comments:before{ content: im-content($im-var-comments); }
.#{$im-css-prefix}-screen3:before{ content: im-content($im-var-screen3); }
.#{$im-css-prefix}-iphone:before{ content: im-content($im-var-iphone); }
.#{$im-css-prefix}-ipad:before{ content: im-content($im-var-ipad); }
.#{$im-css-prefix}-googleplus5:before{ content: im-content($im-var-googleplus5); }
.#{$im-css-prefix}-pin:before{ content: im-content($im-var-pin); }
.#{$im-css-prefix}-pin-alt:before{ content: im-content($im-var-pin-alt); }
.#{$im-css-prefix}-cog5:before{ content: im-content($im-var-cog5); }
.#{$im-css-prefix}-graduation:before{ content: im-content($im-var-graduation); }
.#{$im-css-prefix}-air:before{ content: im-content($im-var-air); }
.#{$im-css-prefix}-droplets:before{ content: im-content($im-var-droplets); }
.#{$im-css-prefix}-statistics:before{ content: im-content($im-var-statistics); }
.#{$im-css-prefix}-pie5:before{ content: im-content($im-var-pie5); }
.#{$im-css-prefix}-cross:before{ content: im-content($im-var-cross); }
.#{$im-css-prefix}-minus2:before{ content: im-content($im-var-minus2); }
.#{$im-css-prefix}-plus2:before{ content: im-content($im-var-plus2); }
.#{$im-css-prefix}-info3:before{ content: im-content($im-var-info3); }
.#{$im-css-prefix}-info22:before{ content: im-content($im-var-info22); }
.#{$im-css-prefix}-question7:before{ content: im-content($im-var-question7); }
.#{$im-css-prefix}-help:before{ content: im-content($im-var-help); }
.#{$im-css-prefix}-warning2:before{ content: im-content($im-var-warning2); }
.#{$im-css-prefix}-add-to-list:before{ content: im-content($im-var-add-to-list); }
.#{$im-css-prefix}-arrow-left12:before{ content: im-content($im-var-arrow-left12); }
.#{$im-css-prefix}-arrow-down12:before{ content: im-content($im-var-arrow-down12); }
.#{$im-css-prefix}-arrow-up12:before{ content: im-content($im-var-arrow-up12); }
.#{$im-css-prefix}-arrow-right13:before{ content: im-content($im-var-arrow-right13); }
.#{$im-css-prefix}-arrow-left22:before{ content: im-content($im-var-arrow-left22); }
.#{$im-css-prefix}-arrow-down22:before{ content: im-content($im-var-arrow-down22); }
.#{$im-css-prefix}-arrow-up22:before{ content: im-content($im-var-arrow-up22); }
.#{$im-css-prefix}-arrow-right22:before{ content: im-content($im-var-arrow-right22); }
.#{$im-css-prefix}-arrow-left32:before{ content: im-content($im-var-arrow-left32); }
.#{$im-css-prefix}-arrow-down32:before{ content: im-content($im-var-arrow-down32); }
.#{$im-css-prefix}-arrow-up32:before{ content: im-content($im-var-arrow-up32); }
.#{$im-css-prefix}-arrow-right32:before{ content: im-content($im-var-arrow-right32); }
.#{$im-css-prefix}-switch2:before{ content: im-content($im-var-switch2); }
.#{$im-css-prefix}-checkmark5:before{ content: im-content($im-var-checkmark5); }
.#{$im-css-prefix}-ampersand:before{ content: im-content($im-var-ampersand); }
.#{$im-css-prefix}-alert:before{ content: im-content($im-var-alert); }
.#{$im-css-prefix}-alignment-align:before{ content: im-content($im-var-alignment-align); }
.#{$im-css-prefix}-alignment-aligned-to:before{ content: im-content($im-var-alignment-aligned-to); }
.#{$im-css-prefix}-alignment-unalign:before{ content: im-content($im-var-alignment-unalign); }
.#{$im-css-prefix}-arrow-down132:before{ content: im-content($im-var-arrow-down132); }
.#{$im-css-prefix}-arrow-up13:before{ content: im-content($im-var-arrow-up13); }
.#{$im-css-prefix}-arrow-left13:before{ content: im-content($im-var-arrow-left13); }
.#{$im-css-prefix}-arrow-right14:before{ content: im-content($im-var-arrow-right14); }
.#{$im-css-prefix}-arrow-small-down:before{ content: im-content($im-var-arrow-small-down); }
.#{$im-css-prefix}-arrow-small-left:before{ content: im-content($im-var-arrow-small-left); }
.#{$im-css-prefix}-arrow-small-right:before{ content: im-content($im-var-arrow-small-right); }
.#{$im-css-prefix}-arrow-small-up:before{ content: im-content($im-var-arrow-small-up); }
.#{$im-css-prefix}-check:before{ content: im-content($im-var-check); }
.#{$im-css-prefix}-chevron-down:before{ content: im-content($im-var-chevron-down); }
.#{$im-css-prefix}-chevron-left:before{ content: im-content($im-var-chevron-left); }
.#{$im-css-prefix}-chevron-right:before{ content: im-content($im-var-chevron-right); }
.#{$im-css-prefix}-chevron-up:before{ content: im-content($im-var-chevron-up); }
.#{$im-css-prefix}-clippy:before{ content: im-content($im-var-clippy); }
.#{$im-css-prefix}-comment:before{ content: im-content($im-var-comment); }
.#{$im-css-prefix}-comment-discussion:before{ content: im-content($im-var-comment-discussion); }
.#{$im-css-prefix}-dash:before{ content: im-content($im-var-dash); }
.#{$im-css-prefix}-diff:before{ content: im-content($im-var-diff); }
.#{$im-css-prefix}-diff-added:before{ content: im-content($im-var-diff-added); }
.#{$im-css-prefix}-diff-ignored:before{ content: im-content($im-var-diff-ignored); }
.#{$im-css-prefix}-diff-modified:before{ content: im-content($im-var-diff-modified); }
.#{$im-css-prefix}-diff-removed:before{ content: im-content($im-var-diff-removed); }
.#{$im-css-prefix}-diff-renamed:before{ content: im-content($im-var-diff-renamed); }
.#{$im-css-prefix}-file-media:before{ content: im-content($im-var-file-media); }
.#{$im-css-prefix}-fold:before{ content: im-content($im-var-fold); }
.#{$im-css-prefix}-gear:before{ content: im-content($im-var-gear); }
.#{$im-css-prefix}-git-branch:before{ content: im-content($im-var-git-branch); }
.#{$im-css-prefix}-git-commit:before{ content: im-content($im-var-git-commit); }
.#{$im-css-prefix}-git-compare:before{ content: im-content($im-var-git-compare); }
.#{$im-css-prefix}-git-merge:before{ content: im-content($im-var-git-merge); }
.#{$im-css-prefix}-git-pull-request:before{ content: im-content($im-var-git-pull-request); }
.#{$im-css-prefix}-graph:before{ content: im-content($im-var-graph); }
.#{$im-css-prefix}-law:before{ content: im-content($im-var-law); }
.#{$im-css-prefix}-list-ordered:before{ content: im-content($im-var-list-ordered); }
.#{$im-css-prefix}-list-unordered:before{ content: im-content($im-var-list-unordered); }
.#{$im-css-prefix}-mail5:before{ content: im-content($im-var-mail5); }
.#{$im-css-prefix}-mail-read:before{ content: im-content($im-var-mail-read); }
.#{$im-css-prefix}-mention:before{ content: im-content($im-var-mention); }
.#{$im-css-prefix}-mirror:before{ content: im-content($im-var-mirror); }
.#{$im-css-prefix}-move-down:before{ content: im-content($im-var-move-down); }
.#{$im-css-prefix}-move-left:before{ content: im-content($im-var-move-left); }
.#{$im-css-prefix}-move-right:before{ content: im-content($im-var-move-right); }
.#{$im-css-prefix}-move-up:before{ content: im-content($im-var-move-up); }
.#{$im-css-prefix}-person:before{ content: im-content($im-var-person); }
.#{$im-css-prefix}-plus22:before{ content: im-content($im-var-plus22); }
.#{$im-css-prefix}-primitive-dot:before{ content: im-content($im-var-primitive-dot); }
.#{$im-css-prefix}-primitive-square:before{ content: im-content($im-var-primitive-square); }
.#{$im-css-prefix}-repo-forked:before{ content: im-content($im-var-repo-forked); }
.#{$im-css-prefix}-screen-full:before{ content: im-content($im-var-screen-full); }
.#{$im-css-prefix}-screen-normal:before{ content: im-content($im-var-screen-normal); }
.#{$im-css-prefix}-sync:before{ content: im-content($im-var-sync); }
.#{$im-css-prefix}-three-bars:before{ content: im-content($im-var-three-bars); }
.#{$im-css-prefix}-unfold:before{ content: im-content($im-var-unfold); }
.#{$im-css-prefix}-versions:before{ content: im-content($im-var-versions); }
.#{$im-css-prefix}-x:before{ content: im-content($im-var-x); }
