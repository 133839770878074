/*
* File:             _search-form.scss
* Description:      This files holds styles for  Mail and Drive common Search Form
* Author:           PreVeil
*/

.search-box {
    margin-left: auto;
    min-width: 380px;
    position: relative;
    .search-form > form,
    > form {
        margin-left: 1rem;
        .input-group {
            background-color: $white !important;
            border-color: $mail-search-border-color;
            border-radius: 1.5rem;
            box-shadow: $mail-search-box-shadow;
            padding-right: 0.25rem;
        }
        .form-control {
            padding-bottom: 0.25rem;
        }
        .form-control,
        .btn {
            background-color: transparent !important;
            border-radius: 1.5rem;
        }
        .btn {
            color: $mail-search-icon-color;
            > i {
                margin: 0.25rem 0;
            }
            &:active,
            &:focus,
            &:hover {
                border-color: transparent;
                color: $gray-500;
            }
        }
        .form-control {
            padding-left: 1rem;
            &::placeholder {
                font-size: 1em;
            }
        }
    }
    .search-results {
        background-color: $white;
        border-radius: 8px;
        box-shadow: $box-shadow-lg;
        display: flex;
        flex-flow: column nowrap;
        margin: 1px 0 0;
        max-height: 450px;
        min-width: 100%;
        max-width: 90vw;
        overflow-y: auto;
        position: absolute;
        right: 0;
        z-index: 10;
    }
}

// Component specific
.drive-component {
    .header-flex {
        display: flex;
    }
}
// Active state of the search
.search-modal {
    .modal-dialog {
        margin-top: 75px;
        max-width: 100%;
        padding: 0 1.5rem;
    }
    .modal-content {
        background-color: transparent;
        .search-box {
            width: 800px;
        }
    }
    // Responsive mobile view <= 768px
    @include media-breakpoint-down(lg) {
        .modal-content {
            .search-box {
                width: 100%;
            }
        }
    }
    // Drive Search Dropdown window
    .search-results {
        display: flex;
        height: 80vh;
        max-height: 80vh;
        justify-content: space-between;
        .in-place {
            margin-top: 5vh;
        }
        .empty {
            @extend .alert;
            @extend .alert-primary;
            font-size: $font-size-md;
            margin: 2.5rem;
            padding: 2rem;
            text-align: center;
            i {
                color: $primary;
            }
        }
        .row {
            @extend .g-0;
            min-height: 65vh;
            overflow-y: hidden;
            > div {
                height: 100%;
                overflow-y: auto;
                ul {
                    @extend .list-unstyled;
                    margin: 0;
                }
            }
            .results {
                border-right: $light-border;
                .results-header {
                    font-weight: 700;
                    padding: 0.5rem 0.5rem 0.5rem 2.25rem;
                    [class^="ficon-"],
                    [class*=" ficon-"] {
                        font-size: 1rem;
                        margin-left: 0.5rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                ul {
                    padding: 0 0.5rem;
                    li {
                        border-bottom: $light-border;
                        display: flex;
                        padding: 0.25rem 0;
                        &:last-of-type {
                            border-bottom: none 0;
                        }
                        &:hover:not(.results-header) {
                            background-color: $table-hover-bg;
                            box-shadow: $box-shadow-sm;
                            cursor: pointer;
                        }
                        p {
                            margin: 0;
                        }
                        > div {
                            padding: 0.25rem 0.25rem 0;
                            width: 100%;
                            //  Information Div for the row
                            div {
                                span {
                                    display: inline-block;
                                    width: 50%;
                                }
                            }
                        }
                        > i {
                            top: -0.25rem;
                            &[class^="#{$pv-icon-css-prefix}-"] {
                                color: $pv-light-blue;
                                font-size: 2.5rem;
                            }
                        }
                        &.show-more {
                            display: block;
                            text-align: right;
                            width: 100%;
                        }
                    }
                }
            }
            // Filters sections
            .filters {
                section {
                    margin-bottom: 1rem;
                    min-height: 150px;
                    padding: 0.5rem;
                    header {
                        align-items: center;
                        border-bottom: $light-border;
                        display: flex;
                        padding: 0 0 0.5rem;
                        margin-bottom: 0.5rem;
                        h5 {
                            margin: 0;
                        }
                        .btn {
                            font-size: $font-size-xs;
                            margin-left: auto;
                        }
                    }
                }
                ul {
                    label {
                        cursor: pointer;
                        min-width: 100%;
                    }
                    .form-check {
                        cursor: pointer;
                        padding: 0.15rem 0.5rem 0.15rem 2rem;
                        &:hover {
                            background-color: $table-hover-bg;
                        }
                    }
                }
                .file-extensions {
                    padding: 0.75rem;
                    position: relative;
                    .input-group {
                        .input-group-text {
                            background-color: $white;
                            border: none 0;
                            font-weight: 700;
                            font-size: $font-size-lg;
                            line-height: 1.5rem;
                            padding: 0 0 0 0.5rem;
                        }
                        .form-control {
                            padding: 0.25rem;
                        }
                    }
                    .autocomplete-results {
                        background: $white;
                        border: $light-border;
                        border-radius: $border-radius;
                        padding: 0;
                        margin: 0;
                        max-height: 250px;
                        min-width: 90%;
                        overflow-y: auto;
                        position: absolute;
                        z-index: 10;
                        li {
                            cursor: pointer;
                            padding: 0.25rem 0.5rem;
                            &.active,
                            &:hover {
                                background-color: $hover-color-dark;
                            }
                        }
                    }
                    .badges {
                        margin: 0.5rem 0;
                        .badge {
                            border-left: 4px solid $pv-light-blue;
                            color: $pv-blue;
                            margin-bottom: 0.25rem;
                            margin-left: 0.25rem;
                            .btn-icon {
                                color: $pv-blue;
                                margin-left: 0.25rem;
                                [class^="ficon-"] {
                                    line-height: 1.25rem;
                                }
                                &:hover {
                                    background-color: $gray-200;
                                    // color: darken($pv-blue, 20%); // darken() deprecated
                                    color: color.adjust($pv-blue, $lightness: -20%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-footer {
            align-items: flex-end;
            border-top: $light-border;
            display: flex;
            margin-top: auto;
            min-height: 75px;
            padding: 1rem;
            > div {
                min-width: 80%;
            }
            .progress-box,
            .toast {
                margin-bottom: 0.5rem;
            }
            p {
                margin-bottom: 0;
            }
            .disclaimer {
                line-height: 1.5rem;
            }
            .toast {
                margin-top: 0;
                .toast-body {
                    font-size: $font-size-sm;
                    padding: 0.25rem 0.5rem;
                }
            }
            .btn {
                margin-left: auto;
            }
            .progress-box {
                b {
                    float: right;
                    margin-top: -0.5rem;
                }
                .progress {
                    max-width: 90%;
                    .progress-bar {
                        background-color: $pv-light-blue;
                    }
                }
            }
        }

        // Responsive mobile view <= 576px
        @include media-breakpoint-down(sm) {
            .row {
                overflow-y: visible;
                > div {
                    height: auto;
                }
                .filters {
                    padding: 0.75rem;
                }
            }
        }
    }
}
