/*
* File:             _layout.scss
* Description:      This files holds styles for global layout
* Author:           PreVeil
*/
// Set body background separate from bootstrap settings
body {
  background-color: $pv-lightgray;
}

// Main container wrapper
.wrapper {
  @extend .container-fluid;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100vh; // This has to be here for cover layouts
  padding: $header-height 0 0;
}

.content-header,
.page-header {
  margin-bottom: 0.5rem; // 1rem with border
  padding-bottom: 0.5rem; // 1rem with border
  > h1 {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}
.content-header {
  text-transform: capitalize;
}
.header-info {
  font-size: 1rem;
  float: right;
  line-height: 1em;
  position: relative;
  [class^="#{$pv-icon-css-prefix}-"] {
    color: $pv-blue;
    font-size: 2rem;
  }
}

//Card section on grey bg
.card-section {
  padding: 1.5rem 2rem 2rem;
}

// Description: Content inside content-wrapper to expand 100vh in total
.content-wrapper {
  display: block;
  height: 100%;
  overflow-y: auto;
  padding: 1rem 1.5rem 2rem 1rem;
  width: 100%;
  // Add this class for cover layout (Full height Scrolling limited to .cover-content
  &.cover {
    align-content: stretch;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    padding-bottom: 0.25rem;
  }
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}

// Main Headers for Public and system pages (No Left Navigation)
.main-header {
  @extend .text-muted;
  small {
    @extend .steps;
  }
}

.new-main-header {
  color: white;
  font-size: 40px;
  text-align: center;
  .with-logo {
    display: inline-table;
    span {
      display: table-row-group;
    }
    img {
      display: table-row-group;
      left: 3px;
      position: relative;
      &.express {
        left: 6px;
      }
    }
  
  }
}

//---------------------------------------------------------
// Layout for Cover Template displays
//---------------------------------------------------------
// Top section for header .page-header

// Bottom section for height limited (scrollable section)
// Cover content with Split Panels
.cover-content {
  height: 100%;
  overflow-y: hidden;
  > div {
    height: 100%;
  }
  .right-pane,
  .left-pane {
    height: 100%;
    overflow-y: auto;
  }
  &.list-view {
    // Responsive  <= 992px
    @include media-breakpoint-down(xl) {
      > .on {
        & > .left-pane {
          width: 0 !important;
        }
        & > .right-pane {
          width: 100% !important;
        }
        & > .divider {
          display: none;
        }
      }
    }
  }
  // Responsive mobile view <= 768px
  @include media-breakpoint-down(md) {
    > .on {
      & > .left-pane {
        width: 0 !important;
      }
      & > .right-pane {
        width: 100% !important;
      }
      & > .divider {
        display: none;
      }
    }
  }
}
