/*
 *   File:           _user-list.scss
 *   Description:    This files holds style for User List component
 *    Author:        PreVeil
 */

.user-block {
  border-left: 4px solid transparent;
  border-radius: 0;
  color: $pv-blue;
  cursor: default;
  display: flex;
  font-size: $font-size-sm;
  padding: 0 0 0 0.5rem;
  margin: 2px 10px 2px 0;
  .display-name {
    line-height: 1.5rem;
  }
  .btn-icon {
    color: $pv-blue;
    margin-left: 0.25rem;
    [class^="ficon-"] {
      line-height: 1.25rem;
    }
    &:hover {
      background-color: $gray-200;
      // color: darken($pv-blue, 20%); // darken() deprecated
      color: color.adjust($pv-blue, $lightness: -20%);
    }
  }
  &.readonly {
    padding-right: 0.75rem;
  }
  &.claimed {
    border-left-color: $pv-orange;
  }
  &.group {
    // border-left-color: darken($pv-orange, 20%); // darken() deprecated
    border-left-color: color.adjust($pv-orange, $lightness: -20%);
  }
  &.unclaimed,
  &.pending {
    // border-left-color: darken(lightgray, 20%);  // darken() deprecated
    border-left-color: color.adjust(lightgray, $lightness: -20%);
  }
  &.external {
    border-left-color: $pv-yellow;
  }
  &.error {
    border-left-color: transparent;
    .display-name {
      // text-decoration: lighten($pv-icon, 10%) line-through; - deprecated
      text-decoration: color.adjust($pv-icon, $lightness: 10%) line-through;
      font-style: italic;
    }
  }
}

// Dropdown autocomplete form
.user-autocomplete {
  flex: 1;
  position: relative;
  min-width: 150px;
  .is-invalid {
    .btn i {
      color: $pv-red;
    }
    .form-control {
      color: $pv-red;
      font-style: italic;
      text-decoration: $pv-red line-through;
    }
  }
  .form-control {
    padding: 0.375rem 0.25rem;
  }
  .autocomplete-results {
    background-color: $white;
    border: solid 1px $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;
    display: flex;
    right: 0;
    max-height: 350px;
    min-width: 350px;
    max-width: 50vw;
    margin: 0;
    position: absolute;
    flex-flow: column nowrap;
    overflow-y: auto;
    width: 100%;
    z-index: 10;
    ul {
      @extend .list-unstyled;
      margin: 0;
      li {
        cursor: pointer;
        font-size: $font-size-sm;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        &.active,
        &:hover {
          background-color: $hover-color-dark;
        }
      }
    }
  }
}

.user-notification,
.compose-row.notifications {
  align-items: center;
  border-bottom: solid 1px $border-color;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.75rem;
  position: relative;
  padding: 0;
  p {
    font-size: $font-size-xs;
    font-weight: 700;
    margin: 0;
    padding: 0.4rem 0.75rem;
    text-align: center;
    width: 100%;
  }
  .external {
    background: $pv-yellow-light;
  }
  .unclaimed,
  .pending {
    background: $active-bg;
  }
}
