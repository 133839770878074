/*
* File:             _pv-cards.scss
* Description:      This files holds styles for preVeil specific cards
* Author:           PreVeil
*/

.pv-card {
  border-radius: $border-radius-xl;
  .card-img-top {
    background-color: $text-inverse;
    height: 200px;
  }
  .card-body {
    .card-img-top {
      background-color: $text-inverse;
      border-bottom: none;
      height: 200px;
    }
  }
  // Responsive header - desktop view < 767px
  // Restrict width only in larger devices
  @include media-breakpoint-up(md) {
    max-width: 615px;
  }
}

.mail-client {
  .card {
    border: none;
    max-width: 350px;
    padding: 1rem 1.25rem;
    .card-header {
      font-size: 1.5rem;
      padding-left: 0;
    }
    .card-img-top {
      background-color: transparent;
      border: none;
      height: 190px;
      outline: none;
      overflow: hidden;
      padding: 0;
      width: 100%;
      &.gmail-image {
        object-fit: contain;
        padding-right: 65px;
      }
      &.mua-image {
        object-fit: cover;
        left: 0;
      }
    }
    .card-body {
      border: none;
      margin: 2rem 0;
      padding: 0;
    }
    .card-text {
      max-width: 300px;
    }
  }
  .gmail {
    border-right: 1px solid $gray-200;
  }
  @include media-breakpoint-down(lg) {
    display: block;
    .gmail {
      border-right: none;
      border-bottom: 1px solid $gray-200;
    }
  }
}

.admin-card {
  &.card {
    border: none;
    box-shadow: none;
    max-width: 1086px;
    .card-body {
      border: none;
      padding-top: 0;
      text-align: center;
      .card-title {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }
      a {
        color: $pv-blue-bright;
      }
    }
  }
}

// list-groups
.public-card {
  margin-bottom: 1.25rem;
  position: relative;
  .list-group-item {
    border: 1px solid $gray-300;
    &.image {
      .option-counter {
        color: $pv-orange;
        font-size: 9rem;
        font-weight: 100;
        line-height: 1.2em;
        text-align: center;
      }
      background-color: $text-inverse;
      padding: 0.5rem 2rem;
      width: 15rem; //  not responsive
    }
    &.content {
      padding: 1.5rem;
      width: 25rem; //  not responsive
      .title {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
      .text {
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: block;
    .list-group-item {
      &.image,
      &.content {
        display: block;
        width: 100%;
      }
      &.content {
        border-left: 1px solid $gray-300;
        border-top: none;
      }
    }
  }
}

.description-card {
  position: relative;
  .image {
    color: $gray-300;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
    padding: 0;
    width: 3rem;
  }
  .content {
    padding: 0;
    .title {
      font-size: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .list-group-item {
      border: none;
      &.image {
        color: $gray-300;
        font-size: 1.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 0;
        width: 3rem;
      }
      &.content {
        padding: 0;
        .title {
          font-size: 1.25rem;
          margin-bottom: 1.25rem;
        }
        .text {
          padding: 0.3rem 0 0 1.5rem;
          li {
            font-size: 1rem;
            line-height: 1.3rem;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      display: block;
      .list-group-item {
        &.image,
        &.content {
          display: block;
          width: 100%;
        }
        &.content {
          .text {
            padding: 0;
          }
        }
      }
    }
  }
}

.list-messages {
  color: $text-muted;
  height: 90% !important;
  text-align: center;
  position: relative;
  p {
    font-size: $font-size-xl;
    margin-bottom: 0;
  }
  div > i {
    font-size: 100px;
  }
  small {
    color: $gray-800;
    text-align: center;
  }
}

.message-box {
  border: none;
  color: $text-muted;
  font-size: $font-size-xl;
  padding-top: 8rem;
  text-align: center;
  i {
    font-size: 7rem;
  }
}
