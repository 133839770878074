/*
 *   File:           loading.module.scss
 *   Description:    This files holds style for loading indicator component with or without message
 *   Author:         PreVeil, LLC
 */

.loading {
  left: 50%;
  margin-left: -($loading-logo-width * 0.5);
  position: fixed;
  text-align: center;
  top: 30%;
  z-index: 10;
  &.in-place {
    margin: 2rem;
    position: static;
  }
  .logo {
    background: url("/assets/images/logos/preveil-logo.svg") no-repeat center center;
    &.white {
      background: url("/assets/images/logos/preveil-logo-white.svg") no-repeat center center;
    }
    display: block;
    height: $loading-logo-height;
    margin: 0 auto 16px;
    width: $loading-logo-width;
  }
  // Class for subcomponent loading with message
  &.loading-msg:not(.in-place) {
    position: absolute;
    top: 50%;
    transform: translate(0, -100%);
    .logo {
      display: none;
    }
  }
  &.loading-msg {
    .logo {
      display: none;
    }
  }
  p {
    margin: 0;
    padding: 10px 0;
  }

  // Responsive left nav - mobile view <= 768px
  @include media-breakpoint-down(md) {
    margin-left: -$loading-logo-width * 0.5;
    /* &.loading-msg {
      transform: translate(0, -100%);
    } commented this out because it seems to not work with mobile view */
  }
}

.loading-progress {
  background-color: rgb(186, 195, 204);
  border-radius: 3px;
  height: 6px;
  margin: 0 auto;
  overflow: hidden;
  width: $loading-progress-width;
  > div {
    background-color: rgb(232, 236, 237);
    height: 7px;
    margin-top: 0;
    margin-left: -$loading-progress-width;
    animation-name: bounce_loadingProgressG;
    -o-animation-name: bounce_loadingProgressG;
    -ms-animation-name: bounce_loadingProgressG;
    -webkit-animation-name: bounce_loadingProgressG;
    -moz-animation-name: bounce_loadingProgressG;
    animation-duration: 1.235s;
    -o-animation-duration: 1.235s;
    -ms-animation-duration: 1.235s;
    -webkit-animation-duration: 1.235s;
    -moz-animation-duration: 1.235s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    width: $loading-progress-width;
  }
}

@keyframes bounce_loadingProgressG {
  0% {
    margin-left: -$loading-progress-width;
  }

  100% {
    margin-left: $loading-progress-width;
  }
}

@-o-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -$loading-progress-width;
  }

  100% {
    margin-left: $loading-progress-width;
  }
}

@-ms-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -$loading-progress-width;
  }

  100% {
    margin-left: $loading-progress-width;
  }
}

@-webkit-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -$loading-progress-width;
  }

  100% {
    margin-left: $loading-progress-width;
  }
}

@-moz-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -$loading-progress-width;
  }

  100% {
    margin-left: $loading-progress-width;
  }
}

// Small icon spinner
.spinner {
  animation: spinner 2s linear infinite;
  border: 2px solid $text-muted;
  border-top: 2px solid $pv-orange;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
  height: 1rem;
  width: 1rem;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
