/*
*   File:           _fonts.scss
*   Description:    This files holds global fonts
*   Author:         PreVeil, LLC
*/

@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Libre Franklin";
  src: url("#{$fonts-path}/Libre_Franklin/LibreFranklin-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Titillium Web";
  src: url("#{$fonts-path}/Titillium_Web/TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Avenir Next */
@font-face {
  font-family: "Avenir Next";
  src: url("#{$fonts-path}/Avenir_Next/AvenirNextLTPro-Medium.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: url("#{$fonts-path}/Avenir_Next/AvenirNextLTPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: url("#{$fonts-path}/Avenir_Next/AvenirNextLTPro-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
