/*
*   File:           _admin.scss
*   Description:    This files holds specific styles for admin - these are older styles that we are trying to move out of after clean up
*   Author:         PreVeil, LLC
*/

.admin-wrapper.cover,
div[class*="admin-wrapper"].content-wrapper {
  padding-bottom: 1rem;

  .cover-content {
    background-color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;
    position: relative;

    .row-container {
      overflow-y: auto;
      overflow-x: hidden;
    }

    & > header {
      align-items: center;
      background-color: $white;
      border-bottom: $light-border;
      display: flex;
      padding: 0.5rem 0 0.5rem 0.75rem;
      position: sticky;
      width: inherit;
      z-index: 1;

      h5 {
        font-weight: 500;
        margin-bottom: 0.2rem;
        margin-left: 0.5rem;
        padding-bottom: 0;
      }
      small {
        margin-bottom: 0.1rem;
      }

      .users-columns-header {
        width: 100%;
        & > div:first-child {
          padding-right: 0;
        }
        & > div:nth-child(2) {
          padding-left: 0;
        }
        & > div:nth-child(3) {
          padding-left: 0.6rem;
        }

        div[class*="col-sm-1"],
        div[class*="col-sm-2"],
        div[class*="col-sm-3"] {
          align-items: center;
          display: flex;
          & > h5 {
            margin-left: 0;
          }
        }
      }
    }
  }

  .admin-panel-center {
    align-items: center;
    display: flex;
    justify-content: center;
    & > .admin-card {
      height: auto;
    }
    .create-list-text {
      margin-bottom: 0.5rem;
    }
    & > .admin-card > .card-body > .card-title {
      margin-bottom: 0.3rem;
    }
  }

  .export-users-header {
    align-items: center;
    background-color: $white;
    border-bottom: $light-border;
    border-right: $light-border;
    border-left: $light-border;
    display: flex;
    height: 40px;
    padding: 0.7rem 0 0.7rem 0;
    position: initial;
    width: inherit;
    h5 {
      font-weight: 500;
      margin-bottom: 0.2rem;
      padding: 0 0.5rem 0 0;
    }
    .data-export-header-label {
      display: flex;
      width: 100%;
      padding-left: 0;
    }
  }

  .gateway-top-nav-divider {
    margin: 0.5rem 0;
  }

  .toolbar {
    align-items: center;
    background-color: $active-bg;
    border-radius: $border-radius $border-radius 0 0;
    min-height: 40px;

    & > div:nth-child(4) {
      margin-left: auto;
    }

    .action-toolbar-buttons:disabled {
      cursor: not-allowed;
      pointer-events: all;
    }

    .total-addresses {
      margin: 0.25rem 0.25rem 0.25rem 0;
      padding-left: 0;
      padding-right: 0.25rem;
      white-space: nowrap;
      & > b {
        margin-right: 0.3rem;
      }
      &.non-edit {
        margin: 0;
      }
    }
  }
  .badge {
    font-size: $font-size-xs;
    font-style: italic;
    padding: 0.25rem 0.35rem;
  }
}

// Styling for reusable AddressListRow Component
// Used to display a single address/domain
.address-row {
  border: 0 none;
  border-bottom: $light-border;
  border-radius: 0;
  margin: 0;
  max-height: 100%;
  min-height: 40px;
  max-width: 100%;

  .title {
    color: $gray-800;
  }

  .title.invalid {
    color: $pv-red;
  }

  .ficon-globe,
  .ficon-user,
  .ficon-x-circle {
    color: $text-muted;
  }

  .address-name {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .address-subtitle {
    overflow: hidden;
    text-align: start;
    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// Root class for a single user in Admin Users
.admin-item-list {
  @extend .address-row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;

  &:hover {
    box-shadow: $box-shadow-sm;
    cursor: pointer;
  }
  &:active {
    // background-color: lighten($active-color-light, 15%); - deprecated
    background-color: color.adjust($active-color-light, $lightness: 15%);
    p {
      color: $gray-800;
    }
  }

  // User full name column
  & > div:nth-child(2) {
    align-items: center;
    display: flex;
    padding-left: 0.3rem;
    .account-type-icon {
      font-size: 1.2rem;
      color: $pv-blue-bright;
      &.inactive {
        color: $text-muted-light;
      }
    }
    & > p {
      overflow: hidden;
      padding-left: 0.4rem;
      text-overflow: ellipsis;
    }
  }

  // Email and Department columns
  & > div:nth-child(3),
  & > div:nth-child(4) {
    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  // checkbox column
  div[class*="col-sm-auto"] {
    padding-right: 0;
  }

  div[class*="col-sm-1"],
  div[class*="col-sm-2"],
  div[class*="col-sm-3"] {
    & > p {
      margin: 0;
    }
  }
}

// Container for CSV Upload invalid/duplicate addresses and domains.
.invalid-card {
  &.show-divider {
    border-top: 1px solid $gray-200;
    padding-top: 1.5rem;
  }

  & > header {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding-left: 0.1rem;
    width: 100%;
    h3 {
      color: $gray-700;
    }
    .emphasis {
      color: $pv-red;
    }
  }
}

// CSV Upload Panel with all the addresses
.csv-upload-card {
  h3 {
    color: $active-color;
  }
  & > div {
    background: $white;
    border: 1px solid $gray-400;
    border-radius: 0.375rem 0.375rem 0 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    width: 100%;

    & > header {
      background: $active-bg;
      min-height: 40px;
      padding-left: 0.75rem;
    }
  }
}

// Text on top CSV Upload tab, displaying the csv download link
.download-csv-legend {
  & > a {
    margin-left: 0.5rem;
    .ficon-download {
      font-size: 1rem;
    }
  }
}

.modal-body {
  .user-autocomplete .form-control {
    padding: 0.375rem 0.75rem;
  }
}

.admin-table {
  border: 1px solid $gray-300;
  border-radius: $border-radius-lg;
  .table-header {
    background: $active-bg;
    border: none;
    border-bottom: 1px solid $gray-300;
    border-radius: $border-radius $border-radius 0 0;
    font-weight: 500;
    margin: 0;
    padding: 0.5rem;
    p {
      padding: 0;
    }
  }
  label {
    display: none;
  }
  .row {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    margin: 0;
    padding: 0.5rem;
    &:last-child {
      border: none;
    }
  }
  .approver-row {
    align-items: center;
    display: flex;
  }
  @include media-breakpoint-down(sm) {
    .table-header {
      display: none;
    }
    label {
      display: inline-flex;
    }
  }
}

.log-actor {
  cursor: pointer;
  text-decoration: underline;
}

.non-member-notif {
  background: $active-bg;
  font-size: $font-size-xs;
  font-weight: 700;
  justify-content: center;
  padding: 0.4rem 0.75rem;
  p {
    margin: 0;
  }
}

.admin-list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .row {
    align-items: center;
    margin: 0;
    padding: 0.4rem 0 0.4rem 0;
    .col-1 {
      max-width: 40px;
    }
    &.header {
      border-bottom: 1px solid $gray-400;
      font-weight: 600;
      .col-4,
      .col-3 {
        cursor: pointer;
      }
    }
    &.body {
      border-bottom: 1px solid $gray-200;
      max-height: 100%;
      min-height: 50px;
      &:hover {
        box-shadow: $box-shadow-sm;
        cursor: pointer;
      }
      &:active {
        // background-color: lighten($active-color-light, 15%); - deprecated
        background-color: color.adjust($active-color-light, $lightness: 15%);
        p {
          color: $gray-800;
        }
      }
      .col-1 {
        max-width: 40px;
      }
    }
  }
}

/********************** 
    ADMIN SETTINGS
**********************/
.admin-settings-content.cover {
  overflow-y: auto;
  & > .card {
    // overriding min-height: 100px
    // content overflowing container at smaller screen sizes.
    min-height: auto;
  }
}

/********************** 
    ADMIN GATEWAY
**********************/
.gateway-steps {
  color: $active-color;

  & > ol {
    font-size: $font-size-sm;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  & > p {
    color: $active-color;
  }
}

div[class*="admin-"].content-wrapper {
  & > .card-section > .list-group {
    max-height: 500px;
    min-height: 400px;
    & > .admin-panel-center {
      min-height: inherit;
    }
  }
}

.gateway-card {
  border: 1px solid $gray-200;
  margin-bottom: 1rem;
  min-height: auto;

  & > .card-body {
    & > .row {
      p {
        margin: 0;
      }
    }
    .gateway-status {
      align-items: center;
      display: flex;
      max-width: 300px;
      .btn {
        margin-left: auto;
      }
    }
    .top-panel-area {
      align-items: center;
      display: flex;
      margin-top: 0.25rem;
    }
  }
}

// Individual Form
.gateway-aliases-form {
  & > footer {
    margin-top: 1rem;
  }
  @media screen and (max-width: 991px) {
    & > footer {
      margin-top: 1.5rem;
    }
  }
}

.gateway-extra-info-label {
  font-size: $font-size-xs;
}

.gateway-userlist {
  display: flex;
  min-height: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  width: 12.5rem;

  & > p {
    margin-right: 0.5rem;
  }
  .user-autocomplete .form-control {
    padding: 0;
  }
  .input-group {
    border: 0 none;
    & > input::placeholder {
      font-size: 0.8rem;
    }
  }

  &.hide > .user-autocomplete {
    display: none;
  }

  &.show {
    border-bottom: $light-border;
    > .user-autocomplete {
      display: block;
      .autocomplete-results {
        left: 0; // NOTE: autocomplete-results list was opening right justify aligned so it was getting underneath the nav-sidebar component and difficult to see.
      }
    }
  }
  & > .loading {
    left: 0;
    position: absolute;
    top: 40%;
    &.in-place {
      margin: 0;
    }
  }
  & > .claimed {
    align-items: center;
    display: flex;
    margin-right: auto;
    width: 100%;
    & > button {
      margin-left: auto;
    }
  }
}

.add-address-button {
  align-self: center;
  margin-top: 0.4rem;
  padding: 0;
  @media (max-width: 506px) {
    margin-top: 2rem !important;
  }
}

.switch-legend {
  align-items: center;
  display: flex;
}

/********************** 
    ADMIN GROUP
**********************/

.groups-header {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  width: 100%;
}

.groups-card,
.recovery-groups-card {
  background: $white;
  border: 1px solid $gray-400;
  border-radius: 0.375rem 0.375rem 0 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem; // TODO: Fix this: This is a reusable/generic style now, so it shouldn't have margin that could affect/push nearby/sibling components.
  width: 100%;

  & > header {
    align-items: center;
    background: $active-bg;
    display: flex;
    min-height: 40px;
    padding: 0.5rem 0 0.5rem 0.8rem;
    width: 100%;

    & > .header-card-row {
      width: 100%;
    }
  }

  &.request-complete-users {
    max-height: 440px;
    & > div {
      overflow: auto;
    }
  }

  & > .row,
  & > div > .row {
    border: 0 none;
    border-bottom: $light-border;
    border-radius: 0;
    margin: 0;
    max-height: 100%;
    min-height: 50px;
    padding: 0.62rem 0.2rem 0.62rem 0;
    width: 100%;
    & > div:first-of-type {
      padding-left: 0.8rem;
      & > p {
        margin: 0;
      }
      & > p:last-of-type {
        color: $gray-600;
        font-size: $font-size-sm;
        line-height: 0.88rem;
      }
    }

    .start-btn,
    .approver-check {
      margin-left: auto;
      margin-right: 0.75rem;
    }

    .approver-check {
      font-size: 1.5rem;
      text-align: center;
      width: 50px;
      & > i {
        background: $pv-green;
        border-radius: 50%;
        color: $white;
        height: 2rem;
        line-height: 1.3;
        width: 2rem;
      }
    }
  }
}

.sidenav-group-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.multiselected-group-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  & > i {
    color: $text-muted;
    font-size: 7rem;
  }
}

.group-alias {
  & > span {
    margin-right: 0.3rem;
  }
  & > p {
    display: inline-block;
    font-weight: bold;
  }
}

// User Row form for dept
.user-row-field {
  margin: 0;
  padding: 0;
  & > p {
    margin: 0;
    padding: 0 0.5rem 0 0;
  }
}

.recovery-groups-card {
  max-height: 220px;
  & > div {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.user-row-checkbox {
  width: 35px !important;
}

/********************** 
    DATA EXPORT
**********************/

.users-export-card {
  max-height: 40vh;
  & > div {
    overflow-y: auto;
  }
}

.data-export-container {
  background-color: #fff;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 1.25rem;
  position: relative;
  .data-export-main {
    border-radius: $border-radius-sm;
    display: flex;
    height: 100%;
    .export-left-section {
      max-height: 95%;
      overflow-y: auto;
      padding-right: 1.25rem;
      padding-top: 1rem;
      width: 65%;
      .data-export-card {
        max-width: 100% !important; // overriding width of pv-card.
      }
      .approvers-card {
        max-height: 60vh;
        & > div {
          overflow-y: auto;
        }
      }
      &:after {
        background: $gray-300;
        content: "";
        height: 100%;
        left: 65%;
        position: absolute;
        top: 0%;
        width: 1px;
      }
      .required-approvals-header {
        & > h4 {
          color: $gray-700;
        }
      }
      .export-receipt-header {
        align-items: center;
        border-bottom: 1px solid $gray-300;
        display: flex;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        h5,
        p {
          margin: 0;
        }
        p {
          color: $gray-600;
          font-size: 0.88rem;
        }
        & > i {
          font-size: 2.5rem;
        }
      }
    }
    .export-right-section {
      height: 100%;
      padding-top: 1rem;
      padding-left: 1.25rem;
      position: relative;
      width: 35%;
      & > h5 {
        margin-bottom: 0;
      }
      & > hr {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
      .group-details {
        .group-content {
          display: flex;
          & > span {
            color: $pv-blue;
            flex-basis: 60%;
          }
          & > p {
            margin-right: auto;
          }
        }
      }
      .export-receipt-header {
        align-items: center;
        border-bottom: 1px solid $gray-300;
        display: flex;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        h5,
        p {
          margin: 0;
        }
        p {
          color: $gray-600;
          font-size: 0.88rem;
        }
        & > i {
          font-size: 2.5rem;
        }
      }
    }
  }
  .data-export-no-group {
    height: auto;
    border-top: 1px solid $gray-300;
    padding-top: 1rem;
  }
}

.export-select-content {
  // Content for the Get Started section.
  & > header {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: flex-start; // TODO: remove this
    padding-left: 0.1rem;
    width: 100%;
    & > h4 {
      color: $pv-blue;
      margin-bottom: 0;
    }
  }
}

.export-users-container {
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  max-height: 200px;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.data-export-content-table {
  border: 1px solid $gray-300;
  .data-export-content-row {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    display: flex;
    height: 50px;
    padding: 0.7rem 0 0.7rem 0.75rem;
    & > label {
      padding-left: 0.5rem;
    }
  }
}

.export-multistep-progress {
  align-items: center;
  background: $white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-sm;
  display: flex;
  height: 58px;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0 5%;
  width: 100%;
  .export-single-step {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 30%;
    & > p {
      margin: 0;
    }

    & > .icon-checkmark {
      align-items: center;
      background: $pv-green;
      border-radius: 50%;
      color: $white;
      display: flex;
      font-size: 0.875rem;
      height: 1.25rem;
      margin: 0 0.5rem;
      justify-content: center;
      width: 1.25rem;
    }

    .step-complete-color {
      color: $pv-green;
    }

    &:nth-child(1) {
      justify-content: end;
      margin-right: 0.5rem;
    }

    &:nth-child(2) > span {
      margin-left: 0.5rem;
    }

    &:nth-child(2) > p {
      margin-right: 0.5rem;
    }

    &:nth-child(3) {
      justify-content: flex-start;
      margin-left: 0.5rem;
    }

    .request-step-number {
      align-items: center;
      background: $gray-400;
      border-radius: 50%;
      color: $white;
      display: flex;
      font-size: 0.81rem;
      height: 1.25rem;
      justify-content: center;
      margin-right: 0.5rem;
      width: 1.25rem;
    }

    & > hr {
      border: 2px solid $gray-400;
      flex-grow: 2;
    }

    & > hr.complete {
      border-color: $pv-green;
    }

    @include media-breakpoint-down(xl) {
      width: 35%;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 0;
  }
}

.data-export-wait-connection {
  .wait-connection-header {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    display: flex;
    height: 45px;
    justify-content: flex-start;
    padding-bottom: 0.75rem;
    & > h4 {
      margin-bottom: 0;
    }
  }
  .wait-connection-instructions {
    margin-top: 0.75rem;
    & > ol {
      padding-left: 1.3rem;
    }
  }
}

.data-export-details-container {
  height: 100%;
  & > header {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: flex-start;

    .header-details-label {
      margin-left: 0.5rem;
    }

    .header-details-label > p,
    h4 {
      margin: 0;
    }

    .header-details-label > p {
      color: $gray-600;
      font-size: 0.88rem;
    }

    & > i {
      color: $gray-700;
      font-size: 2.5rem;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .export-details-body {
    border-top: 1px solid $gray-300;
    height: 100%;
    width: 100%;
    .single-detail {
      margin-top: 1.5rem;
      & > hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      & > h5 {
        font-size: 1.1rem;
        margin-bottom: 0;
      }
      & > p {
        font-size: 0.88rem;
        color: $gray-700;
      }
    }
    .data-users-request-card {
      max-height: 220px;
      .data-request-users-container {
        overflow: auto;
      }
    }
    .request-complete-users {
      max-height: 440px;
      & > div {
        overflow: auto;
      }
    }
  }
}

/**ADMIN DATA EXPORT ENDS HERE ****/

/********************** 
    CLOUD ONLY
**********************/
.cloud-lock-off {
  max-width: 600px;
  & > i {
    display: block;
  }
}

// REUSABLE COMPONENTS

.readonly-details {
  border: 1px solid $gray-300;
  margin-bottom: 2.5rem;
  & > p {
    border-bottom: 1px solid $gray-300;
    display: flex;
    margin: 0;
    padding: 0.625rem;
    text-transform: capitalize;
    & > span {
      font-weight: bold;
      flex-basis: 23%;
      margin-right: 0.3rem;
    }
  }

  & > p:last-of-type {
    border-bottom: 0;
  }
  &.small {
    & > p {
      font-size: 0.9rem;
    }
  }
}

.settings-export-members-container {
  max-height: 336px;
  .export-request-members-container {
    overflow-y: auto;
    .user-id-format {
      min-height: 40px;
      padding: 0;
    }
  }
}

.cloud-lock-legend {
  & > h4 {
    font-family: $theme-headline-font;
  }
  & > p {
    font-size: 0.88rem;
  }
}

// Switch/Toggle Component
// TODO: move it to its corresponding file (reusable) if switch is going to be use elsewhere.
.switch-preveil-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .switch-text-legend {
    margin-right: 0.5rem;
    width: initial;
  }
}

.admin-switch {
  &.admin-alert {
    background: $active-bg;
    border: 1px solid $gray-400;
    max-width: 600px;
  }
  & > .switch-preveil-container {
    padding-right: 8px;
  }
}

// This is for the fixed non-scrollable top row in the list of addresses
// content-dynamic-width class controls the width of the fixed position element (cover-content-header)
// fixed position elements are remove from the normal document flow, so they take the width of the viewport
// we need to calculate the whole width of the page minus the width of:
// - SplitPane ( Left pane width depending on/off state)
// - divider
// - padding surrounding cover-content.
// So the fixed element (cover-content-header) keeps the same width of its parent (content-dynamic-width).
.split-view.on > .right-pane > .content-wrapper > .content-dynamic {
  // desktop width with left pane at full width.
  width: calc(100vw - 18.5rem);
}
.split-view.off > .right-pane > .content-wrapper > .content-dynamic {
  // desktop width with left pane at responsive mode (off).
  width: calc(100vw - 6.8rem);
  // breakpoint <= 767px
  @include media-breakpoint-down(767px) {
    // response/mobile view, left pane is hidden.
    width: calc(100vw - 2rem);
  }
  @include media-breakpoint-up(md) {
    width: calc(100vw - 6.8rem);
  }
}
