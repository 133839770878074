/*
* File:             _utilities.scss
* Description:      This files holds styles for utilities.
* Author:           PreVeil
*/

.thumb {
  min-width: 150px;
}

.max-400 {
  max-width: 400px;
}

.max-500 {
  max-width: 500px;
}
.max-600 {
  max-width: 600px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.admin-alert {
  font-size: $font-size-sm;
}

.float-right {
  right: 0;
}

.height-300 {
  min-height: 300px;
}

.cursor-default {
  cursor: default !important;
}

.cursor-block {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

// Build modes Utilities
.web_build {
  .hide-web {
    display: none;
  }
}

.app_build {
  .hide-app {
    display: none;
  }
}

// Hide on under md - 768px and under
.hide-mobile {
  @extend .d-none;
  @extend .d-md-block;
}

// Hide on up of md breakpoint - 768px and up
.hide-desktop {
  @extend .d-md-none;
}

.opacity-0 {
  opacity: 0;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-sm {
  box-shadow: $box-shadow-sm;
}
.box-shadow-lg {
  box-shadow: $box-shadow-lg;
}
.box-shadow-inset {
  box-shadow: $box-shadow-inset;
}

// Remove hover effect from rows
.row-nohover {
  background-color: $white;
  box-shadow: none;
  cursor: default;
}

// Inside a position: relative container (div, etc) will center content vertically;
.center-vertical {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
}

// Used in side headers
.steps {
  margin-left: 0.75rem;
  font-size: 1rem;
  @include media-breakpoint-down(sm) {
    display: block;
    margin-left: 0;
    width: 100%;
  }
}

// Media object layout with images / icon on the left
.media-object {
  padding-left: 30px;
  position: relative;
  .icon,
  [class^="#{$pv-icon-css-prefix}-"],
  [class*=" #{$pv-icon-css-prefix}-"] {
    left: 0;
    position: absolute;
    top: 0;
  }
}

// Dot
.badge-mark {
  border-radius: 50rem;
  border: $pv-blue 2px solid;
  display: inline-block;
  height: 0.5rem;
  padding: 0;
  width: 0.5rem;
}

// Dev Tools
.dev-tools {
  bottom: 5px;
  right: 5px;
  position: fixed;
  z-index: 1040;
}

.highlight {
  background-color: $highlighted-color;
}

@include media-breakpoint-down(sm) {
  .hide-mobile-sm {
    display: none !important;
  }
}

.inline-iframe {
  min-height: 50px;
  overflow: visible !important;
}

.with_cap {
  text-transform: capitalize;
}

.pv-dropdown {
  &.hide-arrow {
    .dropdown-toggle::after {
      display: none;
    }
  }
  .dropdown-toggle::after {
    margin-left: 0.25rem;
    vertical-align: 0.15em;
  }
  &.btn-icon {
    &:hover,
    &:focus,
    &:active,
    &.show {
      background-color: $hover-color-dark; // #e8f1f6
      border-color: $hover-color-dark; // $active-color-light;
    }
  }
  .dropdown-menu {
    @include small-dropdown-menu();
    margin-top: 0.25rem;
  }
}

.fw-600 {
  font-weight: 600;
}

.error-red {
  color: $pv-red;
}

.strikethrough-red {
  color: $pv-red;
  text-decoration: line-through;
}

.warning-yellow {
  color: $pv-yellow;
}

.success-green {
  color: $pv-green;
}

.pv-orange {
  color: $pv-orange;
}

.light-blue-text {
  color: $pv-blue-bright-light;
}

// Use for extending sections with border bottom and correct spacing
.bordered {
  border-bottom: $light-border;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.overflow-text {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.margin-left-200 {
  margin-left: 12.5rem;
}
