/*
* File:             _tinymce.scss
* Description:      This files holds styles for tinymce - email signature and mail
* Author:           PreVeil
*/

.tiny {
  .tox-tinymce {
    border: $light-border;
    border-radius: 0;
    .tox-editor-container {
      .tox-editor-header {
        border-bottom: $light-border;
        box-shadow: none;
        padding: 0;
        .tox-toolbar__primary {
          .tox-toolbar__group {
            border-right: 1px solid $gray-300;
            padding: 0 4px 0 5px;
            &:last-child {
              border: none;
            }
            .tox-tbtn {
              font-size: $font-size-sm;
              opacity: 0.6;
              margin: 0;
              &:hover {
                background-color: transparent;
                border: 1px solid $pv-blue;
              }
              .feather {
                fill: none;
              }
            }
            .tox-tbtn--enabled {
              background: $active-bg;
              border: 1px solid $pv-blue;
            }
            .tox-tbtn--select {
              width: 4rem;
              &.tox-tbtn--bespoke {
                background: none;
              }
            }
          }
        }
      }
    }
  }

  // Vertical scrollbar styles (mostly mobile devices)
  .tox-editor-header {
    ::-webkit-scrollbar-track {
      background-color: white;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar {
      background-color: white;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.email-signature-footer {
  border: $light-border;
  border-top: none;
  height: 3rem;
  padding: 5px 0;
  width: 100%;
  .btn,
  .btn:hover {
    font-size: $font-size-sm;
  }
  .send-button {
    display: inline-block;
    margin-left: 10px;
    margin-right: 6px;
  }
}

// Modals for editor
.tox .tox-dialog__footer {
  .tox-button:not(.tox-button--secondary) {
    background-color: $pv-orange;
    border-color: $pv-orange;
  }
}
