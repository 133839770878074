/*
*   File:           _drive.scss
*   Description:    This files holds specific styles for drive
*   Author:         PreVeil, LLC
*/

//---------------------------------------------------------------
// Drive My Preveil, Shared, Invitations and  Trash
//---------------------------------------------------------------
.drive-component,
.watched-component {
  padding-bottom: 1rem;
  justify-content: space-around;
  .cover-content {
    background-color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .page-header {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
  }

  .drive-list .row,
  .toolbar.row {
    @extend .g-0;
    > div {
      padding: 0 0.25rem;
    }
  }

  // Listview
  .drive-list {
    .form-check {
      margin: 0;
      padding-left: 2rem;
      > input {
        margin-left: -22px;
      }
      > label {
        display: none; // need this to prevent improper overflow
      }
    }
    .dropdown {
      @extend .pv-dropdown;
      .ficon-more-vertical {
        font-size: 1.35rem;
      }
      .dropdown-toggle:after {
        display: none;
      }
      .dropdown-menu {
        margin-top: 0.65rem;
      }
    }
    .col-1,
    .col-sm-1,
    .col-md-1 {
      max-width: 40px;
    }
    .row:not(.drag-over) {
      border-bottom: $light-border;
    }
    .row {
      align-items: center;
      min-height: 40px;
      &.active {
        @include active-row();
      }
      &.locked {
        > div:first-of-type {
          cursor: not-allowed;
          text-align: center;
          opacity: 0.5;
          i {
            color: $text-muted;
            font-size: 1.5rem;
            margin-left: 0.35rem;
          }
        }
        .form-check {
          pointer-events: none;
        }
      }
      &.error-row {
        > div {
          color: $text-muted;
          font-style: italic;
        }
        .entry-name span {
          // Icons red
          [class^="ficon-"],
          [class*=" ficon-"] {
            color: $pv-red !important;
          }
        }
      }
    }
    .header-row {
      background-color: $white;
      font-weight: 700;
      padding: 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 1000;
    }
    &.mypreveil,
    &.shared {
      .header-row > div {
        cursor: pointer;
      }
    }
    &.shared,
    &.trash {
      .list-body {
        .row {
          &.hidden {
            &:hover {
              @extend .row-nohover;
            }
          }
        }
      }
    }
    .list-body {
      .row {
        &.create-directory {
          min-height: $drive-row-height;
          &:hover {
            background-color: $white;
          }
          .btn-icon,
          .btn-icon > i {
            &:hover,
            &:focus,
            &:active {
              background-color: $hover-color-light;
            }
          }
        }
        &:hover:not(.cursor-block) {
          background-color: $table-hover-bg;
          box-shadow: $box-shadow-sm;
          cursor: pointer;
        }
        > div span [class^="#{$pv-icon-css-prefix}-"] {
          color: $pv-light-blue;
          font-size: 2.5rem;
        }
      }
      // NOTE: Add class no-action to list-body divs for tables without last action column
      &:not(.no-action) > .row > div:last-of-type {
        margin-left: auto;
        margin-right: 0.125rem;
        padding: 0;
        .dropdown {
          padding: 0.25rem 0.35rem;
        }
      }
      // Directory entry name
      .entry-name span {
        display: block;
        margin-right: 0.5rem;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &.isloading {
      position: relative;
      .loading {
        left: 50%;
        margin-left: -($loading-logo-width * 0.5);
        position: absolute;
        top: 30%;
      }
      .list-body,
      .header-row {
        opacity: 0.5;
      }
      .list-messages {
        opacity: 0.2;
      }
    }

    // Fix hovers in trash and my preveil (hovers with dropdown menus)
    &.mypreveil,
    &.trash,
    &.watched {
      .list-body .row > div:last-of-type .dropdown {
        &:hover,
        &:focus,
        &:active,
        &.show {
          background-color: $white;
          border-color: $white;
          min-height: 100%;
        }
      }
    }

    // Invitation rows
    &.invitation .list-body {
      .row {
        .btn-panel {
          > .progress {
            min-width: 120px;
          }
        }
        &:hover {
          &.not-accepted {
            @extend .row-nohover;
          }
        }

        > div:nth-of-type(4) {
          text-align: center;
        }
      }
    }

    // Responsive top nav - mobile view <= 768px
    @include media-breakpoint-down(md) {
      // Default to my preveil drive list
      &.mypreveil,
      &.watched,
      &.watched-logs {
        .header-row {
          // Hide only the headers: type, modified and actions
          > div:nth-child(3),
          > div:nth-child(4),
          > div:nth-child(5) {
            display: none;
          }
        }
        .list-body {
          .row {
            padding: 0.5rem 0 1rem;
            > div:nth-child(3) {
              padding-left: 2.1rem;
              &:before {
                content: "Type:";
                color: $text-muted;
                display: inline-block;
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(4) {
              padding-left: 2.1rem;
              &:before {
                content: "Modified:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
      &.trash {
        .list-body {
          .row {
            padding: 0.5rem 0 1rem;
            > div:nth-child(3) {
              padding-left: 2.1rem;
              &:before {
                content: "Type:";
                color: $text-muted;
                display: inline-block;
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(4) {
              padding-left: 2.1rem;
              &:before {
                content: "Deleted At:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
      &.shared,
      &.invitation,
      &.trash {
        .header-row {
          display: none;
        }
        .list-body {
          .row {
            padding: 0.5rem 0.5rem 1rem;
          }
          .entry-name {
            span [class^="pv-icon-"] {
              font-size: 2rem;
              top: -2px;
            }
          }
          .btn-panel {
            margin: 0.5rem 0 0 !important;
            padding-left: 2.1rem;
          }
        }
      }

      &.invitation {
        .list-body {
          .col-md-2,
          .col {
            display: none;
          }
          .col-md-3:nth-child(2) {
            padding-left: 2.1rem;
            &:before {
              content: "Owner:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
          .btn-panel {
            > .btn {
              margin-right: 0.25rem;
              padding: 0.25rem 0.35rem;
            }
          }
        }
      }

      &.shared {
        .col-md-4:nth-child(2) {
          padding-left: 2.1rem;
          &:before {
            content: "Status:";
            color: $text-muted;
            display: inline-block;
            margin-right: 0.5rem;
          }
        }
      }

      &.watched {
        .list-body .row {
          > div:nth-child(3),
          > div:nth-child(4) {
            padding-left: 2.1rem;
            &:before {
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(3):before {
            content: "Type:";
          }
          > div:nth-child(4):before {
            content: "Modified:";
          }
        }
      }
      &.watched-logs {
        .header-row {
          > div:nth-child(2) {
            display: none;
          }
        }
        .list-body .row {
          > div:nth-child(2) {
            padding-left: 2.1rem;
            &:before {
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(2):before {
            content: "Event:";
          }
          > div:nth-child(3):before {
            content: "User:";
          }
        }
      }
    }
  }
}

// Drive Detail Sidebar (offcanvas)
.drive-details.sidebar.offcanvas {
  .dropdown {
    .dropdown-menu {
      margin-top: 0.25rem;
    }
    @extend .pv-dropdown;
  }
  .offcanvas-header {
    padding: 0 0.75rem 0 0.25rem;
    .btn-close {
      margin-right: 0;
    }
  }
  .rename-div,
  .title-div {
    display: flex;
    align-items: center;
    > span {
      [class^="#{$pv-icon-css-prefix}-"] {
        color: $pv-light-blue;
        font-size: 4rem;
      }
      [class^="#{$fs-icon-css-prefix}-"] {
        @extend .xlg;
      }
      .icon-lock2 {
        color: $gray-500;
        font-size: 1.25rem;
        margin: 0 0 -25px -30px;
      }
    }
    > p {
      cursor: pointer;
      padding-top: 0.5rem;
      margin-bottom: 0;
      [class^="#{$pv-icon-css-prefix}-"] {
        color: $text-muted;
        font-size: 1.65rem;
        margin: 0.125rem 0 0 0.25rem;
        opacity: 0.5;
      }
    }
    .form-control {
      font-size: 16px;
    }
    // Responsive - desktop view <= 768px - rename input needs more space
    @include media-breakpoint-up(sm) {
      form {
        min-width: 500px;
      }
    }
  }

  // Item Details
  .metadata-list {
    @extend .g-0;
    @extend .bordered;
    > div {
      margin-bottom: 0.5rem;
      label {
        color: $gray-600;
        display: block;
      }
      b {
        font-weight: 500;
      }
    }
    // Responsive sizing
    @include media-breakpoint-up(md) {
      > div {
        margin-bottom: 0;
        label {
          display: block;
        }
      }
    }
    @include media-breakpoint-down(md) {
      position: relative;
      .col-auto {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  // Collapsable header
  .collapse-section {
    @extend .bordered;
    padding-bottom: 0;
    > h5 {
      font-weight: 500;
    }
    h5 {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
    &.pb-0 {
      > h5 {
        margin-bottom: 1rem;
      }
    }
    &.closed {
      padding-bottom: 1rem;
    }
  }

  .progress-width {
    min-width: 20rem;
  }

  .lock-button-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    gap: 1rem;
  }

  .side-bar-counter {
    div {
      display: inline-block;
      text-align: center;
      line-height: 1.25rem;
      span {
        display: block;
        color: $text-muted;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
    span {
      vertical-align: top;
      display: inline-block;
      padding: 0 0.4rem 0 0.4rem;
    }
  }

  .drive-share {
    > h5 {
      align-self: center;
      display: flex;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .new-share {
      @extend .card-body;
      margin-bottom: 2rem;
      position: relative;
      > div {
        align-items: center;
        border-bottom: $light-border;
        display: flex;
        flex-wrap: wrap;
        // padding: 0.25rem 0.25rem 0;
        &.non-member-notif {
          background: $active-bg;
          font-size: $font-size-xs;
          font-weight: 700;
          padding: 0.4rem 0.75rem;
          justify-content: center;
          p {
            margin: 0;
          }
        }
        label {
          align-self: flex-start;
          color: $text-muted;
          font-size: $font-size-sm;
          text-align: right;
          padding: 0.5rem 1rem;
          width: 140px;
        }
        div {
          &.input-group {
            border: 0 none;
          }
          .user-autocomplete .autocomplete-results {
            right: auto;
          }
          .btn {
            font-weight: 500;
          }
        }
        .loading {
          margin: 15px 0;
        }
      }
      .expiration {
        label {
          line-height: 1rem;
          small {
            color: $text-muted;
            display: block;
          }
        }
        .expiration-input {
          box-shadow: none !important;
          min-height: 2.375rem;
        }
        .expiration-error {
          margin-left: auto;
        }
      }

      .btn-panel {
        border-bottom: none 0;
        margin-top: 1rem;
      }
      .toast.bg-warning {
        p {
          margin-bottom: 0;
        }
      }
      .dropdown > .dropdown-menu {
        margin-top: -2px;
      }
    }

    .access-list {
      .access-header {
        align-items: center;
        background-color: $active-bg;
        border-radius: $border-radius $border-radius 0 0;
        font-size: $font-size-sm;
        margin: 0;
        min-height: 30px;
        padding: 0 0.75rem;
        > div {
          padding: 0.25rem 0;
        }
      }
      .access-body {
        max-height: 350px;
        overflow-y: auto;
        .row {
          align-items: center;
          margin: 0;
          min-height: 2.375rem;
          padding: 0 0.25rem 0 0.75rem;
          small {
            font-size: $font-size-xs;
            display: block;
          }
          &:not(:last-child) {
            border-bottom: $light-border;
          }
          &:hover {
            background-color: $table-hover-bg;
          }
          > div {
            padding: 0.25rem 0;
            > span {
              font-size: $font-size-sm;
            }
          }
          &.owner {
            background-color: $table-hover-bg;
            color: $text-muted;
          }
          &.pending {
            background-color: $table-disabled-bg;
            color: $text-muted;
            font-style: italic;
            input,
            .btn {
              font-style: italic;
            }
          }
          &.new-date {
            > :nth-child(1),
            > :nth-child(2) > span,
            input {
              font-weight: 700;
            }
          }
          &.new-type {
            > :nth-child(1),
            > div > span,
            .dropdown-toggle {
              font-weight: 700 !important;
            }
            &.strike {
              > :nth-child(1),
              > div > span {
                @extend .text-decoration-line-through;
                font-style: italic;
                font-weight: 700;
              }
            }
          }
        }
        .dropdown {
          position: static;
          .dropdown-menu {
            margin-top: 0;
          }
          .readonly {
            &.dropdown-toggle:after {
              display: none;
            }
          }
          .active {
            cursor: not-allowed;
          }
        }
        .btn {
          font-weight: 400;
        }
      }
      &.saving {
        position: relative;
        > .access-body,
        > .btn-panel {
          opacity: 0.1;
          pointer-events: none;
        }
        .loading {
          position: absolute;
        }
      }
    }
    .btn-panel {
      margin: 1rem 1rem 0rem 0.25rem;
    }
  }

  // Read Only version while editing share
  .edit_mode,
  .readonly {
    .access-list {
      .btn,
      .expiration-input,
      .btn-panel {
        display: none;
      }
    }
  }

  .version-history {
    box-shadow: $box-shadow-sm;
    margin-top: 0.5rem;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    .row {
      align-items: center;
      margin: 0;
      min-height: 2.375rem;
      padding: 0 0.25rem 0 0;
      &:not(:last-child) {
        border-bottom: $light-border;
      }
      .dropdown {
        position: static;
      }
    }
  }
}

// Alerts
.details-alert {
  margin: 1.5rem 0.5rem 1rem;
  .alert-heading > [class^="ficon-"] {
    color: $text-primary;
    font-size: $font-size-lg;
    margin-right: 0.5rem;
  }
}

.create-icons {
  padding-left: 10px;
}

.create-form {
  border: none;
  &:hover {
    background-color: transparent;
  }
}

//---------------------------------------------------------------
// Drive Watched pages
//---------------------------------------------------------------
.watched-component {
  position: relative;
  // Email frequency control
  .email-frequency {
    margin-bottom: 0.5rem;
    padding-right: 1.25rem;
    // Responsive control - mobile view < 768px
    @include media-breakpoint-down(md) {
      margin-left: 40px;
    }
    > label {
      display: block;
      margin-bottom: 0;
      max-height: 24px;
    }
    .form-check {
      color: $text-muted;
      font-size: 14px;
      > label {
        text-transform: capitalize;
      }
    }
  }

  // Tab content area
  .tab-content {
    height: 100%;
    overflow: hidden;
    padding: 1rem 1rem 0;
    .tab-pane.active {
      height: 100%;
      overflow: hidden;
      .watched-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      > label {
        margin-bottom: 0.5rem;
      }
      .bc-title,
      .bc-title > a {
        color: $pv-blue;
        font-weight: 600;
      }
      .drive-list {
        height: 100%;
        overflow-y: auto;
        .list-messages {
          img {
            max-height: 30vh;
          }
          p {
            font-size: 1.25rem;
          }
        }
        &.watched {
          // Watched Items header
          .header-row {
            > div:nth-child(2),
            > div:nth-child(4),
            > div:nth-child(5) {
              cursor: pointer;
            }
          }
        }
        // Watched Logs header
        &.watched-logs {
          .header-row {
            z-index: 1;
            > div {
              cursor: pointer;
            }
          }
          .list-body {
            .row > div:last-of-type {
              .btn {
                padding: 0.25rem 0.35rem;
                &:hover,
                &:focus,
                &:active {
                  background-color: #fff !important;
                  border-color: transparent !important;
                  color: $pv-blue !important;
                }
                .ficon-more-vertical {
                  font-size: 1.35rem;
                }
              }
            }
          }
        }
        // Disabled icons for deleted rows and Watched recent changes rows
        &.watched .list-body .is_deleted,
        &.watched-logs .list-body {
          .entry-name {
            [class^="pv-icon-"] {
              color: $text-muted;
              font-size: 2.25rem;
            }
            [class^="ficon-"],
            [class*=" ficon-"] {
              color: $text-muted;
              font-size: 1.25rem;
              padding: 0.25rem 0.5rem;
              margin-right: 0.25rem;
              top: -2px;
            }
          }
          .breadcrumb {
            .breadcrumb-item:last-of-type,
            .last {
              a,
              a:hover {
                @extend .cursor-block;
                color: $text-muted;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }

  .nav-tabs .nav-link {
    padding: 0.5rem 2rem;
  }

  .watched {
    // Lists
    .list-body .row {
      // Table Location column
      .breadcrumb {
        font-size: $font-size-sm;
        margin: 0;
        max-width: 100%;
        .breadcrumb-item {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // Responsive - desktop view > 768px
  @include media-breakpoint-up(lg) {
    padding-top: 1.5rem;
    .email-frequency {
      margin-bottom: 0;
      position: absolute;
      right: 0;
      top: 1rem;
      z-index: 1000;
    }
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      max-height: 50vh;
      max-width: 100vw;
      min-width: 80vw;
      overflow-y: auto;
      right: 0;
      width: 100%;
      .date-selector {
        max-width: 100vw;
        min-width: 90%;
      }
      .logs {
        display: block;
      }
      .date-picker-inputs {
        display: block;
        max-width: 100%;
        .date-picker-input {
          margin: 0 0 0.25rem !important;
          max-width: 100%;
        }
      }
      .react-datepicker__month-container {
        display: block;
        float: none;
      }
    }
  }

  // Responsive control - mobile view < 768px
  @include media-breakpoint-down(md) {
    .nav-tabs .nav-link {
      font-size: 1rem;
      padding: 0.5rem 0.75rem;
    }
    .email-frequency {
      margin-left: 40px;
    }
    .tab-content .tab-pane.active {
      .breadcrumb {
        padding-left: 0;
      }
      .drive-list .list-messages {
        img {
          max-height: 25vh;
        }
        p {
          font-size: 1rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

// Watched Detail View - side panel
// ---------------------------------
.watched-details {
  @extend .drive-details;
  .bc-title,
  .bc-title > a {
    color: $pv-blue;
    font-weight: 600;
  }
  .changes {
    margin-bottom: 1rem !important;
    padding-bottom: 1rem !important;
    .changes-body {
      margin-top: 1rem;
      > div {
        &:not(:last-of-type) {
          @extend .bordered;
        }
        p {
          margin: 0;
          padding-left: 0.5rem;
          &:first-of-type {
            font-weight: 500;
            padding-left: 0;
          }
        }
      }
    }
  }
}
