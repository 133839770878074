/*
* File:             _drag-and-drop.scss
* Description:      This files holds styles for Drag and Drop (upload and move)
* Author:           PreVeil
*/

.dragging-item {
    background-color: $hover-color;
    opacity: 0.6;
}

.drag-over:not(.dragging-item) {
    background-color: $active-bg;
    border: 2px dashed $active-color;
    border-radius: $border-radius;
}

.drag-over-list {
    border: 2px dashed $active-color;
    border-radius: $border-radius;
}

#drag {
    align-items: center;
    background: white;
    border: $gray-600;
    display: flex;
    padding: 5px 15px 5px 15px;
    position: fixed;
    white-space: nowrap;
    i {
        color: $pv-blue;
        margin-right: 5px;
    }
}
