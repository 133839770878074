/*
*  File:            _variables.scss
*  Description:     This files holds variables used by site
*  Author:          PreVeil
*/

@import "./colors";

/* ------------------------------------------------------------------------- */
/* PATHS */
/* ------------------------------------------------------------------------- */
$assets-root-directory: "/assets";
$img-path: "/assets/images";
$fonts-path: "/assets/fonts";
$preveil-fonts-path: "/assets/fonts/PreVeil_Apps";
$im-font-path: "/assets/fonts/Icomoon"; //Icomoon path
$feather-font-path: "/assets/fonts/Feather"; //Feather Icons path
$icon-img-path: "/assets/images/icons"; // Drive SVG icons
$fs-icon-blue: "/drive_blue";
$fs-icon-grey: "/drive_grey";
$cicon-img-path: "~/public/flags/4x3";

/* ------------------------------------------------------------------------- */
/* Theme Variables */
/* ------------------------------------------------------------------------- */
$theme-body-font: "Libre Franklin", "Helvetica Neue", Helvetica, Arial, sans-serif;
$theme-headline-font: "Titillium Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
$theme-navigation-font: "Avenir Next", sans-serif;
$theme-font-size-default: 16px;

/*Iconography*/
/* Icon sets: 'icomoon' */
$icon-font-family: "icomoon";
$icon-font-size: 1em;
$im-css-prefix: "icon";

/* Icon sets: PreVeil Icons */
$pv-icon-font-size: 1.75em;
$pv-icon-css-prefix: "pv-icon";

/*Feather icon library*/
$feather-font-family: "feather";
$fi-css-prefix: "ficon";
$ficon-font-size: 1.5em;

/*Drive / Filesync icon library*/
$fs-icon-css-prefix: "fsi";
$fs-icon-font-size: 1em;
$fs-icon-size-sm: 24px;
$fs-icon-size: 40px;
$fs-icon-size-xlg: 60px;

/*Country icon library*/
$ci-icon-css-prefix: "cicon";
$ci-icon-font-size: 1em;
$ci-icon-size: 25px;
$ci-icon-height: 19px;

/* ------------------------------------------------------------------------- */
/* Bootstrap Variables Overrides*/
/* ------------------------------------------------------------------------- */
$body-bg: $white; // NOTE: This overrides all other backgrounds so set to white
$body-bg-base: $pv-lightgray;
$enable-negative-margins: true;
$font-family-sans-serif: $theme-body-font;
$headings-font-family: $theme-headline-font;
$headings-font-weight: 400;
$headings-margin-bottom: 1rem;

$body-color: $gray-800;
$font-size-root: $theme-font-size-default;
$font-size-base: 1rem; // 16px
$font-size-xs: $font-size-base * 0.75; // 12px
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-md: $font-size-base * 1.125; // 18px
$font-size-lg: $font-size-base * 1.25; // 20px
$font-size-xl: $font-size-base * 1.5; // 24px
$h1-font-size: 2rem; // $font-size-base * 2; // set now to 30px
$h2-font-size: 1.75rem; // $font-size-base * 1.7; // set now to 28
$h3-font-size: 1.5rem; //$font-size-base * 1.6; // set now to 24
$h4-font-size: 1.25rem; //$font-size-base * 1.5; //  set now to 20
$h5-font-size: 1.125rem; // $font-size-base * 1.375; // set now to 18
$h6-font-size: 1rem; // $font-size-base * 1.125; // set now to 16

$line-height-base: 1.5;
$line-height-xs: 1.15rem;
$line-height-sm: 1.25rem;
$line-height-lg: 2;
$link-color: #4a9cb7; //  $pv-blue-bright;
$link-shade-percentage: 50%;
$link-decoration: none;
$border-radius: 0.375rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default; // 16px
$border-radius-2xl: 2rem !default; // 32px
$border-radius-pill: 50rem !default;
$hr-opacity: 1;
$hr-color: $gray-200;

$zindex-fixed: 1050;
$zindex-dropdown: 1050;

// Theme color classes - legacy variables
$text-inverse: $gray-100;
$text-muted: $gray-600;
$text-muted-light: $gray-500;
$text-primary: $primary;
$text-info: $info;
$border-color: $gray-200;
$pv-icon: $gray-600;
$highlighted-color: rgb(255, 255, 190);

// Box shadows
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.325rem rgba($black, 0.08);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
$box-shadow-inset-md: inset 0.1rem 0 0.5rem rgba(0, 0, 0, 0.08);

// Buttons
$btn-padding-x: 0.875rem;
$btn-border-radius: $border-radius-lg;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-font-weight: 500;
$btn-link-color: $pv-btn-link;

$btn-close-color: $gray-700;
$btn-close-focus-shadow: none;

// Badges
$badge-font-size: $font-size-sm;
$badge-font-weight: 500;
$badge-padding-y: 0.125rem; // 0.125rem 0.375rem;
$badge-padding-x: 0.375rem;
$badge-border-radius: $border-radius-sm;

// Dropowns
$dropdown-min-width: 11rem;
$dropdown-border-radius: 0 0 $border-radius-sm $border-radius-sm;
$dropdown-dark-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
$dropdown-dark-border-color: rgba($white, 0.15);
$dropdown-link-hover-bg: color.adjust($active-bg, $lightness: 5%); //  lighten($active-bg, 5%); - deprecated
$dropdown-link-active-bg: $active-bg;
$dropdown-link-active-color: $body-color;

// Forms
$input-bg: $white;
$input-border-color: $gray-400;
$form-label-font-weight: 500;
$form-control-focus-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
$input-focus-box-shadow: $form-control-focus-box-shadow;
$input-focus-border-color: $gray-400;
$input-disabled-bg: $pv-lightgray;
$form-text-font-size: $font-size-xs;
$input-placeholder-color: $gray-500;
$form-check-input-checked-bg-color: $pv-light-blue;
$form-check-input-checked-border-color: $pv-light-blue;
$input-border-radius-sm: $border-radius-lg;
$form-check-input-border: solid 1px rgba($black, 0.5);
$form-check-input-indeterminate-color: $gray-500;
$form-check-input-indeterminate-bg-color: $white;
$form-check-input-indeterminate-border-color: $gray-500;
// Form selects
$form-select-focus-box-shadow: $form-control-focus-box-shadow;
$form-select-border-radius-sm: 0;
// Form Switches
$form-check-input-checked-border-color: $gray-300;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#d9d9d9'/></svg>");
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#fff'/></svg>");
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#d9d9d9'/></svg>");
$form-switch-border-radius: 1em;

// Tables
// $table-color: $white;
$table-border-color: $border-color;
$table-hover-bg: $hover-color;
$table-disabled-bg: $gray-100;

// Tabs
$nav-tabs-border-radius: 0;
$nav-tabs-border-color: $gray-400;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $white;
$nav-tabs-link-hover-border-color: transparent;
$nav-link-hover-color: black;
$nav-tabs-link-active-bg: $white;

// Modals
$modal-header-border-length: 3px;
$modal-lg: 650px;
$modal-content-border-color: none;
$modal-footer-margin-between: 0.25rem;
$modal-footer-border-color: transparent;
$modal-sm: 400px;
$modal-header-padding-y: 0.75rem;
// $modal-fade-transform: translate3d(0, 100vh, 0);

// Pagination
$pagination-color: $active-color;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-disabled-color: $gray-500;
$pagination-disabled-bg: transparent;
$pagination-hover-color: $pv-blue;
$pagination-hover-bg: $active-color-light;
$pagination-active-color: $active-color;
$pagination-active-bg: transparent;
$pagination-active-border-color: transparent;
$pagination-focus-box-shadow: none;

// Breadcrumbs
$breadcrumb-divider: quote("\e930");
$breadcrumb-item-padding-x: 0.25rem;
$breadcrumb-divider-color: $black;
$breadcrumb-active-color: $pv-blue;
$breadcrumb-color: $body-color;
$breadcrumb-hover-color: $gray-600;

// Accordion
$accordion-bg: $white;

// Progress Bars
$progress-height: 0.5rem;

//Cards
$card-border-color: $body-bg;
$card-border-radius: $border-radius;
$card-title-spacer-y: 1rem;
$card-box-shadow: $box-shadow;
$card-cap-bg: $white;

//List groups
$list-group-active-color: $gray-900;
$list-group-active-bg: $white;
$list-group-active-border-color: rgba($black, 0.125);
/* ------------------------------------------------------------------------- */
/* PreVeil specific variables*/
/* ------------------------------------------------------------------------- */
// theme utilities:
$light-border: solid 1px $border-color;

// Components:
// Navbar
$header-height: 3.75rem;
$header-brand-width: 90px;
$header-font-size: 0.9375rem;
$navbar-color: $header-inactive-color;
$navbar-active-color: $white;
$navbar-bg: $pv-navbar-blue;
$navbar-bg-hover: color.adjust($navbar-bg, $lightness: 10%); // lighten($navbar-bg, 10%); - deprecated
$navbar-dropdown-bg: $pv-navbar-blue-dark;
$navbar-dropdown-color: $white;
$header-active-color: $white;
$nav-bg: $text-inverse;
$nav-border: $light-border;
$nav-border-color-closed: $gray-400;
$nav-padding: 0.5rem;
$header-dropdown-top: 40px;
$header-dropdown-width: 175px;
$header-dropdown-color: $pv-blue;
$header-dropdown-main-color: $gray-800;
$header-account-max-width: 200px;

// Bootstrap overrides and additional variables:
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 22px;
$nav-link-height: 50rem;
$nav-link-height: $header-height;
$navbar-brand-height: $header-height;
$navbar-brand-padding-y: 0;
$navbar-brand-margin-end: 0;
$navbar-dark-color: $text-inverse;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-toggler-focus-width: 0;
$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
// custom var
$navbar-dark-toggler-icon-hover-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-hover-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-hover-bg: rgba(0, 0, 0, 0.1);
$navbar-dark-active-bg: rgba(0, 0, 0, 0.2);

// Left Navigation
$left-nav-width: 250px;
$left-nav-width-closed: 50px;
$left-nav-active-link-bg: $active-bg;
$left-nav-link-color: $active-color;
$left-nav-active-link-color: $active-color;
$left-nav-hover-link-bg: $gray-200;
$left-navitem-color: #777;

// Loading component
$loading-logo-height: 40px;
$loading-logo-width: 110px;
$loading-progress-bg: #bac3cc;
$loading-progress-color: #e8eced;
$loading-progress-width: 140px;

// Public cards
$public-page-max-width: 990px;
$create-account-max-width: 1200px;
$public-card-image-width: 240px;
$public-card-content-width: 100%;

// Toasts
$toast-max-width: 100%;
$toast-font-size: $font-size-base;
$toast-color: $black;
$toast-border-width: 0;
$toast-border-radius: 0;

// Forms
$form-input-icon-height: 100%;
$form-input-icon-width: 30px;

// Mail
$mail-badge-icon-color: color.adjust($active-color, $lightness: 25%); //  lighten($active-color, 25%); - deprecated
$mail-search-icon-color: #152544;
$mail-search-border-color: #ccc;
$mail-search-box-shadow: inset 0px 3px 8px rgb(0 0 0 / 10%);

// Drive
$drive-row-height: 48px;
