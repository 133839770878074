/*
* File:             _mixins.scss
* Description:      This files holds custom mixins
* Author:           PreVeil
*/

// Rotate icon
@mixin pv-icon-rotate($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  transform: rotate($degrees);
}

// Flip icon
@mixin pv-icon-flip($horiz, $vert, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
  transform: scale($horiz, $vert);
}

@mixin divider($bg, $margin, $opacity, $width) {
  background-color: $bg;
  display: block;
  margin: $margin;
  opacity: $opacity;
  width: $width;
}
//  @include divider($pv-navbar-blue-light, 1rem 0, 0.5, 2px);

@mixin small-dropdown-menu() {
  border-radius: $border-radius;
  font-family: $theme-body-font;
  font-size: $font-size-sm;
  padding: 0;
  .dropdown-item {
    padding: 0.25rem 0.5rem;
    &:first-child {
      &:hover {
        border-radius: $border-radius $border-radius 0 0;
      }
    }
    &:last-child {
      &:hover {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
    &:active,
    &.active,
    &:focus-visible {
      color: $active-color;
      font-weight: 500;
      outline: 0 none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
    [class^="#{$pv-icon-css-prefix}-"] {
      margin: -2px 5px 0 -0.25rem;
    }
  }
}

// Table Row or Row active state
@mixin active-row {
  // background-color: lighten($active-color-light, 15%); - deprecated
  background-color: color.adjust($active-color-light, $lightness: 15%);
  p {
    color: $gray-800;
  }
}

// Caret icons
@mixin caret-down {
  border-top: $caret-width solid;
  border-right: $caret-width solid transparent;
  border-bottom: 0;
  border-left: $caret-width solid transparent;
}

@mixin pv-caret-up($width: 0.5em) {
  border-top: 0;
  border-right: $width solid transparent;
  border-bottom: $width solid;
  border-left: $width solid transparent;
}

// @mixin caret-right {
//   border-top: $caret-width solid transparent;
//   border-right: 0;
//   border-bottom: $caret-width solid transparent;
//   border-left: $caret-width solid;
// }

// @mixin caret-left {
//   border-top: $caret-width solid transparent;
//   border-right: $caret-width solid;
//   border-bottom: $caret-width solid transparent;
// }

// @mixin caret($direction: down) {
//   @if $enable-caret {
//     &::after {
//       display: inline-block;
//       margin-left: $caret-spacing;
//       vertical-align: $caret-vertical-align;
//       content: "";
//       @if $direction == down {
//         @include caret-down;
//       } @else if $direction == up {
//         @include caret-up;
//       } @else if $direction == right {
//         @include caret-right;
//       }
//     }

//     @if $direction == left {
//       &::after {
//         display: none;
//       }

//       &::before {
//         display: inline-block;
//         margin-right: $caret-spacing;
//         vertical-align: $caret-vertical-align;
//         content: "";
//         @include caret-left;
//       }
//     }

//     &:empty::after {
//       margin-left: 0;
//     }
//   }
// }
