/*
* File:             _icons.scss
* Description:      This files holds styles for themes icons and icon overrides
* Author:           PreVeil
*/
@font-face {
  font-family: "preveil-apps";
  src: url("#{$preveil-fonts-path}/preveil-apps.eot?78438996");
  src:
    url("#{$preveil-fonts-path}/preveil-apps.eot?78438996#iefix") format("embedded-opentype"),
    url("#{$preveil-fonts-path}/preveil-apps.woff2?78438996") format("woff2"),
    url("#{$preveil-fonts-path}/preveil-apps.woff?78438996") format("woff"),
    url("#{$preveil-fonts-path}/preveil-apps.ttf?78438996") format("truetype"),
    url("#{$preveil-fonts-path}/preveil-apps.svg?78438996#preveil-apps") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="#{$pv-icon-css-prefix}-"],
[class*=" #{$pv-icon-css-prefix}-"] {
  display: inline-block;
  font-family: "preveil-apps" !important;
  font-size: $pv-icon-font-size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  position: relative;
  speak-as: none;
  text-transform: none;
  text-align: center;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.#{$pv-icon-css-prefix}-add-account:before {
  content: "\e800";
} /* "" */
.#{$pv-icon-css-prefix}-add-contact:before {
  content: "\e801";
} /* "" */
.#{$pv-icon-css-prefix}-add:before {
  content: "\e802";
} /* "" */
.#{$pv-icon-css-prefix}-android:before {
  content: "\e803";
} /* "" */
.#{$pv-icon-css-prefix}-arrow-down:before {
  content: "\e804";
} /* "" */
.#{$pv-icon-css-prefix}-arrow-left:before {
  content: "\e805";
} /* "" */
.#{$pv-icon-css-prefix}-arrow-right:before {
  content: "\e806";
} /* "" */
.#{$pv-icon-css-prefix}-arrow-up:before {
  content: "\e807";
} /* "" */
.#{$pv-icon-css-prefix}-attach:before {
  content: "\e808";
} /* "" */
.#{$pv-icon-css-prefix}-attachment:before {
  content: "\e809";
} /* "" */
.#{$pv-icon-css-prefix}-calendar:before {
  content: "\e80a";
} /* "" */
.#{$pv-icon-css-prefix}-carat:before {
  content: "\e80b";
} /* "" */
.#{$pv-icon-css-prefix}-chevron-down:before {
  content: "\e80c";
} /* "" */
.#{$pv-icon-css-prefix}-chevron-left:before {
  content: "\e80d";
} /* "" */
.#{$pv-icon-css-prefix}-chevron-right:before {
  content: "\e80e";
} /* "" */
.#{$pv-icon-css-prefix}-chevron-up:before {
  content: "\e80f";
} /* "" */
.#{$pv-icon-css-prefix}-clear:before {
  content: "\e810";
} /* "" */
.#{$pv-icon-css-prefix}-close:before {
  content: "\e811";
} /* "" */
.#{$pv-icon-css-prefix}-cloud:before {
  content: "\e812";
} /* "" */
.#{$pv-icon-css-prefix}-compose:before {
  content: "\e813";
} /* "" */
.#{$pv-icon-css-prefix}-contact:before {
  content: "\e814";
} /* "" */
.#{$pv-icon-css-prefix}-desktop:before {
  content: "\e815";
} /* "" */
.#{$pv-icon-css-prefix}-document-multiple:before {
  content: "\e816";
} /* "" */
.#{$pv-icon-css-prefix}-file-audio:before {
  content: "\e817";
} /* "" */
.#{$pv-icon-css-prefix}-download:before {
  content: "\e818";
} /* "" */
.#{$pv-icon-css-prefix}-duplicate:before {
  content: "\e819";
} /* "" */
.#{$pv-icon-css-prefix}-filter-alt:before {
  content: "\e81b";
} /* "" */
.#{$pv-icon-css-prefix}-filter:before {
  content: "\e81c";
} /* "" */
.#{$pv-icon-css-prefix}-folder-default:before {
  content: "\e81d";
} /* "" */
.#{$pv-icon-css-prefix}-folder-download:before {
  content: "\e81e";
} /* "" */
.#{$pv-icon-css-prefix}-folder-shared:before {
  content: "\e81f";
} /* "" */
.#{$pv-icon-css-prefix}-folder-upload:before {
  content: "\e820";
} /* "" */
.#{$pv-icon-css-prefix}-forward:before {
  content: "\e821";
} /* "" */
.#{$pv-icon-css-prefix}-fullscreen:before {
  content: "\e822";
} /* "" */
.#{$pv-icon-css-prefix}-info:before {
  content: "\e823";
} /* "" */
.#{$pv-icon-css-prefix}-key-small:before {
  content: "\e824";
} /* "" */
.#{$pv-icon-css-prefix}-key:before {
  content: "\e825";
} /* "" */
.#{$pv-icon-css-prefix}-laptop:before {
  content: "\e826";
} /* "" */
.#{$pv-icon-css-prefix}-mac:before {
  content: "\e827";
} /* "" */
.#{$pv-icon-css-prefix}-mailbox-empty:before {
  content: "\e828";
} /* "" */
.#{$pv-icon-css-prefix}-mark-read:before {
  content: "\e829";
} /* "" */
.#{$pv-icon-css-prefix}-message-alt:before {
  content: "\e82a";
} /* "" */
.#{$pv-icon-css-prefix}-message:before {
  content: "\e82b";
} /* "" */
.#{$pv-icon-css-prefix}-minimize:before {
  content: "\e82c";
} /* "" */
.#{$pv-icon-css-prefix}-mobile:before {
  content: "\e82d";
} /* "" */
.#{$pv-icon-css-prefix}-more-alt:before {
  content: "\e82e";
} /* "" */
.#{$pv-icon-css-prefix}-more:before {
  content: "\e82f";
} /* "" */
.#{$pv-icon-css-prefix}-move:before {
  content: "\e830";
} /* "" */
.#{$pv-icon-css-prefix}-multiple-messages-alt:before {
  content: "\e831";
} /* "" */
.#{$pv-icon-css-prefix}-new-folder:before {
  content: "\e833";
} /* "" */
.#{$pv-icon-css-prefix}-open:before {
  content: "\e834";
} /* "" */
.#{$pv-icon-css-prefix}-organization:before {
  content: "\e835";
} /* "" */
.#{$pv-icon-css-prefix}-question:before {
  content: "\e836";
} /* "" */
.#{$pv-icon-css-prefix}-refresh:before {
  content: "\e837";
} /* "" */
.#{$pv-icon-css-prefix}-reply-all:before {
  content: "\e838";
} /* "" */
.#{$pv-icon-css-prefix}-reply:before {
  content: "\e839";
} /* "" */
.#{$pv-icon-css-prefix}-search:before {
  content: "\e83a";
} /* "" */
.#{$pv-icon-css-prefix}-settings:before {
  content: "\e83b";
} /* "" */
.#{$pv-icon-css-prefix}-share:before {
  content: "\e83c";
} /* "" */
.#{$pv-icon-css-prefix}-starred:before {
  content: "\e83d";
} /* "" */
.#{$pv-icon-css-prefix}-trash:before {
  content: "\e83e";
} /* "" */
.#{$pv-icon-css-prefix}-unstarred:before {
  content: "\e83f";
} /* "" */
.#{$pv-icon-css-prefix}-windows:before {
  content: "\e840";
} /* "" */
.#{$pv-icon-css-prefix}-multiple-messages:before {
  content: "\e841";
} /* "" */
.#{$pv-icon-css-prefix}-file-code:before {
  content: "\e843";
} /* "" */
.#{$pv-icon-css-prefix}-file-excel:before {
  content: "\e844";
} /* "" */
.#{$pv-icon-css-prefix}-file-image:before {
  content: "\e845";
} /* "" */
.#{$pv-icon-css-prefix}-file-pdf:before {
  content: "\e846";
} /* "" */
.#{$pv-icon-css-prefix}-file-powerpoint:before {
  content: "\e847";
} /* "" */
.#{$pv-icon-css-prefix}-file-text:before {
  content: "\e848";
} /* "" */
.#{$pv-icon-css-prefix}-file-video:before {
  content: "\e849";
} /* "" */
.#{$pv-icon-css-prefix}-file-word:before {
  content: "\e84a";
} /* "" */
.#{$pv-icon-css-prefix}-file-zip:before {
  content: "\e84b";
} /* "" */
.#{$pv-icon-css-prefix}-file:before {
  content: "\e84c";
} /* "" */
.#{$pv-icon-css-prefix}-finder:before {
  content: "\e84d";
} /* "" */
.#{$pv-icon-css-prefix}-approval-group:before {
  content: "\e878";
} /* "" */
.#{$pv-icon-css-prefix}-approved:before {
  content: "\e879";
} /* "" */
.#{$pv-icon-css-prefix}-receipt:before {
  content: "\e87a";
} /* "" */
.#{$pv-icon-css-prefix}-unapproved:before {
  content: "\e87b";
} /* "" */
.#{$pv-icon-css-prefix}-failed:before {
  content: "\e89e";
} /* '' */
.#{$pv-icon-css-prefix}-domain:before {
  content: "\e8a0";
} /* '' */

// Rotated & Flipped Icons
// -------------------------
// Rotation
.#{$pv-icon-css-prefix}-rotate-90 {
  @include pv-icon-rotate(90deg, 1);
}
.#{$pv-icon-css-prefix}-rotate-180 {
  @include pv-icon-rotate(180deg, 2);
}
.#{$pv-icon-css-prefix}-rotate-270 {
  @include pv-icon-rotate(270deg, 3);
}
// Flip
.#{$pv-icon-css-prefix}-flip-horizontal {
  @include pv-icon-flip(-1, 1, 0);
}
.#{$pv-icon-css-prefix}-flip-vertical {
  @include pv-icon-flip(1, -1, 2);
}
.#{$pv-icon-css-prefix}-flip-horizontal.#{$pv-icon-css-prefix}-flip-vertical {
  @include pv-icon-flip(-1, -1, 2);
}

// Additional Custom icons
// -------------------------
.connected,
.waiting {
  animation-direction: normal;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-name: loopOpacity;
  animation-timing-function: ease;
  background: $pv-green;
  border-radius: 1.25rem;
  display: inline-block;
  height: 1rem;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  overflow: hidden;
  position: relative;
  top: 1px;
  width: 1rem;
  z-index: 1;
}
.waiting {
  background: $pv-yellow;
  margin-right: 0.5rem;
}
@keyframes loopOpacity {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.6;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.success-icon-border {
  border: 0.125rem solid $pv-green-dark;
  border-radius: 1.5rem;
  display: inline-block;
  font-size: 2rem;
  line-height: 1;
  height: auto;
  width: auto;
  .icon {
    color: $pv-green-dark;
    font-size: inherit;
    line-height: 1em;
    padding: 1px;
    position: relative;
  }
}

.right-logo {
  border-left: $light-border;
  float: right;
  overflow: hidden;
  .logo-container {
    color: $pv-orange;
    font-size: 50px;
    height: 34px;
    line-height: 34px;
    padding: 6px 12px;
    position: relative;
    width: 65px;
    .icon-key {
      font-size: 50px;
      position: absolute;
      right: 7px;
      top: -7.5px;
    }
  }
}

// CUI NOTICE ICONS
.pv-icon-sm {
  @extend .pv-icon-key-small;
  color: $pv-orange;
  font-weight: 600;
  margin-right: 0.5rem;
}

/* Image Icons */
.sm-icon-placeholder {
  display: inline-block;
  height: 24px;
  width: 20px;
}

/* Sync Arrow icons */
.sync-icon:before {
  background-color: $pv-icon;
  border-radius: 50%;
  color: $white;
  padding: 0.2rem;
}
