/*
* File:             _left-navigation.scss
* Description:      This files holds styles for left navigation / sidebar.
* Author:           PreVeil
*/
.left-navigation {
  font-family: $theme-navigation-font;
  overflow-x: hidden;
  padding: 0.5rem 0 1rem 0.75rem;
  > .nav {
    @extend .flex-column;
    margin-top: 0.5rem;
  }
  .nav-link,
  .nav-item,
  .left-nav-btn {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
  }
  .nav-link,
  .nav-item {
    border-radius: $border-radius-2xl;
    color: $left-nav-link-color;
    margin-bottom: 0.25rem;
    padding: 0.5rem 0.75rem;
    position: relative;
    // icon
    [class^="ficon-"] {
      font-size: 1.25rem;
      top: -2px;
    }

    // text
    > span {
      margin-left: 10px;
    }
    span.badge {
      background-color: $body-bg-base !important;
      color: $left-nav-active-link-color;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      position: absolute;
      right: 0.5rem;
      top: 0.65rem;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $left-nav-hover-link-bg;
      color: $left-nav-active-link-color;
      .badge {
        background-color: $left-nav-hover-link-bg !important;
      }
    }
    &.active {
      background-color: $left-nav-active-link-bg;
      color: $left-nav-active-link-color;
      .badge {
        background-color: $left-nav-active-link-bg !important;
      }
    }
  }
  hr {
    margin: $nav-padding;
  }
  // Top action button
  .left-nav-btn {
    font-weight: 300;
    max-width: 400px;
    text-align: left;
  }
  // Headers in Settings Nav
  .nav-item {
    color: $left-navitem-color;
    font-weight: 500;
    max-height: 40px;
    &:hover {
      background: none;
    }
    [class^="#{$pv-icon-css-prefix}-"] {
      margin: 0 5px 0 -4px;
      top: -2px;
    }
    > i {
      opacity: 0.5;
    }
  }
  // Responsive left nav - mobile view <= 768px
  @include media-breakpoint-down(md) {
    padding-right: 0.75rem;
  }
}

.feedback {
  // font-size: $font-size-sm;
  padding: 0.5rem 0;
  > span {
    margin: 0 !important;
  }
}

// Toggle Button
.toggle-box {
  display: flex;
  min-height: 38px;
  margin: 0.5rem 0.5rem 0.75rem 1rem;
  padding: 0;
  position: relative;
  .toggle-nav {
    padding-left: 0.25rem;
  }
  p {
    color: $left-nav-active-link-color;
    font-weight: 500;
    line-height: 1.1em;
    margin: 0.25rem 0 0 0.5rem;
    max-width: calc(100% - 1.5rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    small {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:after {
    background-color: $hr-color;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    bottom: -0.5rem;
    width: 100%;
  }
}
.toggle-nav {
  i {
    background-image: $navbar-light-toggler-icon-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
  &:hover {
    opacity: 0.5;
  }
}

// Responsive Toggle button under the right panel - mobile view <= 768px
@include media-breakpoint-down(md) {
  .right-pane {
    .page-header > h1,
    .breadcrumb {
      padding-left: 45px;
    }
    > .toggle-nav {
      left: 24px;
      position: absolute;
      top: 80px;
      z-index: 10;
    }
  }
  .on .right-pane {
    > .toggle-nav {
      display: none;
    }
  }
}

// Closed State
.closed {
  .toggle-box {
    margin-right: 1rem;
    p {
      display: none;
    }
  }
  .left-navigation {
    .nav-link,
    .nav-item,
    .left-nav-btn {
      max-width: 40px;
      width: auto;
      > span {
        display: none;
      }
      > span.badge {
        background-color: $pv-orange !important;
        border-radius: 50%;
        color: $white;
        display: block;
        font-size: $font-size-xs;
        font-weight: 400;
        min-width: 1.25rem;
        padding: 5px 0 3px;
        text-align: center;
        top: -0.125rem;
        right: -0.25rem;
        // mark bubble 99+ on small nav
        &.exceed_limit {
          color: transparent;
          height: 22px;
          width: 22px;
          &:after {
            content: "99+";
            color: $white;
            display: block;
            font-size: 11px;
            left: 1px;
            position: absolute;
            top: 0.375rem;
          }
        }
      }
    }
    .nav-link,
    .nav-item {
      padding: 0.5rem 0.625rem;
    }
    .left-nav-btn {
      margin-left: 0;
      min-width: 40px;
      padding-left: 0;
      padding-right: 0;
      > i {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

// App build mode only styles
.app_build {
  .left-navigation {
    > .nav {
      margin-top: 1.5rem;
    }
  }
}
