/*
*   File:           _public.scss
*   Description:    This files holds specific styles for the public pages
*   Author:         PreVeil, LLC
*/

.public-page {
  height: auto;
  min-height: 100vh;
  .content-wrapper {
    margin: 0 auto;
    max-width: $public-page-max-width;
    overflow: visible;
    padding-top: 2rem;
    // Responsive left nav - mobile view <= 768px
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}

// Create Account Pages
.create-account-pages {
  .public-page {
    .content-wrapper {
      max-width: $create-account-max-width;
      padding-top: 3.125rem;
    }
  }

  .twofa-info {
    padding: 0.5rem;
    p {
      color: $white;
      font-size: $font-size-lg;
    }
    img {
      margin: 0.3rem;
    }
  }

  .create-account-card {
    background: black;
    border-radius: $border-radius-lg;
    padding: 1rem;
    .col-md-7 {
      border-radius: $border-radius-lg;
      max-width: 640px;
      padding: 0;
    }
    .pv-card {
      max-width: 640px;
      min-height: 440px;
      padding-bottom: 60px;
      .card-body {
        padding: 1rem 1.5rem;
        &.form-error .form-text {
          font-size: $font-size-lg;
        }
        .btn-panel {
          bottom: 0;
          padding: 1rem 0;
          position: absolute;
          width: 85%;
        }
      }
      .totp-separator {
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: 600;
      }
    }
    .right-panel {
      background: url("/assets/images/account/small-circle.png"), url("/assets/images/account/large-circle.png"),
        url("/assets/images/account/account-background.png");
      background-position:
        bottom left,
        top 60% right 10%,
        right;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-size: auto, auto, cover;
      padding: 0;
    }
  }
}

// Connect Accounts
.pin {
  font-size: 3.125rem;
  font-weight: 500;
}

.links {
  display: flex;
  justify-content: space-between;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

// Upgrade account adjusments
.web_build {
  .login-email-form .btn-panel {
    display: grid;
    .btn {
      margin-right: 0;
      &.btn-outline-secondary {
        display: none;
      }
    }
  }
}

.upgrade-account {
  .form-control {
    max-width: 400px;
  }
}

// Misc error pages
.error-icon {
  color: $pv-red;
  font-size: 2rem;
  margin-right: 10px;
  top: -3px;
}

// TOTP
.totp-code {
  padding-bottom: 0.5rem;
  text-align: center;
}

// Choose Account
.choose-account {
  justify-content: center;
  .list-group {
    &:focus,
    &:focus-visible {
      outline: none;
    }
    .list-group-item {
      border-left: none;
      border-right: none;
      cursor: pointer;
      padding: 1rem;
      span {
        display: block;
        font-size: $font-size-sm;
        &.name {
          color: $pv-blue;
          font-size: $font-size-lg;
          font-weight: 500;
          line-height: 1.25em;
        }
      }
      &:focus,
      &:focus-visible {
        outline: none;
      }
      &:hover {
        background: $hover-color;
      }
    }
  }
}

// Approval Groups
.recovery-options {
  @extend .list-unstyled;
  margin-bottom: 2rem;
  li {
    align-items: center;
    // border-bottom: solid 1px lighten($border-color, 5%); - deprecated
    border-bottom: solid 1px color.adjust($border-color, $lightness: 5%);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    div * {
      margin: 0;
    }
    h2 {
      font-weight: 400;
    }
  }
}

.alert-icon {
  .ficon-alert-triangle {
    color: $pv-yellow;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
}

.progress-circles-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .progress-circle {
    border: 1px solid $link-color;
    border-radius: 20px;
    display: inline-block;
    height: 20px;
    margin: 5px;
    width: 20px;
    &.filled-in {
      background-color: $link-color;
    }
  }
}

.password-validator {
  font-size: $font-size-xs;
  margin-top: 0.25rem;
  > span {
    > i {
      font-weight: 700;
      margin-right: 0.25rem;
    }
    font-style: italic;
    margin-right: 0.5rem;
  }
  .ficon-x {
    color: $pv-red;
  }
  .ficon-check {
    color: $pv-green;
  }
}
