/*
*   File:           _toolbar.scss
*   Description:    This files holds specific styles for toolbar components in drive, settings and mail
*   Author:         PreVeil, LLC
*/

.toolbar {
  align-items: center;
  background-color: $active-bg;
  border-radius: $border-radius $border-radius 0 0;
  min-height: 40px;
  .line {
    background-color: $active-color-light;
    border-left: solid 1px $border-color;
    display: inline-block;
    margin: 0.25rem !important;
    max-width: 2px;
    padding: 0 !important;
    width: 2px;
  }
  .input-group {
    border: none;
    height: 31px;

    & > .form-control {
      height: 100%; // keeping the form-control input with the default font-size without increasing the input height.
      padding-left: 0.2rem;
    }

    .input-group-btn,
    .input-group-btn:hover,
    .input-group-text {
      background: $white;
      border: solid 1px $white;
      color: $text-muted-light;
      > i {
        top: 0;
      }
    }
    .input-group-btn:hover {
      color: $text-muted;
    }
  }

  &.trash,
  &.watched,
  &.admin {
    .input-group {
      border: none;
      height: 31px;
      & > .form-control {
        height: 100%; // keeping the form-control input with the default font-size without increasing the input height.
        padding-left: 0.2rem;
        i {
          padding-right: 0.3rem;
        }
        &:disabled {
          background-color: #fff;
        }
      }
    }
  }
  // Empty result set:
  &.disabled {
    .order-0,
    .order-1 {
      cursor: not-allowed;
      opacity: 0.2;
      .btn,
      .form-check-input {
        pointer-events: none;
      }
    }
  }
  &.btn-disabled > div {
    cursor: not-allowed;
  }
  .btn-icon {
    border-radius: $border-radius;
    margin-right: 0.25rem;
    &:disabled {
      opacity: 0.5;
    }
    &:hover,
    &.show {
      background-color: $active-color-light;
      border-color: $active-color-light;
    }
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      @include small-dropdown-menu();
      margin-top: 0.25rem;
      .dropdown-item {
        padding: 0.5rem;
      }
    }
  }
  [class^="#{$pv-icon-css-prefix}-"] {
    margin: 0 2px 0;
  }
  [class^="#{$fi-css-prefix}-"] {
    font-size: 1.1rem;
  }
  .pv-icon-arrow-left {
    font-size: 2rem;
    margin-right: 0;
  }

  > .divider.col {
    background-color: $active-color-light;
    margin: 0.5rem 0 !important;
    max-width: 2px;
    padding: 0 !important;
    width: 2px;
  }
  .order-1 {
    white-space: nowrap;
  }
  .pagination_info {
    // border-right: solid 1px $active-color-light;
    color: $text-muted;
    font-size: $font-size-sm;
    text-align: right;
    // padding-right: 0.75rem;
    b {
      font-weight: 500;
    }
  }
  .form-check {
    padding-left: 1.75rem;
  }
  .dropdown-menu.watch-menu {
    min-width: 160px;
    .dropdown-item {
      padding-bottom: 0.25rem;
      > div {
        display: flex;
      }
    }
  }
  // Responsive left nav - mobile view <= 576px
  @include media-breakpoint-down(sm) {
    > .divider.col {
      display: none;
    }
    .order-1 {
      flex: 0 0 auto !important;
      white-space: nowrap;
      width: auto !important;
    }
    .pagination_info {
      font-size: $font-size-xs;
    }
  }
}
// Refresh button in toolbar and in the DetailsView panels
.refresh {
  .btn-sm {
    height: 1.75rem;
    position: relative;
    margin-top: 2px;
    width: 1.75rem;
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
  .spinner {
    display: block;
    margin: 0;
    left: 6px;
    line-height: 1.25rem;
    position: absolute;
    top: 6px;
  }
}
