/*
*   File:           _bootstrap-overrides.scss
*   Description:    This files holds bootstrap overrides
*   Author:         PreVeil, LLC
*/
// utility fonts
.fs-1 {
  font-size: $h4-font-size !important; // 24px
}
.fs-2 {
  font-size: $h5-font-size !important; // 22px
}
.fs-3 {
  font-size: $font-size-lg !important; // 20px
}
.fs-4 {
  font-size: $font-size-md !important; // 18px
}
.fs-5 {
  font-size: $font-size-sm !important; // 14px
}
.fs-6 {
  font-size: $font-size-xs !important; // 12px
}

// alerts
.alert {
  background-color: $white;
  &.alert-primary {
    .btn-close {
      color: $pv-orange;
    }
    .alert-heading {
      color: $pv-orange;
      font-size: $font-size-md;
      font-weight: 400;
    }
  }
  p {
    color: black;
    margin-bottom: 0;
  }
}

//breadcrumbs
.breadcrumb-item {
  a {
    color: $pv-blue;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// forms
.form-disabled {
  padding-bottom: 2rem;
  .form-control {
    opacity: 0.5;
  }
}

.switch-preveil {
  .form-switch {
    cursor: pointer;
    margin-bottom: 0;
    .form-check-input {
      background-color: $gray-400;
      background-image: url("/assets/images/toggle-switch-button.svg");
      background-repeat: no-repeat;
      background-size: 1.4rem;
      border-color: $gray-300;
      font-size: 0.8rem;
      font-weight: 500;
      height: 1.5rem;
      margin-top: 0;
      position: relative;
      text-wrap: nowrap;
      width: 3.125rem;
      &:after {
        content: "off";
        top: 0;
        color: $gray-700;
        left: 52%;
        line-height: 24px;
        position: absolute;
        right: 0;
      }
      &:focus {
        border-color: $gray-300;
        outline: 0;
      }
      &:checked {
        background-color: $active-color;
        border-color: $gray-300;
        &:after {
          content: "on";
          top: 0;
          color: $white;
          left: 15%;
          line-height: 22px;
          position: absolute;
        }
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    .form-check-label {
      padding-left: 0.5rem;
    }

    &.active {
      & > input:after {
        content: "on";
        bottom: 2px;
        color: $white;
        left: 15%;
        position: absolute;
      }
    }

    &.inactive {
      & > input {
        border: 1px solid $gray-500;
      }
      & > input:after {
        content: "off";
        bottom: 2px;
        color: $gray-700;
        left: 52%;
        position: absolute;
        right: 0;
      }
    }
  }

  &.disabled {
    opacity: 0.7;
    .form-switch {
      cursor: not-allowed;
    }
  }
}
.switch-preveil-disabled {
  & > input[disabled] {
    cursor: not-allowed;
    pointer-events: all;
  }
}

.form-check-input:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}

.form-control {
  &.is-invalid {
    background-image: none;
    border: 1px solid $pv-red;
    padding-right: 12px;
  }
}

.input-placeholder {
  opacity: 0.5;
}

// navs
.tab-content {
  background-color: $white;
  border: solid 1px $gray-400;
  border-radius: 0 $border-radius $border-radius $border-radius;
  padding: 2rem 1.5rem;
  // @include media-breakpoint-down(sm) {
  //   border-radius: 0 0 $border-radius $border-radius;
  // }
}
.nav-tabs {
  border-bottom-width: 0px;
  margin: 0 5px 0 0;
  z-index: 10;
  .nav-link {
    padding: 0.5rem 2.5rem;
    color: black;
    font-size: $font-size-md;
  }
}

.nav-item,
.nav-link {
  &:focus-visible {
    outline: none !important;
    box-shadow: none;
  }
}

// pagination
.pagination {
  [class^="ficon-"] {
    font-size: 1rem;
  }
  .page-item {
    margin-right: 0.25rem;
    .page-link {
      border-radius: $border-radius;
    }
    &.active > .page-link {
      font-weight: 700;
    }
    &.bordered > .page-link {
      border: solid 1px $active-color-light;
    }
    &.disabled {
      .page-link {
        cursor: not-allowed;
      }
    }
  }
}
// Cards
.card {
  min-height: 100px;
  box-shadow: $box-shadow-sm;
  .card-body {
    padding: 1.5rem 2rem;
  }
  .card-header {
    background-color: transparent;
    border: none;
  }
}
.card-group {
  .card {
    box-shadow: none;
  }
}
// Modals
@include media-breakpoint-down(lg) {
  .modal-lg {
    // Adjust the large modals for responsive
    max-width: 96%;
  }
}
.modal-footer {
  justify-content: flex-start;
  padding-top: 0;
}
.modal-header {
  padding: 0.75rem 0;
  margin: 0 1rem;
}

// Toasts
.toast {
  border-left: solid 5px;
  margin-top: 75px;
  max-width: 95vw;
  .toast-header {
    background-color: transparent;
  }
  .toast-body,
  .toast-header {
    color: $toast-color;
    padding: 0.625rem 2.25rem 0.625rem 1rem;
    position: relative;
    width: 100%;
    strong {
      margin-right: 0.5rem;
    }
    .btn-close {
      position: absolute;
      right: 5px;
      top: 10px;
      &:hover,
      &:focus {
        background-color: transparent;
        opacity: 0.8;
      }
    }
  }
  &.bg-success {
    border-left-color: $pv-green-dark;
    // background: lighten($pv-green, 35%) !important; - deprecated
    background: color.adjust($pv-green, $lightness: 35%) !important;
  }
  &.bg-danger {
    // border-color: lighten($pv-red, 20%);  - deprecated
    border-color: color.adjust($pv-red, $lightness: 20%);
    // background: lighten($pv-red, 50%) !important;  - deprecated
    background: color.adjust($pv-red, $lightness: 50%) !important;
  }
  &.bg-warning {
    // border-left-color: lighten($pv-yellow, 10%);  - deprecated
    border-left-color: color.adjust($pv-yellow, $lightness: 10%);
    // background: lighten($pv-yellow, 30%) !important;  - deprecated
    background: color.adjust($pv-yellow, $lightness: 30%) !important;
  }
  &.bg-info {
    // border-left-color: lighten($pv-blue, 10%); - deprecated
    border-left-color: color.adjust($pv-blue, $lightness: 10%);
    // background: lighten($pv-blue, 50%) !important; - deprecated
    background: color.adjust($pv-blue, $lightness: 50%) !important;
  }
  &.bg-primary {
    // border-left-color: lighten($pv-orange, 10%); - deprecated
    border-left-color: color.adjust($pv-orange, $lightness: 10%);
    // background: lighten($pv-orange, 35%) !important; - deprecated
    background: color.adjust($pv-orange, $lightness: 35%) !important;
  }
  &.bg-light {
    // Used for copy & paste
    // border-color: lighten($pv-green-dark, 10%); - deprecated
    border-color: color.adjust($pv-green-dark, $lightness: 10%);
    background: $white !important;
    > .toast-header,
    > .toast-body {
      padding: 0.25rem 1.5rem 0.25rem 0.5rem;
      font-size: $font-size-sm;
      .btn-close {
        font-size: 0.65rem;
        right: 4px;
        top: 7px;
      }
    }
  }
  &.offline {
    margin-top: $header-height;
    .toast-body {
      padding: 0.5rem 0.5rem 0.25rem;
      font-size: $font-size-sm;
    }
  }
}

// Custom styles for list groups:
.list-group {
  &.item-mb li {
    margin-bottom: 1rem;
  }
}

// Popovers
p.popover-body {
  margin-bottom: 0;
}

// For dirty warning - before unload toastr for longer calls
.dirty-warning {
  max-width: $left-nav-width;
  .toast {
    margin-top: 0;
    .toast-header {
      font-size: $font-size-sm;
      font-weight: 700;
      padding-bottom: 0px;
      .btn-close {
        right: 15px;
      }
    }
    .toast-body {
      font-size: $font-size-sm;
      line-height: 1.2em;
    }
  }
}

// Dropdowns
a.dropdown-item {
  outline: 0;
}
