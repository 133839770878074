/*
* File:             _right-sidebar.scss
* Description:      This files holds styles for the right panel for user notifications and previews
* Author:           PreVeil
*/
.offcanvas.offcanvas-end {
  &.sidebar {
    border-left: $light-border;
    box-shadow: $box-shadow;
    top: 60px;
    width: 100%;
    .title {
      border-bottom: $light-border;
      padding: 20px;
      vertical-align: bottom;
      > .icon {
        color: $pv-blue;
        font-size: 28px;
      }
    }
    .close {
      margin: 10px 5px;
    }
    .offcanvas-header {
      border-bottom: $light-border;
      padding: 0.5rem 1rem;
    }
    &.show {
      .sidebar-panel {
        transform: translateX(0);
        overflow-y: auto;
      }
      .sidebar-bg {
        display: block;
      }
    }

    // Sidebar Content
    .offcanvas-body {
      overflow-x: hidden;
      .item {
        border-bottom: $light-border;
        padding: 1rem 1rem 0.75rem 3.125rem;
        position: relative;
        p {
          margin-bottom: 4px;
        }

        .icon-left {
          color: $pv-blue-bright;
          font-size: 40px;
          left: -4px;
          position: absolute;
          top: 5px;
          &:not(.icon-folder-shared) {
            color: $pv-light-blue;
          }
        }

        .icon-right {
          color: $pv-icon;
          font-size: 28px;
          position: absolute;
          top: 5px;
          right: 0;
          &.icon-reply-all {
            transform: scaleX(-1);
          }
          &[class^="e-icon-"] {
            right: 8px;
            transform: scale(0.9);
          }
        }
      }
    }
  }

  // Responsive sizing
  @include media-breakpoint-up(md) {
    max-width: 400px;
    &.wide-panel,
    &.drive-details {
      max-width: 800px;
    }
    &.narrower-panel {
      max-width: 600px;
    }
  }
}
