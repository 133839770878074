/*
*  File:            _images.scss
*  Description:     This files holds the styles for certain images
*  Author:          PreVeil
*/

//admin

.gray-circle-background {
  background-color: $pv-lightgray;
  border-radius: 105px;
  height: 205px;
  margin: 0 auto;
  padding-top: 13px;
  width: 205px;
}

.approval-group-image {
  background: url("/assets/images/recovery_group.svg") no-repeat center;
  height: 165px;
  margin-bottom: 20px;
  width: auto;
}

.trusted-community {
  background: url("/assets/images/trusted-community.svg") no-repeat center;
  height: 190px;
  width: auto;
}

.email-gateway {
  background: url("/assets/images/email-gateway.svg") no-repeat center;
  margin-bottom: 8px;
  height: 150px;
  width: auto;
}

.cloud-lock-image {
  background: url("/assets/images/cloud_lock.svg") no-repeat center;
  height: 300px;
  width: auto;
}

// public
.create-new-account-image {
  background: url("/assets/images/create_new_account.svg") no-repeat center center;
  height: 150px;
}
.copy-account-image {
  background: url("/assets/images/copy_account.svg") no-repeat center center;
  height: 150px;
}
.recovery-group-image {
  background: url("/assets/images/recovery_group.svg") no-repeat center center;
  height: 135px;
  margin-top: 7px;
}
.upgrade-account-image {
  background: url("/assets/images/upgrade_express_account.svg") no-repeat center;
  height: 150px;
}

.settings-image {
  background: url("/assets/images/settings.svg") no-repeat center;
  border: none;
}

// Two factor Authentication images
.pick-sms-2fa-image {
  background: url("/assets/images/SMS-2fa.svg") no-repeat center top;
  background-size: auto 110%;
  height: 100%;
}
.pick-totp-2fa-image {
  background: url("/assets/images/TOTP-2fa.svg") no-repeat center 5px;
  background-size: auto 100%;
  height: 100%;
}
