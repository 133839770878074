/*
* File:             _forms.scss
* Description:      This files holds styles for forms and accessory controls
* Author:           PreVeil
*/

form {
  .form-text {
    display: inline-block;
    line-height: $line-height-xs;
  }
}

// make label invisible to screens but show to screenreaders
.form-check.sr-only {
  label {
    @extend .visually-hidden;
  }
}
.form-check-input[type="checkbox"]:indeterminate {
  border-width: 2px;
}

// Additional components for forms
.btn-panel {
  .btn:not(:last-child) {
    margin-right: 0.625rem;
  }
}

.pv-switch-position {
  position: absolute;
  right: 20px;
}

.error {
  color: $pv-red;
  font-size: 0.75em;
  height: 1em;
  padding: 0.2em 0.2em;
}

.error-message {
  @extend .error;
  position: absolute;
}

.required {
  color: $pv-red;
}

.gateway {
  .form-control {
    border: none;
    border-bottom: 1px solid $gray-400;
    &:focus {
      box-shadow: none;
    }
  }
}

.input-group {
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  &:focus-within {
    box-shadow: none; //0 1px 10px 0 rgb(0, 0, 0, 0.25);
  }
  .form-control {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  &.input-group-sm {
    border-radius: $border-radius-sm;
  }
}

// icons for input form controls
.input-icon-prepend {
  position: relative;
  .form-control {
    padding-left: 30px;
  }
  > i {
    color: $pv-icon;
    font-size: 1.125rem;
    opacity: 0.5;
    position: absolute;
    left: 7px;
    top: 8px;
  }
}

.input-icon-append {
  align-items: center;
  background: $white;
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  color: $pv-muted-label;
  display: flex;
  font-size: 0.8rem;
  height: 100%;
  padding: 0.3rem 0 0.3rem 0.4rem;
  position: relative;
  > i {
    color: $pv-icon;
    font-size: 1.125rem;
    opacity: 0.5;
    position: absolute;
    right: 5px;
    top: 12px;
  }
  .form-control {
    padding-right: 30px;
  }
}

.status-icon-append {
  align-items: center;
  background: $white;
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  color: $pv-muted-label;
  display: flex;
  font-size: 0.9rem;
  height: 100%;
  padding: 0.3rem 0 0.3rem 0.4rem;
  position: relative;
  > i {
    opacity: 1;
    position: absolute;
    right: 17px;
    top: 9px;
  }
}

.password-form-control {
  &.invalid {
    border: 1px solid $pv-red;
  }
  .form-control {
    padding-left: 0;
  }
  > i {
    color: $pv-icon;
    font-size: 1.125rem;
    left: 7px;
    opacity: 0.5;
    padding-right: 15px;
    top: 8px;
  }
}

// Number inputs no arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

// Shared verification code widget
.verification-code {
  .form-control {
    display: inline-block;
    margin-right: 5px;
    max-width: 40px;
    text-align: center;
    &.form-control-lg {
      max-width: 50px;
    }
  }
  // Responsive left nav - mobile view <= 768px
  @include media-breakpoint-down(sm) {
    .form-control.form-control-lg {
      font-size: 1rem;
      max-width: 40px;
      padding: 0.25rem;
    }
  }
}

// Backup recovery textarea
.backup-ta {
  height: 200px;
  padding: 0.75rem;
}

.inline-form-table {
  align-items: center;
  margin-right: auto;
  max-width: 600px;
}
