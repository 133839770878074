/*
* File:             _date-picker.scss
* Description:      This files holds styles for react-datepicker and date picker related classes
* Author:           PreVeil
*/

.logs {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .react-datepicker__navigation {
    color: $gray-600;
    top: 0.5rem;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
      .react-datepicker__current-month {
        font-weight: normal;
        font-size: $font-size-sm;
      }
      .react-datepicker__day-names {
        font-size: $font-size-sm;
      }
    }
    .react-datepicker__month {
      margin-top: 0;
      .react-datepicker__week {
        .react-datepicker__day {
          font-size: $font-size-sm;
          &.react-datepicker__day--in-range,
          &.react-datepicker__day--in-selecting-range,
          &:hover:not(.react-datepicker__day--disabled) {
            background-color: $gray-100;
            border-radius: 0;
            color: $black;
          }
          &.react-datepicker__day--range-end,
          &.react-datepicker__day--range-start,
          &.react-datepicker__day--selected {
            background-color: $pv-blue;
            border-radius: 0;
            color: $white;
            &:hover {
              background-color: $pv-blue;
              color: $white;
            }
          }
          &.react-datepicker__day--keyboard-selected {
            background-color: transparent;
            border-radius: 0;
            color: $black;
          }
          &.react-datepicker__day--outside-month {
            color: $gray-300;
          }
          &.react-datepicker__day--range-start {
            border-radius: 4px 0 0 4px;
          }
          &.react-datepicker__day--range-end {
            border-radius: 0 4px 4px 0;
          }
          &.react-datepicker__day--disabled {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.date-picker-dropdown {
  padding: 0;
  .dropdown-toggle {
    border: none;
  }
  .dropdown-menu {
    .ranges {
      border: none;
      padding: 0;
      min-width: fit-content;
      &.right-border {
        border-right: 1px solid $pv-icon-opaque;
      }
      .dropdown-item {
        &.buttons {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        &.dropdown-item:not(:last-child) {
          border-bottom: 1px solid $pv-icon-opaque;
          padding: 0.5rem;
          &.active {
            color: $black;
            &:hover {
              background-color: $active-bg;
            }
          }
          &:focus {
            color: $black;
          }
        }
        &.dropdown-item:last-child {
          &:hover,
          &:active {
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
        }
        padding: 1rem;
      }
    }
    .date-picker-input:first-child {
      margin-right: 1rem;
    }
    .selector {
      padding-top: 12px;
    }
    @include media-breakpoint-down(xl) {
      display: block;
      .ranges {
        border-right: none;
        width: 100%;
        &.right-border {
          border-right: none;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      display: block;
      .react-datepicker,
      .date-picker-inputs {
        display: block;
        .date-picker-input {
          margin-right: 0;
        }
      }
      .selector {
        padding: 0;
      }
    }
  }
}

.date-picker-inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date-picker-input {
  align-items: center;
  border: 1px solid $gray-400;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  line-height: 2;
  &.main-picker {
    // width: 30%;
    margin-bottom: 0.5rem;
    max-width: 70vh;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container,
    .input {
      border: none;
      color: $gray-700;
      width: 100%;
      &:active,
      &:focus,
      &:hover,
      &:visited,
      &:focus-visible {
        border: none;
        border-radius: 0;
        outline: none;
      }
    }
  }
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within {
    border-radius: 0;
    box-shadow: 0 1px 10px 0 rgb(0, 0, 0, 0.25);
    outline: none;
  }
  @include media-breakpoint-down(sm) {
    &.main-picker {
      width: 100%;
    }
  }
}

.date-picker-menu::before {
  border-bottom: 7px solid $pv-icon-opaque;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  left: 9px;
  position: absolute;
  top: -7px;
}

.date-picker-menu::after {
  border-bottom: 6px solid $white;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: -6px;
}

// Drive expiration date picker
// -----------------------------------------------------
.expiration-input {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  max-width: 200px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container,
  .input {
    border: none;
    color: $gray-700;
    max-width: 300px;
    width: 100%;
    &:active,
    &:focus,
    &:hover,
    &:visited,
    &:focus-visible {
      border: none;
      border-radius: 0;
      outline: none;
    }
  }
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within {
    border-radius: 0;
    box-shadow: 0 1px 10px 0 rgb(0, 0, 0, 0.25);
    outline: none;
  }
  .drive-expiration {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__navigation {
      color: $white;
      top: 1rem;
    }
    .react-datepicker__navigation-icon::before {
      border-color: $white;
    }
    .react-datepicker__month-container {
      border-radius: $border-radius-sm;
      box-shadow: $box-shadow-lg;
      .react-datepicker__header {
        border-bottom: none;
        .react-datepicker__current-month {
          background-color: $pv-orange;
          border-radius: $border-radius-sm $border-radius-sm 0 0;
          color: $white;
          font-family: $theme-body-font;
          font-weight: normal;
          padding: 0.5rem 0;
        }
        .react-datepicker__day-names {
          background-color: $white;
          .react-datepicker__day-name {
            color: $gray-500;
            font-size: $font-size-sm;
          }
        }
      }
      .react-datepicker__month {
        margin-top: 0;
        .react-datepicker__week {
          .react-datepicker__day {
            color: $active-color;
            font-size: $font-size-sm;
            &.react-datepicker__day--keyboard-selected {
              background-color: transparent;
              border-radius: 0;
              color: $black;
            }
            &.react-datepicker__day--selected {
              background-color: $pv-orange;
              // background-color: $pv-blue;
              border-radius: 0;
              color: $white;
              &:hover {
                background-color: $pv-blue;
                color: $white;
              }
            }
            &.react-datepicker__day--disabled {
              color: $gray-500;
            }
            &.react-datepicker__day--outside-month {
              color: $gray-300;
            }
          }
        }
      }
    }
  }
}
.expiration-error {
  color: $pv-red;
  display: block;
}
