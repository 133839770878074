/*
 *   File:           _top-navigation.module.scss
 *   Description:    This files holds style overrides for Top navigation and Main Header
 *    Author:        PreVeil
 */

#preveil-header {
  .navbar {
    background-color: $navbar-bg;
    background-image: url("/assets/images/logos/top_header_bg.png");
    background-repeat: no-repeat;
    background-position: left top;
    min-height: $header-height;
    > .container-fluid {
      padding: 0;
    }
    // Logo
    .navbar-brand {
      background-image: url("/assets/images/logos/top_header_bg.png");
      background-repeat: no-repeat;
      background-position: left top;
    }
    // All links
    a {
      color: $navbar-color;
      &.active {
        color: $navbar-active-color;
      }
    }
    [class^="#{$pv-icon-css-prefix}-"] {
      font-size: 2.65rem;
    }
    [class^="#{$fi-css-prefix}-"] {
      padding: 0.5rem;
    }

    // Top level links with icons: notifications, support, settings, admin
    .nav-item,
    .nav-link {
      &:hover,
      &:focus {
        background-color: $navbar-bg-hover;
        > a {
          color: $pv-lightgray;
        }
      }
      &.show {
        background-color: $navbar-bg-hover;
        > .dropdown-toggle {
          color: $pv-lightgray;
        }
      }
      &:active,
      &.active {
        > .dropdown-toggle {
          color: $header-active-color;
        }
      }
      // Drive Icon
      .ficon-repeat {
        transform: rotate(90deg) scale(0.75);
        &:after {
          left: -8px;
          top: -8px;
        }
      }
    }
    .dropdown-toggle {
      cursor: pointer;
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      background-color: $navbar-dropdown-bg;
      border: 0 none;
      font-family: $theme-navigation-font;
      line-height: $line-height-sm;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
      a {
        color: $navbar-dropdown-color;
        small {
          display: block;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: $navbar-bg-hover;
        }
      }
      .dropdown-item {
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        text-align: right;
        &:first-child {
          padding-top: 0.75rem;
        }
        &:last-child {
          padding-bottom: 0.75rem;
        }
        &.active {
          // background-color: lighten($navbar-bg, 10%); -deprecated
          background-color: color.adjust($navbar-bg, $lightness: 10%);
        }
        i {
          font-size: 1em;
          margin: 0 5px 0 0;
          padding: 0;
          &[class^="#{$pv-icon-css-prefix}-"] {
            font-size: 1.6875rem;
            margin: 0 5px 0 0;
          }
          // Star for whats new
          &.featured {
            color: $pv-yellow;
            font-size: 1.4rem;
            margin-top: -10px;
            margin: -10px 0 0;
          }
        }
      }
      .dropdown-divider {
        border-top-color: $white;
        margin: 0.5rem 1rem;
        opacity: 0.5;
      }
    }

    // Account Dropdown menu
    .account-menu {
      .dropdown-menu {
        max-height: 350px;
        min-width: 200px;
        overflow: auto;
        padding: 0;
        .dropdown-item {
          border-bottom: solid 1px rgba(237, 238, 240, 0.5);
          line-height: 1.1rem;
          padding: 0.75rem 1.5625rem;
          &:last-child {
            border-bottom: 0 none;
          }
        }
      }
    }

    // User Nav: mail, drive, chat
    .user-nav {
      font-family: $theme-headline-font;
      line-height: $header-height;
      margin-right: auto;
      a {
        color: $navbar-color;
        display: inline-block;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: $header-height;
        padding: 0 1.25rem;
        &.active {
          color: $header-active-color;
          position: relative;
          &:after {
            @include pv-caret-up(0.5rem);
            bottom: 0;
            content: "";
            color: $body-bg-base;
            display: block;
            left: 50%;
            margin-left: -0.5rem;
            position: absolute;
          }
        }
        &:hover {
          background-color: $navbar-bg-hover;
          color: $pv-lightgray;
        }
      }
      span {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    // Alert dot
    .dot {
      position: relative;
      &:after {
        @extend .badge-mark;
        background-color: $pv-red-bright;
        border-color: $pv-red-bright;
        content: "";
        position: absolute;
        right: 10px;
        top: 10px;
      }
      &.nav-link:after,
      &.dropdown:after {
        right: 10px;
        top: 15px;
      }
      &.disabled {
        opacity: 0.5;
        &:after {
          display: none;
        }
      }
    }

    // Responsive top nav - mobile view <= 768px
    @include media-breakpoint-down(md) {
      max-height: 100vh;
      overflow: auto;
      .navbar-nav {
        font-family: $theme-navigation-font;
        font-size: 1.125rem;
        padding: 0.5rem 0 0;
      }
      .navbar-brand {
        width: 220px;
      }
      .show-desktop {
        display: none;
      }
      .navbar-collapse {
        a {
          color: $white;
        }
        .nav-item,
        .nav-link {
          padding: 0.5rem;
          text-align: right;
        }
        .dropdown-menu {
          background-color: transparent;
          .dropdown-divider {
            display: none;
          }
        }
        .account-menu .dropdown-menu {
          max-height: none;
          overflow: visible;
          .dropdown-item {
            border-bottom: 0 none;
          }
        }
      }
      .mobile-user-nav {
        display: flex;
        flex-direction: row;
        padding: 0;
        a {
          display: block;
          line-height: $header-height;
          padding: 0 0.5rem;
          &:hover,
          &:focus,
          &:active {
            color: $white;
          }
        }
      }
      .dot.dropdown:after {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      .navbar-brand {
        width: 150px;
      }
      .mobile-user-nav {
        margin-left: auto;
        a {
          padding: 0 0.25rem;
        }
      }
      .navbar-toggler {
        padding: 0.5rem 0.25rem;
      }
    }
  }

  // Change icon for toggler
  .navbar-toggler {
    border-radius: 0;
    height: $header-height;
    padding: 0.5rem;
    > .navbar-toggler-icon {
      background-image: none;
      @extend .icon-grid2;
      font-family: $icon-font-family;
      font-size: 1.5rem;
      height: auto;
    }
    &.collapsed {
      > .navbar-toggler-icon {
        color: $navbar-color;
      }
    }
  }

  // Responsive header - Desktop View < 767px
  @include media-breakpoint-up(md) {
    #top-navbar {
      height: $header-height;
    }
    .user-nav {
      margin-left: 1rem;
    }
    .navbar-brand {
      width: $left-nav-width - 12px;
    }
    // Top level links on the right side: notifications, support, settings, admin
    .nav-item,
    .nav-link {
      line-height: $header-height;
      padding: 0 0.5rem;
      &.admin {
        margin-left: -2px; //covers the divider on active
      }
    }
    .divider {
      @include divider($pv-navbar-blue-light, 1rem 0, 0.5, 2px);
    }
    .mobile-user-nav {
      display: none;
    }
  }
}
