/*
* File:             _list-filters.scss
* Description:      This files holds styles for list filters. 
*                   Including: filter by date range and users (activity logs, watched, data export and more)
* Author:           PreVeil
*/

.list-filters {
    padding: 0;
    .form-control {
        padding: 0.25rem;
    }
    // User Filter
    .user-autocomplete {
        .form-control {
            padding-left: 0.5rem;
        }
        .autocomplete-results {
            min-width: 300px;
        }
    }
    .user-badges {
        display: flex;
        flex-wrap: wrap;
        margin: 0.5rem 0;
    }
    // Responsive control - mobile view < 768px
    @include media-breakpoint-down(md) {
        .user-autocomplete {
            margin-bottom: 0.5rem;
        }
    }

    // Date picker and date filters
    .dropdown {
        .input-group {
            border-radius: $border-radius;

            .input-group-text {
                background: $white;
                border: solid 1px $white;
                color: $text-muted;
                max-height: 32px;
                padding: 0;
                > i {
                    font-size: 2rem;
                }
            }
            .react-datepicker-wrapper {
                flex-grow: 1;
                margin-right: 1px;
            }
            @include media-breakpoint-up(md) {
                max-width: 500px;
            }
        }
    }

    // Dropdown component
    .dropdown-menu {
        font-size: 1rem;
        min-width: 200px;
        .dropdown-item {
            padding: 0.375rem 0.5rem;
        }
        // Triangle on top of menu
        &:before {
            border-bottom: 7px solid $pv-icon-opaque;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            content: "";
            display: inline-block;
            left: 9px;
            position: absolute;
            top: -7px;
        }
        &::after {
            border-bottom: solid 6px #fff;
            border-left: solid 6px transparent;
            border-right: solid 6px transparent;
            content: "";
            display: inline-block;
            left: 10px;
            position: absolute;
            top: -6px;
        }

        // custom date selector
        .date-selector {
            min-width: 480px;
            .date-picker-input:first-child {
                margin-right: 1rem;
            }
        }
        .btn-panel {
            border-top: $light-border;
            padding: 0.5rem;
            margin: 0.5rem 0.5rem 0 0.5rem;
        }

        // Responsive control - mobile view < 768px
        @include media-breakpoint-down(lg) {
            right: -10px;
            &:before {
                left: 179px;
                position: absolute;
                top: -7px;
            }
            &::after {
                left: 180px;
                position: absolute;
                top: -6px;
            }
        }
    }
}
