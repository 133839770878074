/*
* File:           _typography.scss
* Description:    This files holds styles for global typography.
* Author:         PreVeil
*/

html {
  font-family: $theme-body-font;
  font-size: $theme-font-size-default;
}

code {
  background-color: $gray-200;
  border-radius: $border-radius-lg;
  display: block;
  padding: 1rem;
  margin: 0.5rem;
}

// Custom scrollbar
::-webkit-scrollbar-track {
  background-color: white;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  background-color: white;
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

// NOTE: This is a shortcut for removing the React error overlay in development mode
// will need to be removed for future iframe usage
body > iframe {
  display: none;
}

.bold {
  @extend .fw-bold;
}

.semi-bold {
  font-weight: 500;
}

.sub-text {
  font-size: $font-size-xs;
}

input::placeholder {
  font-size: 0.9em;
}

.text-orange {
  color: $pv-orange !important;
}

.text-success {
  color: $pv-green-dark !important;
}

.text-info {
  color: $text-info !important;
}

.text-link {
  color: $link-color !important;
}

.text-muted-light {
  color: $text-muted-light !important;
}

.text-dark {
  color: $body-color;
}

.no-wrap {
  white-space: nowrap !important;
}

.disclaimer {
  color: $text-muted;
  font-style: italic;
  font-size: $font-size-sm;
}

// bordered titlesf
h1,
h2,
h3,
h4,
h5,
h6 {
  &.border-bottom {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

// Tooltips
.react-tooltip {
  font-size: $font-size-sm;
  max-width: 400px;
  padding-top: 0.35rem;
  z-index: 9000;
}
