/*
* File:             _breadcrumbs.scss
* Description:      This files holds styles for breadcrumbs
* Author:           PreVeil
*/

// Fix the divider globally to support chevrons
.breadcrumb {
  .breadcrumb-item {
    &:before {
      font-family: $feather-font-family;
    }
  }
}
.drive-breadcrumbs {
  .breadcrumb {
    align-items: flex-end;
  }
  .breadcrumb-item:not(.active),
  a {
    color: $breadcrumb-color;
    &:hover {
      color: $breadcrumb-hover-color;
    }
  }
  .breadcrumb-item {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
    &.active {
      font-weight: 600;
      &:before {
        font-weight: 400;
      }
    }
    &.h1 {
      margin-bottom: 0;
    }
    &.effective_root {
      color: $text-muted;
      cursor: not-allowed;
      font-weight: 300;
      opacity: 0.7;
      > a {
        cursor: not-allowed;
      }
    }
  }
}
