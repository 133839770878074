/*
* File:             _drive-icons.scss
* Description:      This files holds styles for custom drive icons - image based
* Author:           PreVeil
*/

// Drive Custom Image Icons
// -------------------------
[class^="#{$fs-icon-css-prefix}-"],
[class*=" #{$fs-icon-css-prefix}-"] {
  background-repeat: no-repeat;
  background-size: 100%;
  border: solid 6px transparent;
  display: inline-block;
  font-size: $fs-icon-font-size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  height: $fs-icon-size;
  line-height: 1;
  position: relative;
  speak-as: none;
  text-transform: none;
  text-align: center;
  top: -1px;
  vertical-align: middle;
  width: $fs-icon-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.sm {
    border-width: 2px;
    height: $fs-icon-size-sm;
    width: $fs-icon-size-sm;
  }

  &.xlg {
    border-width: 4px;
    height: $fs-icon-size-xlg;
    width: $fs-icon-size-xlg;
  }
}

// Active Drive Icons (blue)
// folders:
// NOTE: Default regular unsynced folder
.fsi-folder {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-folder.svg");
}

.fsi-unsynced-partial {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-exception-folder.svg");
}

.fsi-synced {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-synced-folder.svg");
}

.fsi-synced-partial {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-exception-synced-folder.svg");
}

// Links (shared folders)
// NOTE: Default unsynced
.fsi-shared {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-shared-folder.svg");
}

.fsi-shared-unsynced-partial {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-shared-exception-folder.svg");
}

.fsi-shared-synced {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-shared-synced-folder.svg");
}

.fsi-shared-synced-partial {
  background-image: url("#{$icon-img-path}/#{$fs-icon-blue}/sm-exception-synced-shared-folder.svg");
}

.fsi-pending {
  background-image: url("#{$icon-img-path}/#{$fs-icon-grey}/sm-pending-folder.svg");
}

.fsi-shared-pending {
  background-image: url("#{$icon-img-path}/#{$fs-icon-grey}/sm-pending-shared-folder.svg");
}

// Drive syncing icons
.fsi-unsynced {
  background-image: url("#{$icon-img-path}/no-local-sync.svg");
  background-size: 18px;
  background-position: center;
  border-width: 2px;
  height: $fs-icon-size-sm;
  margin-right: 0.5rem;
  opacity: 0.5;
  width: $fs-icon-size-sm;
}
