/* ------------------------------------------------------------------------------
 *
 *  # Icomoon icons base
 *
 *  Base styles for Icomoon. Include font import, class definition and other options
 *
 * ---------------------------------------------------------------------------- */

// Font definition
@font-face {
    font-family: "icomoon";
    src: url("#{$im-font-path}/icomoon.eot?3p0rtw");
    src:
        url("#{$im-font-path}/icomoon.eot?#iefix3p0rtw") format("embedded-opentype"),
        url("#{$im-font-path}/icomoon.woff?3p0rtw") format("woff"),
        url("#{$im-font-path}/icomoon.ttf?3p0rtw") format("truetype"),
        url("#{$im-font-path}/icomoon.svg?3p0rtw#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

// Base Class Definition
[class^="#{$im-css-prefix}-"],
[class*=" #{$im-css-prefix}-"] {
    /* Use !important to prevent issues with browser extensions that change fonts */
    color: $text-muted;
    display: inline-block;
    font-family: "icomoon" !important;
    font-size: $icon-font-size;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    min-width: 1em;
    position: relative;
    speak-as: none;
    text-transform: none;
    text-align: center;
    top: -1px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Icon Sizes. 4 additional sizes: .im-2x -> .im-5x
@for $i from 1 through 5 {
    .#{$im-css-prefix}-#{$i}x {
        font-size: $i * $icon-font-size;
    }
}

//
// Rotated & Flipped Icons
//

// Rotation
.#{$im-css-prefix}-rotate-90 {
    @include im-icon-rotate(90deg, 1);
}
.#{$im-css-prefix}-rotate-180 {
    @include im-icon-rotate(180deg, 2);
}
.#{$im-css-prefix}-rotate-270 {
    @include im-icon-rotate(270deg, 3);
}

// Flip
.#{$im-css-prefix}-flip-horizontal {
    @include im-icon-flip(-1, 1, 0);
}
.#{$im-css-prefix}-flip-vertical {
    @include im-icon-flip(1, -1, 2);
}
.#{$im-css-prefix}-flip-horizontal.#{$im-css-prefix}-flip-vertical {
    @include im-icon-flip(-1, -1, 2);
}
