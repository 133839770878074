/*
* File:             _file-viewer.scss
* Description:      This files holds styles for file viewer
* Author:           PreVeil
*/

.file-viewer {
  .modal-dialog {
    .modal-content {
      height: 90%;
      &.fullscreen {
        height: 100%;
      }
      .modal-header {
        background-color: $pv-blue-dark;
        border: 0;
        color: $white;
        margin: 0;
        padding-bottom: 0;
        .maximize {
          color: $white;
          opacity: 0.5;
          i {
            font-size: 1.2rem;
          }
          &:hover {
            opacity: 1;
          }
        }
        .btn-close {
          margin: 0;
        }
      }
      .modal-body {
        background-color: $pv-blue-dark;
        padding-top: 12px;
        .viewer {
          height: 100%;
        }
      }
      @include media-breakpoint-down(xs) {
        height: 100%;
      }
    }
    // Responsive sizing
    @include media-breakpoint-down(xl) {
      max-width: 96%;
      &.fullscreen {
        max-width: 100%;
      }
    }
  }
}

#doc-container {
  width: 100%;
  height: 100%;
  &.text {
    background-color: $white;
    font-size: 16px;
    line-height: 25px;
    overflow-y: auto;
    padding: 50px;
  }
  &.image {
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto;
    overflow-x: hidden;
    &.large-image {
      background-size: contain;
    }
  }
}

#pdf-marking {
  color: $text-inverse;
  font-size: 10.5px;
  opacity: 0.6;
  text-align: center;
}

#doc-outer-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
