/*
* File:             _split-pane.scss
* Description:      This files holds styles for split pane
* Author:           PreVeil
*/

.split-view {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  width: 100%;
  .divider {
    border-left: solid 1px $gray-200;
    align-items: center;
    align-self: stretch;
    cursor: col-resize;
    display: flex;
    padding-right: 0.25rem;
    &.disabled {
      cursor: none;
    }
  }
  > .right-pane {
    // Note: this must stay hidden for proper cover functionality
    overflow-y: hidden;
  }
  .right-pane {
    flex: 1;
    overflow-x: auto;
    > .loading {
      margin-left: 3rem;
    }
  }
  .left-pane {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 12px;
  }
  // For toggling panel transitions
  &:not(.dragging) {
    > .right-pane,
    > .left-pane {
      transition: width 0.2s cubic-bezier(0.2, 0, 0, 1);
    }
  }
  // Add closed-left or closed-right classes to close either side
  .cover-content .off {
    > .divider {
      display: none;
    }
    > .right-pane {
      display: none;
      width: 0;
    }
  }
  &.off .left-pane {
    padding-right: 0;
    overflow: hidden; // Hide overflow when closed
    .mail-tree {
      display: none;
    }
    hr {
      margin-right: 1rem;
    }
  }
  &.on .feedback {
    .ficon-message-square {
      display: none;
    }
  }
  // Responsive mobile view <= 768px
  @include media-breakpoint-down(md) {
    // Main Split panel for Left Navigation
    &.wrapper-panels {
      &.on {
        & > .left-pane {
          width: 100% !important;
        }
        > .divider {
          display: none;
        }
        .content-wrapper {
          overflow: hidden;
          padding: 1rem 0 2rem;
          .toolbar {
            display: none;
          }
        }
      }
      &.off {
        & > .left-pane {
          width: 52px !important;
        }
        & > .right-pane {
          > .loading {
            margin-left: -($loading-logo-width * 0.5);
          }
        }
        // Responsive  to handle new desing - mobile view <= 768px
        // @include media-breakpoint-down(md) {
        & > .left-pane {
          width: 0 !important;
          * {
            display: none;
          }
        }
        > .divider {
          display: none;
        }
        // }
      }
    }
  }
}
