/*
 *   File:           _phone-component.scss
 *   Description:    This files holds style for phone component - Create Express Account Pages - Phone control
 *   Author:         PreVeil, LLC
 */

//
.phone-group {
  .phone-code {
    font-size: $font-size-md;
  }
}

.phone-react-select {
  width: 110px;
  font-size: $font-size-sm;
  [class*="control"] {
    border: solid 1px $gray-400;
    border-radius: 0;
    box-shadow: none;
    &:hover,
    &:active {
      border: solid 1px $gray-400;
      box-shadow: none;
    }
    [class*="ValueContainer"] {
      padding-right: 0;
    }
    [class*="IndicatorsContainer"] {
      [class*="indicatorContainer"] {
        padding: 0;
        padding-right: 0.5rem;
      }
    }
  }
  [class*="menu"] {
    margin: 0;
  }
}

// New custom Select for country dropdowns
// For CSP compliance remove the input styles
.countries a,
.country-option,
.country-mask {
  img {
    width: 25px;
  }
  span {
    display: inline-block;
    line-height: 19px;
    margin-left: 0.375rem;
    vertical-align: middle;
  }
}
.countries {
  max-width: 100px;
  position: relative;
  .dropdown-toggle {
    height: 40px;
    width: 100px;
    &:after {
      display: none;
    }
    .country-mask {
      display: block;
      min-height: 40px;
      padding: 7px 0 0 5px;
      width: 100px;
    }
    .input-icon-append {
      background-color: $white;
      border: $light-border;
      border-radius: $border-radius;
      height: 100%;
      padding: 0;
      position: absolute;
      top: 0;
      z-index: 2;
      i {
        top: 10px;
      }
      input {
        background-color: transparent;
        width: 100px;
      }
    }
    &.masked {
      .input-icon-append {
        background-color: transparent;
        input {
          opacity: 0;
        }
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    margin-top: -2px;
    max-height: 250px;
    min-width: auto;
    overflow-y: scroll;
    width: 100px;
    .dropdown-item {
      text-align: center;
    }
  }
}
