/*
*   File:           _buttons.scss
*   Description:    This files holds global styles for PreVeil buttons
*   Author:         PreVeil, LLC
*/

// Buttons with Icons:
.btn {
  [class^="#{$pv-icon-css-prefix}-"],
  [class*=" #{$pv-icon-css-prefix}-"] {
    font-size: 1.75rem;
    float: left;
    margin: -2px 2px -2px -8px;
    position: relative;
  }
  [class^="#{$im-css-prefix}-"],
  [class*=" #{$im-css-prefix}-"],
  [class^="#{$fi-css-prefix}-"],
  [class*=" #{$fi-css-prefix}-"] {
    font-size: 1rem;
    margin-right: 0.3125rem;
  }
  &.btn-icon {
    padding: 0;
    [class^="#{$pv-icon-css-prefix}-"],
    [class*=" #{$pv-icon-css-prefix}-"],
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"],
    [class^="#{$fi-css-prefix}-"],
    [class*=" #{$fi-css-prefix}-"] {
      float: none;
      line-height: 1.75rem;
      min-width: 1.75rem;
      margin: 4px;
    }
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"],
    [class^="#{$fi-css-prefix}-"],
    [class*=" #{$fi-css-prefix}-"] {
      font-size: 1.25rem;
    }
    &:disabled {
      border-color: transparent !important;
      > i {
        cursor: not-allowed;
      }
    }
  }
  &:focus-visible,
  &:active {
    border-color: transparent !important;
    border-width: 1px;
  }
  &.disabled {
    border: none;
  }
}

.btn-sm {
  &.btn-icon {
    border-width: 0 !important;
    [class^="#{$pv-icon-css-prefix}-"],
    [class*=" #{$pv-icon-css-prefix}-"],
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"],
    [class^="#{$fi-css-prefix}-"],
    [class*=" #{$fi-css-prefix}-"] {
      line-height: 1.75rem;
      min-width: auto;
      margin: 0;
    }
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"],
    [class^="#{$fi-css-prefix}-"],
    [class*=" #{$fi-css-prefix}-"] {
      font-size: 1rem;
      min-width: 1.75rem;
    }
  }
}

/* Bootrap overrides*/
// Remove all Shadows from buttons
.btn:focus-visible,
.btn:active:focus,
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.btn:disabled,
.btn:active {
  border-color: transparent;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-info,
.btn-warning {
  color: $white;
  [class^="#{$im-css-prefix}-"],
  [class*=" #{$im-css-prefix}-"] {
    color: $white;
  }
  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &:focus-visible {
    color: $pv-lightgray !important;
  }
  &.btnloading {
    background: color.adjust($pv-orange, $lightness: -7%); // darken() deprecated
    background-image: url("/assets/images/icon-loading-button.gif");
    background-position: 15px 7px;
    background-repeat: no-repeat;
    color: $white;
    padding-left: 3rem;
  }
}

.btn-outline-primary,
.btn-outline-success,
.btn-outline-info {
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: $white !important;
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"] {
      color: $white !important;
    }
  }
}
.btn-primary {
  &:focus,
  &:active {
    // background-color: darken($orange, 8%); // NOTE: darken() deprecated
    background: color.adjust($orange, $lightness: -8%);
  }
}
.btn-no-outline-primary,
.btn-no-outline-success,
.btn-no-outline-info {
  border: transparent;
  color: $pv-orange;
  font-weight: 700;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: white;
  }
}

.btn-no-outline-primary,
.btn-outline-primary {
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: $pv-orange !important;
  }
  &:hover {
    background-color: $gray-100 !important;
  }
}

.btn-no-outline-secondary,
.btn-outline-secondary:not(:disabled) {
  color: $pv-blue !important;
  &:focus,
  &:hover {
    color: white !important;
    background-color: $pv-blue !important;
    [class^="#{$im-css-prefix}-"],
    [class*=" #{$im-css-prefix}-"] {
      color: $white !important;
    }
  }
}

// Modal Button outside title bar
.btn-close.no-title {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2000;
}

.btn-transparent {
  border: transparent;
  &:hover,
  &:active {
    background-color: transparent;
  }
}

// button Light
.btn-outline-light {
  border-color: $gray-400;
  color: $gray-800; //$pv-blue;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: transparent;
    border-color: $gray-500;
    color: $pv-blue;
  }

  &:disabled {
    // border-color: lighten($pv-blue, 20%); - deprecated
    // color: lighten($pv-blue, 20%); - deprecated
    border-color: color.adjust($pv-blue, $lightness: 20%);
    color: color.adjust($pv-blue, $lightness: 20%);
    cursor: not-allowed;
    opacity: 0.5;
  }
}

// Loading buttons with spinner
.btn-loading {
  padding-left: 36px;
  position: relative;
  &:before {
    @extend .spinner;
    border-color: $white !important;
    border-top-color: transparent !important;
    content: "";
    display: block;
    margin: 0;
    left: 12px;
    line-height: 1.25rem;
    position: absolute;
    top: 10px;
  }
}
