/*
*   File:           _admin.scss
*   Description:    This files holds NEW specific styles for admin as we migrate some of the styles over.
*   Author:         PreVeil, LLC
*/

.admin-component {
  justify-content: space-around;
  padding-bottom: 1rem;

  .cover-content {
    background-color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;
    position: relative;
  }

  .page-header {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    h1 {
      margin-bottom: 1rem;
    }
  }
  .form-check {
    margin: 0;
    padding-left: 2rem;
    > input {
      margin-left: -22px;
    }
    > label {
      display: none; // need this to prevent improper overflow
    }
  }
  .row {
    @extend .g-0;
    > div {
      padding: 0 0.25rem;
    }
    .account-type-icon {
      color: $pv-blue-bright;
      font-size: 1.2rem;
      margin-right: 0.5rem;
      &.inactive {
        color: $text-muted-light;
      }
    }
  }
  // Listview
  .admin-list-view {
    &.approval-groups {
      min-height: 500px;
    }
    overflow-y: auto;
    .col-1,
    .col-md-1,
    .col-lg-1 {
      max-width: 40px;
    }
    .row:not(.drag-over) {
      border-bottom: $light-border;
    }
    .row {
      align-items: center;
      &.active {
        @include active-row();
      }
    }
    .header-row {
      background-color: $white;
      font-weight: 700;
      padding: 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 1000;
    }
    .header-row > div {
      cursor: pointer;
    }
    .list-body {
      .row {
        line-height: 36px;
        &:hover {
          background-color: $table-hover-bg;
          box-shadow: $box-shadow-sm;
          cursor: pointer;
        }
        > div span [class^="#{$pv-icon-css-prefix}-"] {
          color: $pv-light-blue;
          font-size: 2.5rem;
        }
        [class*="col"]:not(:last-child) {
          overflow: hidden; /* Hide overflowed text */
          text-overflow: ellipsis; /* Add ellipsis to the overflowed text */
          white-space: nowrap; /* Prevent text wrapping */
        }
      }
    }
    &.isloading {
      position: relative;
      .loading {
        left: 50%;
        margin-left: -($loading-logo-width * 0.5);
        position: absolute;
        top: 30%;
      }
      .list-body,
      .header-row {
        opacity: 0.5;
      }
      .list-messages {
        opacity: 0.2;
      }
    }
    // Responsive top nav - mobile view <= 768px
    @include media-breakpoint-down(md) {
      &.admin-users {
        .header-row {
          // Hide only the headers
          > div:nth-child(3),
          > div:nth-child(4),
          > div:nth-child(5),
          > div:nth-child(6) {
            display: none;
          }
        }
        .list-body {
          .row {
            .col-lg-1 {
              max-width: 200px;
            }
            padding: 0.5rem 0 1rem;
            > div:nth-child(3) {
              padding-left: 2.1rem;
              &:before {
                content: "Email:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(4) {
              padding-left: 2.1rem;
              &:before {
                content: "Department:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(5) {
              padding-left: 2.1rem;
              &:before {
                content: "Recovery Group:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(6) {
              &.col-md-1 {
                width: 60px;
              }
              padding-left: 2.1rem;
              &:before {
                content: "Type:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
      &.approval-groups {
        // Hide only the headers
        .header-row {
          > div:nth-child(3),
          > div:nth-child(4),
          > div:nth-child(5) {
            display: none;
          }
        }
        .list-body {
          .row {
            padding: 0.5rem 0 1rem;
            > div:nth-child(3) {
              padding-left: 2.1rem;
              &:before {
                content: "Approvers:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            > div:nth-child(4) {
              padding-left: 2.1rem;
              &:before {
                content: "Required Approvers:";
                color: $text-muted;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.no-recovery-group {
  color: $pv-warning;
  font-style: italic;
}

// Sidebar for Admin > Users
.user-sidebar {
  .offcanvas-header {
    .offcanvas-title {
      i {
        color: $pv-blue-bright;
        font-size: 1.5rem;
        margin-right: 0.3rem;
        &.inactive {
          color: $text-muted-light;
        }
      }
    }
    .badge {
      font-size: $font-size-xs;
      font-style: italic;
      margin: 0 auto 0 1rem;
      padding: 0.25rem 0.35rem;
      text-transform: capitalize;
    }
  }
  .offcanvas-body {
    position: relative;
    .user-update {
      background: color.adjust($pv-yellow, $lightness: 30%);
      border-left: solid 5px color.adjust($pv-yellow, $lightness: 10%);
      color: $body-color;
      font-weight: 500;
      padding: 0.25rem 0.75rem;
      position: absolute;
      right: 1rem;
    }
  }
}

// Account panel
.account-panel {
  .user-table {
    margin-bottom: 1rem;
    border: 1px solid $gray-300;
    & > p {
      display: flex;
      padding: 0.625rem;
      margin: 0;
      & > span {
        font-weight: 500;
        flex-basis: 23%;
      }
    }
    p:not(:last-of-type) {
      border-bottom: 1px solid $gray-300;
    }

  }
  .recovery-group-section {
    margin-top: 1.5rem;
    max-height: 300px;
    .recovery-group-dropdown {
      align-items: center;
      border-bottom: $light-border;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      h5 {
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }

  .user-form {
    .row {
      align-items: center;
      label {
        font-weight: 500;
      }
    }
  }
}

.table-display {
  background: $white;
  border: 1px solid $gray-400;
  border-radius: 0.375rem 0.375rem 0 0;
  margin-bottom: 2rem;
  width: 100%;

  .header-card-row {
    align-items: center;
    background: $active-bg;
    margin: 0;
    min-height: 40px;
    [class*="col"] {
      p {
        margin: 0 auto;
      }
    }
  }

  .table-body {
    .row {
      align-items: center;
      border-bottom: $light-border;
      margin: 0;
      padding: 0.4rem 0.2rem 0.4rem 0;
      & > div:first-of-type {
        padding-left: 0.8rem;
        & > p {
          margin: 0;
        }
        & > p:last-of-type {
          color: $gray-600;
          font-size: $font-size-sm;
          line-height: 0.88rem;
        }
      }
    }
    > .row:last-of-type {
      border-bottom: none;
    }
  }
}

.centered-card {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  top: 20px;
}