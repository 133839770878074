/*
* File:             _bottom-progress.scss
* Description:      This files holds styles for the bottom panel for upload progress
* Author:           PreVeil
*/

.bottom-progress {
  .offcanvas-header {
    border-bottom: solid 1px $border-color;
    margin: 0;
    padding: 0.25rem 0.5rem;
    .offcanvas-title {
      font-size: 1rem;
      font-weight: 500;
      max-width: 90%; // Note: set this for .lg as default
      &.download-header {
        max-width: 95%;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btns {
      margin-left: auto;
      .btn {
        @extend .btn-icon;
        color: $btn-close-color;
        opacity: 0.5;
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
  .offcanvas-body {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    padding: 0;
    .progress-row {
      align-items: center;
      justify-content: center;
      margin: 0;
      overflow: hidden;
      width: 100%;
      .icon-col {
        align-items: left;
        justify-content: left;
        text-align: left;
      }
      .file-icon {
        font-size: 2.5rem;
      }
      .file-icon:not(.upload-success, .upload-conflict, .upload-error) {
        color: $pv-light-blue;
      }
      .file-name {
        cursor: default;
        display: block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .percent {
        text-align: right;
      }
      .status {
        align-items: center;
        padding: 0;
        text-align: center;
      }
      .btn-icon {
        border-radius: $border-radius;
        color: $gray-600;
        margin-right: 0.25rem;
        &:hover,
        &:focus,
        &:active,
        &.show {
          color: $gray-800;
        }
      }
    }
    .upload-success {
      color: $green;
    }
    .upload-error {
      color: $red;
    }
    .upload-conflict {
      color: $yellow;
    }
  }
  .btn-panel {
    border-top: solid 1px $border-color;
    display: flex;
    padding: 0.5rem 1rem;
    span {
      color: $pv-green;
      float: right;
      font-weight: 700;
      margin: 0.375rem auto;
    }
    .btn-no-outline-primary:disabled {
      opacity: 0.25;
    }
  }
  // Modal Sizes
  &.lg,
  &.minimized {
    // border: solid 1px darken($border-color, 10%);
    border: solid 1px color.adjust($border-color, $lightness: -10%);
    border-radius: 0.5rem 0.5rem 0 0;
    height: auto;
    left: auto;
    right: 1.5rem;
    .offcanvas-header {
      background-color: $body-bg-base;
      border-radius: 0.5rem 0.5rem 0 0;
    }
    .offcanvas-title {
      max-width: 350px; // Note: set this for .fullscreen as default
    }
  }
  &.lg {
    box-shadow: $box-shadow;
    height: auto;
    max-height: 500px;
    max-width: 100%;
    width: 500px;
    .tox-sidebar-wrap {
      padding: 0.75rem;
    }
  }
  &.minimized {
    border-bottom: none;
    overflow: hidden;
    width: 500px;
  }
  &.displace {
    right: 544px;
  }

  // Responsive - mobile view <= 768px
  @include media-breakpoint-down(md) {
    &.displace,
    &.lg {
      max-height: 80vh;
      right: 0;
    }
    &.minimized {
      right: 0;
      width: 50%;
      &.displace {
        right: 50%;
      }
    }
    .offcanvas-header {
      padding: 0.5rem 1rem;
    }
  }
}
