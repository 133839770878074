/*
*   File:           _mail.scss
*   Description:    This files holds specific styles for mail
*   Author:         PreVeil, LLC
*/

// Mail Content Wrapper
.mail-component.cover {
  padding-bottom: 1rem;
  justify-content: space-around;

  .cover-content {
    background-color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.isloading .list-view {
    opacity: 0.5;
  }

  .pv-icon-starred {
    color: $yellow;
  }

  .page-header {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    h1 {
      margin-bottom: 1rem;
    }
  }

  .form-check {
    margin: 0;
    padding-left: 1.875rem;
    > input {
      margin-left: -22px;
    }
    > label {
      display: none; // need this to prevent improper overflow
    }
  }
  .row {
    @extend .g-0;
    > div {
      padding: 0.25rem;
    }
  }

  // Mail List View and table
  .table {
    margin-bottom: 0;
    border-radius: 0 0 $border-radius $border-radius;
    tr {
      border-bottom: solid 1px $gray-200;
      > td {
        color: $body-color;
        .form-check {
          border-radius: $border-radius-lg;
          padding-bottom: 0.25rem;
          padding-top: 0.25rem;
          min-height: 2rem;
        }
        .pv-icon-unstarred {
          color: $gray-400;
        }

        &:first-child,
        &:nth-child(2) {
          padding: 0.5rem 0.25rem;
          text-align: center;
          width: 39px;
        }
        &.mail-info {
          padding: 0.75rem;
        }
        &.mail-body {
          max-width: 50vw;
          padding: 0.75rem 0.5rem;
        }
      }
      &:hover {
        box-shadow: $box-shadow-sm;
        cursor: pointer;
      }
      &.active {
        @include active-row();
      }
      &.unread {
        .sender,
        .subject,
        .mail-info p {
          color: $gray-800;
          font-weight: 700;
        }
      }
      &.draft {
        .mail-info {
          .badge {
            display: none;
          }
        }
      }
      // Children elements:
      p {
        line-height: 1.3em;
        margin-bottom: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sender,
      .subject {
        line-height: 1.3em;
        font-weight: 500;
      }
      .subject {
        margin-bottom: 0.25rem;
      }
      .subject,
      .snippet {
        font-size: $font-size-sm;
      }
      .sender small {
        color: $text-muted;
        font-size: $font-size-sm;
      }
      .snippet {
        color: $text-muted;
      }
      .mail-info {
        font-size: $font-size-sm;
        text-align: right;
        p {
          margin-bottom: 0.5rem;
        }
        .badge {
          background-color: $active-bg !important;
          color: $active-color;
          line-height: 1.5rem;
          min-width: 1.5rem;
          padding: 0 0.25rem;
        }
        [class^="ficon-"] {
          color: $mail-badge-icon-color;
          font-size: 1rem;
          margin-right: 1rem;
        }
      }
      .btn-icon,
      .form-check {
        &:hover,
        &:focus,
        &:active {
          background-color: $hover-color-dark;
          > .pv-icon-unstarred {
            color: $active-color;
          }
          > input {
            border-color: $active-color;
          }
        }
      }
    }

    // Responsive - mobile view <= 992px
    @include media-breakpoint-down(lg) {
      tr {
        td {
          &.mail-body {
            max-width: 35vw;
          }
        }
      }
    }
    // Responsive - mobile view <= 576px
    @include media-breakpoint-down(sm) {
      tr {
        td {
          padding: 0.5rem 0.25rem;
          .sender {
            font-size: $font-size-base;
          }
        }
      }
    }
  }

  .thread {
    height: auto;
    padding: 1rem;
    .card {
      margin-bottom: 1rem;
      min-height: 50px;
      .card-header {
        border-bottom: $light-border;
        color: $gray-800;
        display: flex;
        // Header sections
        ul {
          @extend .list-unstyled;
          font-size: $font-size-sm;
          margin-bottom: 0;
          b {
            font-weight: 500;
            margin-right: 0.5rem;
          }
          .sender {
            font-size: $font-size-base;
            font-weight: 500;
            margin-bottom: 0.25rem;
            small,
            .user-email {
              color: $text-muted;
              line-height: 20px;
              vertical-align: bottom;
            }
            .badge {
              font-size: 0.6875rem;
              margin-left: 0.75rem;
              padding: 0.1875rem 0.5rem;
            }
          }
          &.unread li {
            .user-email,
            b {
              color: #000;
              font-weight: 700;
            }
          }
          &.mail-header-info {
            margin-left: auto;
            text-align: right;
            [class^="#{$pv-icon-css-prefix}-"] {
              font-size: 1.5rem;
              margin: 0 2px;
            }
            .pv-icon-compose {
              font-size: 1.75rem;
            }
            .btn-icon {
              border-radius: $border-radius;
              color: $active-color;
              &:hover,
              &:focus,
              &:active {
                background-color: $hover-color;
                border-color: $hover-color;
                > i {
                  color: $active-color;
                }
                > i.pv-icon-starred {
                  color: $yellow;
                }
              }
            }
          }
          .date {
            margin: 0.25rem 0;
            padding-right: 0.5rem;
          }
        }
        // Responsive - mobile view <= 767px
        @include media-breakpoint-down(md) {
          display: block;
          ul {
            width: 100%;
            &.mail-header-info {
              border-top: solid 1px $body-bg-base;
              display: flex;
              margin: 0.5rem 0 0;
              padding: 0.5rem 0 0;
              text-align: left;
              .date {
                font-size: $font-size-xs;
                margin-left: auto;
              }
            }
          }
        }
      }
      .card-body {
        padding: 1rem;
        // previous mail display
        .previous_mail {
          position: relative;
          min-height: 10px;
          > .btn {
            border: none;
            // position: absolute;
            // right: 0.75rem;
            // top: 0;
            span {
              font-size: $font-size-xs;
              color: $text-muted;
            }
          }
        }
      }
    }
  }

  // Search Form
  .search-box {
    .search-results {
      ul {
        @extend .list-unstyled;
        margin: 0;
        &.processing {
          li {
            opacity: 0.5;
          }
        }
      }
      li {
        border-bottom: $light-border;
        cursor: pointer;
        padding: 0.5rem;
        b.highlight {
          font-weight: 500;
        }
        &.empty {
          color: $text-muted;
          font-size: $font-size-md;
          padding: 1rem 0.5rem;
          text-align: center;
        }
        &.view-all {
          background: $body-bg-base;
          display: flex;
          font-size: $font-size-sm;
          padding: 0.25rem 0.75rem;
          i {
            font-size: 1.25rem;
            margin-left: 0.5rem;
          }
          .title {
            margin-right: auto;
          }
          small {
            font-size: 0.95rem;
            white-space: nowrap;
          }
        }
        &:hover {
          background-color: $hover-color;
        }
        &.line {
          background-color: $gray-400;
          border-bottom: none;
          padding: 0.3125rem 0 0;
        }
      }
      .contact {
        display: flex;
        padding-left: 0.25rem;
        small {
          display: block;
        }
        i {
          color: $active-color-light;
          font-size: 2.5rem;
          margin-right: 0.25rem;
        }
      }
      .mail-result {
        display: flex;
        font-size: $font-size-sm;
        padding: 0.25rem 0.75rem 0.25rem 1rem;
        > span {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .badge {
            border: solid 1px $light;
            color: $active-color;
            font-size: $font-size-xs;
            margin: 0.25rem 0;
            padding: 0.25rem 0.5rem;
          }
          i {
            font-size: 1rem;
            margin-right: 0.5rem;
          }
          .date {
            display: block;
            white-space: nowrap;
          }
        }
        > div {
          padding: 0 0.5rem 0 0;
        }
        p {
          margin: 0;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.people {
            font-size: $font-size-base;
          }
        }
        &.unread {
          font-weight: 700;
          .people {
            font-weight: 700;
          }
        }
      }
      // Responsive mobile view <= 768px
      @include media-breakpoint-down(md) {
        li {
          &.mail {
            div > p {
              max-width: 58vw;
            }
          }
        }
      }
    }
  }

  .search-filters {
    margin-bottom: 0.5rem;
    .col {
      color: $text-muted;
      font-size: $font-size-xs;
      align-self: flex-end;
    }
    .btn-group {
      margin-left: auto;
      margin-top: -5px;
      .btn {
        padding: 0.25rem 1.5rem;
      }
    }
  }
  // Headers with search box on the right for mail
  .header-flex {
    display: flex;
    min-height: 50px;
    .toggle-search {
      @extend .d-md-none;
    }
    // Responsive mobile view <= 768px
    @include media-breakpoint-down(md) {
      h1 {
        padding-left: 45px;
      }
      .search-box {
        min-width: 50px;
        .search-form {
          display: none;
        }
        .toggle-search {
          float: right;
          &:active,
          &:focus,
          &:hover {
            border-color: $gray-400;
            color: $active-color;
          }
        }
      }
      &.searching {
        .search-box {
          margin-bottom: 9px;
          width: 90%;
          .search-form {
            display: block;
            form {
              display: block;
            }
          }
        }
        h1 {
          display: none;
        }
        .toggle-search {
          display: none;
        }
      }
    }
  }
} // end of .mail-component.cover

// Mail Tree for custom mailboxes
.mail-tree {
  @extend .move-tree;
}

// Compose Mail modal
.compose-mail {
  // Change the animation so it appears in the bottom
  .offcanvas-header {
    border-bottom: solid 1px $border-color;
    margin: 0;
    padding: 0.25rem 0.5rem;
    .offcanvas-title {
      font-size: 1rem;
      font-weight: 500;
      max-width: 90%; // Note: set this for .lg as default
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btns {
      margin-left: auto;
      .btn {
        @extend .btn-icon;
        color: $btn-close-color;
        opacity: 0.5;
        &:hover,
        &:focus {
          opacity: 0.75;
        }
      }
    }
    .btn-close {
      margin-left: 0;
    }
  }
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
  .btn-panel {
    border-top: solid 1px $border-color;
    display: flex;
    padding: 0.5rem 1rem;
    span {
      color: $pv-green;
      float: right;
      font-weight: 700;
      margin: 0.375rem auto;
    }
    .btn-no-outline-primary:disabled {
      opacity: 0.25;
    }
  }
  .compose-row {
    align-items: center;
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.75rem;
    position: relative;
    > .form-control {
      flex: 1;
    }
    label {
      color: $text-muted;
      font-size: $font-size-sm;
      padding: 0.5rem 0.75rem 0.5rem 0;
      align-self: flex-start;
    }
    > .toggle-btn {
      align-self: flex-start;
      position: absolute;
      right: 0;
      top: 0;
      i {
        margin: 0.25rem 0;
      }
    }
    div {
      flex: 1; // allows the tiny to be flexible & fill space
      &.input-group {
        border: 0 none;
      }
    }
    .form-control {
      border: 0 none;
      box-shadow: none;
    }
    &.tos {
      padding-right: 1.75rem;
    }
    .loading {
      margin: 15px 0;
    }
    &.notifications {
      padding: 0;
      p {
        font-size: $font-size-xs;
        font-weight: 700;
        padding: 0.4rem 0.75rem;
        margin: 0;
        text-align: center;
        width: 100%;
      }
      .external {
        background: $pv-yellow-light;
      }
      .unclaimed,
      .pending {
        background: $active-bg;
      }
    }
    &.attachments {
      max-height: 90px;
      padding-top: 0.5rem;
      overflow-y: auto;
      .badge {
        padding: 0.125rem 0.5rem 0;
      }
    }
  }
  // tiny Editor
  .tiny {
    flex: 1; // allows the tiny to be flexible & fill space
    .tox-tinymce {
      border: none;
    }
    .tox-sidebar-wrap {
      padding: 0.75rem;
    }
    .loading {
      margin-top: 5rem;
    }
  }

  // Modal Sizes
  &.lg,
  &.minimized {
    border: solid 1px color.adjust($border-color, $lightness: -10%); // darken($border-color, 10%);
    border-radius: 0.5rem 0.5rem 0 0;
    right: 1.5rem;
    left: auto;
    .offcanvas-header {
      border-radius: 0.5rem 0.5rem 0 0;
      background-color: $body-bg-base;
    }
    .offcanvas-title {
      max-width: 350px; // Note: set this for .fullscreen as default
    }
  }
  &.fullscreen {
    height: 100vh;
    z-index: 1200;
    .offcanvas-title {
      font-size: $font-size-md;
    }
    .tox-sidebar-wrap {
      padding: 1rem;
    }
  }
  &.lg {
    box-shadow: $box-shadow;
    height: calc(100% - $header-height);
    max-height: 500px;
    max-width: 100%;
    width: 500px;
    .tox-sidebar-wrap {
      padding: 0.75rem;
    }
  }
  &.minimized {
    border-bottom: none;
    height: 45px;
    overflow: hidden;
    width: 250px;
    z-index: 900;
    .offcanvas-title {
      max-width: 150px;
    }
  }
  &.sending {
    @extend .minimized;
    flex-direction: row;
    height: auto;
    padding: 0.5rem 1rem 0.75rem;
    width: 320px;
    p {
      margin-bottom: 0.25rem;
    }
    > div {
      flex: 1;
    }
    .circle-icon {
      background-color: $pv-green;
      border: solid 1px $pv-green;
      border-radius: 50%;
      display: none;
      height: 2.25rem;
      margin-left: 1.5rem;
      text-align: center;
      width: 2.25rem;
      i {
        color: $white;
        font-size: 2rem;
        line-height: 2.25rem;
      }
    }
    &.error {
      .circle-icon {
        background-color: $pv-red;
        border: solid 1px $pv-red;
        opacity: 1;
      }
      .progress-bar {
        display: none;
      }
    }
    &.success .circle-icon {
      display: block;
    }
  }

  // Responsive - mobile view <= 768px
  @include media-breakpoint-down(md) {
    &.lg,
    &.minimized {
      max-height: 100vh;
      right: 0;
    }
    .offcanvas-header {
      padding: 0.5rem 1rem;
      .btns {
        display: none;
      }
    }
  }
}

.compose-sending {
  bottom: 0;
  height: 50px;
  position: fixed;
}

// Attachment badges in message footer and compose
.attachments .badge {
  color: $active-color !important;
  border: solid 1px $light;
  margin: 0 0.5rem 0.5rem 0;
  min-height: 1.8125rem;
  padding: 0.25rem 0.5rem;
  span,
  i {
    color: $mail-badge-icon-color;
    margin: 0 0.5rem;
  }
  span {
    font-size: $font-size-xs;
  }
  i {
    font-size: 1rem;
  }
  .spinner {
    float: left;
  }
  &.text-danger {
    border-color: color.adjust($pv-red, $lightness: 50%) !important; // lighten($pv-red, 50%) !important; - deprecated
    span {
      color: $pv-red;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background-color: color.adjust($light, $lightness: -5%) !important; //  darken($light, 5%) !important;
    border-color: color.adjust($light, $lightness: -5%); // darken($light, 5%);
    color: color.adjust($active-color, $lightness: -5%); //darken($active-color, 5%);
    box-shadow: $box-shadow-sm;
  }
  .btn-icon {
    border-width: 0;
    color: $pv-blue;
    margin-left: 0.25rem;
    min-width: 1.25rem;
    vertical-align: baseline;
    [class^="ficon-"] {
      line-height: 1.25rem;
    }
    &:hover {
      background-color: $gray-200;
      color: color.adjust($pv-blue, $lightness: -20%); // darken($pv-blue, 20%);
    }
  }
}

// MODALS
// Welcome Modal
.welcome-modal {
  max-width: 700px;
  margin-top: 72px; // to account for the top toastr
  @include media-breakpoint-down(md) {
    margin: 1rem 0.5rem;
    max-width: 100%;
  }
}

.express-subsume-modal {
  max-width: 650px;
  @include media-breakpoint-down(md) {
    margin: 1rem 0.5rem;
    max-width: 100%;
  }
}

.welcome-image {
  background-color: $text-inverse;
  border: $light-border;
  height: 13rem;
}

.welcome-buttons {
  margin-top: 1.5rem;
  @include media-breakpoint-down(sm) {
    .btn {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}

// Move Modal
.move-modal {
  max-width: 700px;
  .modal-header {
    p {
      margin-bottom: 0;
    }
    i.ficon-chevron-right {
      font-size: 1rem;
    }
  }
  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
  .move-folders {
    @extend .left-navigation;
    .tree.mail-tree,
    .tree.move-tree {
      .folder-label {
        display: flex;
        align-items: center;
        padding: 0 0.75rem;
        position: relative;
        .folder-name {
          display: block;
          line-height: 2rem;
          margin-left: 0.5rem;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }
}

// External Link Modal
.external-link-modal {
  max-width: 700px;
  .external-link {
    font-weight: 700;
    overflow-wrap: break-word;
  }
  .pv-card {
    border: $light-border;
    max-width: 100%;
    .card-img-top {
      border: $light-border;
    }
    .card-title {
      font-size: $font-size-lg;
    }
  }
  .modal-footer {
    margin-top: 1rem;
  }
}
