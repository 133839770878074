/*
*   File:           _settings.scss
*   Description:    This files holds specific styles for settings
*   Author:         PreVeil, LLC
*/

.mail-client-details {
  @extend .border;
  max-width: 1000px;
  .gray-background {
    background-color: $pv-lightgray;
  }
  .col-4,
  .col-7 {
    border-right: 1px solid $gray-300;
    margin: 0;
    padding: 10px;
    &:last-child {
      border: none;
    }
  }
  .col {
    font-weight: 600;
    padding: 10px;
    .row {
      border: none;
      border-bottom: 1px solid $gray-300;
      border-radius: 0;
      padding: 0;
      &:last-child {
        border: none;
      }
      .col-5 {
        border-right: 1px solid $gray-300;
        overflow-wrap: break-word;
        padding: 0.5rem;
      }
      .col {
        font-weight: 600;
        padding: 0.5rem;
      }
    }
  }
  .row {
    border-bottom: 1px solid $gray-300;
    margin: 0;
    &:last-child {
      border: none;
    }
  }
  .secondary {
    color: $black;
    @extend .gray-background;
  }
  @include media-breakpoint-down(lg) {
    .row {
      .col-4,
      .col-7,
      .col {
        display: block;
        width: 100%;
      }
      .col-4,
      .col-7 {
        background-color: $pv-lightgray;
        border-bottom: 1px solid $gray-300;
        border-right: none;
      }
      .col {
        background-color: white;
        .row {
          background-color: white;
        }
      }
    }
  }
  @include media-breakpoint-only(lg) {
    .col {
      .row {
        .col-5 {
          border-right: 1px solid $gray-300;
        }
      }
    }
  }
  @include media-breakpoint-only(xs) {
    .col {
      .row {
        .col-5 {
          border-bottom: 1px solid $gray-300;
          border-right: none;
          width: 100%;
        }
      }
    }
  }
}

.profile {
  @extend .border;
  margin-bottom: 50px;
  max-width: 650px;
  padding: 0;
  .col-md-4,
  .col {
    margin: 0;
    padding: 0.75rem 0.625rem 0.5rem;
  }
  .col {
    font-weight: bold;
  }
  .row {
    border-bottom: 1px solid $gray-300;
    margin: 0;
    &:last-child {
      border: none;
      .btn > i {
        line-height: 1.5rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .col-md-4,
    .col {
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    }
  }
}

.delete-account {
  margin-top: 50px;
  > h2 {
    @extend .content-header;
    border-bottom: $light-border;
  }
  .delete-body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    > h5 {
      margin-bottom: 0;
    }
  }
}

.existing-backup {
  @extend.border;
  box-shadow: $box-shadow-sm;
  display: flex;
  max-width: 300px;
  padding: 10px;
  p {
    align-self: center;
    font-weight: 700;
    margin: 0;
  }
  .btn {
    margin-left: auto;
  }
}

.approver-row {
  align-items: center;
  display: flex;
  &.added-row {
    color: $pv-green;
  }
  &.removed-row {
    .table-cell {
      text-decoration: line-through;
    }
  }
}

.settings-table {
  border: 1px solid $gray-300;
  border-radius: $border-radius-lg;
  .user-list {
    border-radius: $border-radius;
    padding: 0.25rem;
  }
  .table-header {
    background: $active-bg;
    border: none;
    border-bottom: 1px solid $gray-300;
    border-radius: $border-radius $border-radius 0 0;
    font-weight: 500;
    margin: 0;
    padding: 0.25rem;
    p {
      padding: 0;
    }
  }
  .row {
    border-bottom: 1px solid $gray-300;
    margin: 0;
    padding: 0.25rem;
    &:last-child {
      border: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .table-header {
      display: none;
    }
  }
}

.settings-list:not(.admin) > .list-messages {
  position: static;
}

.settings-list {
  height: 100%;
  overflow-y: auto;
  &.approval-requests,
  &.approval-requests-with-select {
    .center-vertical {
      transform: translate(-20%, -10%);
    }
  }
  .btn-icon {
    &:hover,
    &:focus,
    &:active,
    &.show {
      background-color: $hover-color-dark;
      border-color: $hover-color-dark;
    }
  }
  &.clickable {
    .row:not(.header-row) {
      &:hover {
        background-color: $table-hover-bg;
        box-shadow: $box-shadow-sm;
        cursor: pointer;
      }
      &:active {
        // background-color: lighten($active-color-light, 15%); - deprecated
        background-color: color.adjust($active-color-light, $lightness: 15%);
        p {
          color: $gray-800;
        }
      }
    }
  }
  .dropdown {
    position: static;
    @extend .pv-dropdown;
    .dropdown-toggle:after {
      display: none;
    }
  }
  &.devices {
    background-color: $white;
    height: 265px;
    overflow-y: scroll;
    .header-row {
      padding: 1rem 0;
    }
    .row {
      padding: 0.5rem 0rem;
    }
    .row:not(.header-row) {
      .col {
        padding: 0 0.5rem;
      }
    }
  }
  .col-1,
  .col-sm-1,
  .col-md-1 {
    max-width: 40px;
  }
  .header-row {
    background-color: $white;
    font-weight: 700;
    padding: 0.5rem 0;
    top: 0;
    z-index: 1000;
    .clickable {
      cursor: pointer;
    }
  }
  &.isloading {
    position: relative;
    .loading {
      left: 50%;
      // margin-left: -($loading-logo-width * 0.5);
      // position: absolute;
      top: 50%;
    }
    .list-body,
    .header-row {
      opacity: 0.5;
    }
    .list-messages {
      opacity: 0.2;
    }
  }
  .row {
    align-items: center;
    border-bottom: $light-border;
    padding: 0.25rem 0rem 0.25rem;
    @extend .g-0;
    .name-with-icon {
      align-items: center;
      display: flex;
      padding-left: 0;
    }
    > div {
      padding: 0 0.25rem;
      @extend .overflow-text;
    }
    .form-check {
      margin: 0;
      padding-left: 2rem;
      > input {
        margin-left: -22px;
      }
      > label {
        display: none; // need this to prevent improper overflow
      }
    }
  }
  .list-body {
    .row {
      > div:last-of-type {
        margin-left: auto;
      }
    }
  }
  // Responsive top nav - mobile view <= 768px
  @include media-breakpoint-down(lg) {
    .header-row {
      display: none;
    }
    &.activity-logs {
      .list-body {
        .row {
          padding: 0.5rem 0 1rem;
          > div:nth-of-type(1) {
            &:before {
              content: "Event:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(2) {
            &:before {
              content: "Timestamp:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(3) {
            &:before {
              content: "Topic:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(4) {
            &:before {
              content: "Device:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    &.approval-requests-with-select {
      .header-row {
        > div:nth-child(3),
        > div:nth-child(4),
        > div:nth-child(5) {
          display: none;
        }
      }
      .list-body {
        .row {
          padding: 0.5rem 0 1rem;
          > div:nth-child(3) {
            padding-left: 2.1rem;
            &:before {
              content: "Requester:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
              margin-top: 0.5rem;
            }
          }
          > div:nth-child(4) {
            padding-left: 2.1rem;
            &:before {
              content: "Timestamp:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(5) {
            padding-left: 2.1rem;
            &:before {
              content: "Expiration:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(6) {
            padding-left: 2.1rem;
            &:before {
              content: "Status:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    &.approval-requests {
      .header-row {
        display: none;
      }
      .list-body {
        .row {
          padding: 0.5rem 0 1rem;
          > div:nth-child(1) {
            &:before {
              content: "Action:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
              margin-top: 0.5rem;
            }
          }
          > div:nth-child(2) {
            &:before {
              content: "Requester:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(3) {
            &:before {
              content: "Timestamp:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(4) {
            &:before {
              content: "Expiration:";
              color: $text-muted;
              display: inline-block;
              margin-bottom: 0.5rem;
              margin-right: 0.5rem;
            }
          }
          > div:nth-child(5) {
            &:before {
              content: "Status:";
              color: $text-muted;
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.metadata-list {
  .details-block {
    display: flex;
    justify-content: space-between;
    .details-section {
      display: flex;
      .details-values {
        p {
          text-align: left;
          b {
            color: $black;
          }
        }
      }
      p {
        padding-bottom: 0.2em;
        padding-right: 0.7em;
        text-align: right;
      }
    }
  }
  b {
    font-weight: 500;
  }
  p {
    color: $gray-600;
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-bottom: 0.5rem;
    }
  }
}

.check-list {
  list-style: none;
  margin-left: 0.9em;
  padding: 0;
  width: 100%;
  li {
    align-items: center;
    display: flex;
    padding-bottom: 0.5rem;
    width: 100%;
    .icon-checkmark {
      color: $pv-green-dark;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    .icon-circle-small {
      color: $pv-blue-bright;
      font-size: 1.5rem;
    }
    .ficon-alert-circle {
      color: $pv-yellow;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    p {
      margin: 0 0.5rem 0 0;
    }
  }
}

.notification-count {
  // background-color: lighten($pv-icon, 10%); - deprecated
  background-color: color.adjust($pv-icon, $lightness: 10%);
  border: none;
  border-radius: 3px;
  color: $white;
  font-size: $font-size-xs;
  font-weight: 500;
  padding: 2px 6px;
  &.red {
    background-color: $pv-warning;
  }
  &.yellow {
    background-color: $pv-yellow;
  }
  &.blue {
    background-color: $pv-blue-bright;
  }
  &:hover {
    color: $white;
  }
  &.transparent {
    background-color: transparent;
    color: $gray-600;
    border: 1px solid $gray-600;
  }
  &.green {
    background-color: $pv-green;
  }
  &.gray {
    background-color: $gray-300;
  }
}

.recovery-group-progress-list {
  list-style-type: none;
  padding-left: 0px;
  h4 {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    display: flex;
    margin: 0;
    padding-bottom: 1rem;
    span {
      margin-left: auto;
    }
  }
  .subtitle {
    color: $gray-600;
    font-size: $font-size-sm;
  }
  li {
    align-items: center;
    border-top: $gray-300;
    display: flex;
    height: 70px;
    div {
      flex-grow: 1;
    }
    .approved-icon {
      color: $pv-blue-bright;
    }
    &:last-child {
      border-bottom: $gray-300;
    }
  }
}

.device-icon {
  color: $gray-600;
  font-size: 2.5rem;
}

.lock-image {
  background-color: $gray-100;
}

.log-user {
  color: $pv-blue;
  font-weight: 600;
}

.small-icon {
  font-size: $h6-font-size;
}

.settings-height,
.table-responsive {
  min-height: 70vh;
}

.activity-logs {
  > b {
    padding: 0 0.25rem;
  }
  .users {
    // d-flex align-items-center pb-2 flex-wrap
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 70vh;
    padding-bottom: 1rem;
  }
  .user-autocomplete .form-control {
    padding: 0.25rem 0.75rem;
  }
}

.recovery-approval-button {
  bottom: 0;
  margin-bottom: 1rem;
  position: absolute;
}

.change-phone {
  align-items: center;
  display: flex;
  .country-mask {
    padding-right: 1rem;
    span {
      color: $pv-icon;
      font-weight: normal;
    }
  }
  .phone-code {
    border-right: solid 1px $gray-200;
    border-left: solid 1px $gray-200;
    color: $pv-icon;
    font-weight: normal;
    padding: 0 1rem;
  }
  .phone-input {
    border: 0;
    color: $pv-icon;
    max-width: 200px;
    padding-left: 1rem;
    font-weight: normal;
  }
  .btn {
    margin-left: auto;
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .phone-code {
      border: 0;
      padding: 0 0.25rem;
    }
    .phone-input {
      padding-left: 0.25rem;
    }
  }
}

.authentication-alert {
  display: flex;
  margin: 0.5rem;
  img {
    margin-right: 0.5rem;
  }
  .admin-alert {
    flex: 1;
  }
}

.change-auth-modal {
  .modal-dialog {
    .modal-content {
      min-height: 200px;
    }
  }
}
