/*
*   File:           _system.scss
*   Description:    This files holds specific styles for settings
*   Author:         PreVeil, LLC
*/
.account {
  color: $pv-blue;
  font-weight: bold;
}

.not-found {
  text-align: center;
  img {
    width: 7rem;
  }
}
